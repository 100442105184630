import * as React from "react";
const SvgCurriculum = (props) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", width: "384px", height: "384px", viewBox: "0 0 384 384", ...props }, /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("clipPath", { id: "clip1" }, /* @__PURE__ */ React.createElement("path", { d: "M 68 32 L 316 32 L 316 62.6875 L 68 62.6875 Z M 285.210938 62.6875 L 316 62.6875 L 316 325.253906 L 285.210938 325.253906 Z M 68 62.6875 L 96.164062 62.6875 L 96.164062 325.253906 L 68 325.253906 Z M 68 325.253906 L 316 325.253906 L 316 352 L 68 352 Z M 68 325.253906 " })), /* @__PURE__ */ React.createElement("clipPath", { id: "clip2" }, /* @__PURE__ */ React.createElement("path", { d: "M 108.871094 192 L 275.128906 192 L 275.128906 220 L 108.871094 220 Z M 108.871094 192 " })), /* @__PURE__ */ React.createElement("clipPath", { id: "clip3" }, /* @__PURE__ */ React.createElement("path", { d: "M 108.871094 204 L 275.128906 204 L 275.128906 253 L 108.871094 253 Z M 108.871094 204 " })), /* @__PURE__ */ React.createElement("clipPath", { id: "clip4" }, /* @__PURE__ */ React.createElement("path", { d: "M 108.871094 238 L 275.128906 238 L 275.128906 286 L 108.871094 286 Z M 108.871094 238 " })), /* @__PURE__ */ React.createElement("clipPath", { id: "clip5" }, /* @__PURE__ */ React.createElement("path", { d: "M 108.871094 271 L 275.128906 271 L 275.128906 305.214844 L 108.871094 305.214844 Z M 108.871094 271 " })), /* @__PURE__ */ React.createElement("clipPath", { id: "clip6" }, /* @__PURE__ */ React.createElement("path", { d: "M 108.871094 304 L 275.128906 304 L 275.128906 305.214844 L 108.871094 305.214844 Z M 108.871094 304 " }))), /* @__PURE__ */ React.createElement("g", { id: "surface1" }, /* @__PURE__ */ React.createElement("g", { clipPath: "url(#clip1)", clipRule: "nonzero" }, /* @__PURE__ */ React.createElement("path", { style: {
  stroke: "none",
  fillRule: "nonzero",
  fillOpacity: 1
}, d: "M 92 56 L 292 56 L 292 328 L 92 328 Z M 68 352 L 316 352 L 316 32 L 68 32 Z M 68 352 " })), /* @__PURE__ */ React.createElement("path", { style: {
  fillRule: "evenodd",
  fillOpacity: 1,
  strokeWidth: 4.58258,
  strokeLinecap: "butt",
  strokeLinejoin: "miter",
  stroke: "rgb(100%,100%,100%)",
  strokeOpacity: 1,
  strokeMiterlimit: 4
}, d: "M 421.952535 269.385204 L 152.248271 269.385204 C 144.956584 269.385204 139.025004 263.479624 139.025004 256.166756 L 139.025004 124.459747 C 139.025004 117.159445 144.956584 111.241299 152.248271 111.241299 C 159.539958 111.241299 165.471537 117.159445 165.471537 124.459747 L 165.471537 242.948308 L 408.729268 242.948308 L 408.729268 124.459747 C 408.729268 117.159445 414.648254 111.241299 421.952535 111.241299 C 429.244222 111.241299 435.163208 117.159445 435.163208 124.459747 L 435.163208 256.191886 C 435.163208 263.479624 429.244222 269.385204 421.952535 269.385204 Z M 421.952535 269.385204 ", transform: "matrix(0.310178,0,0,0.310882,106.826762,77.854604)" }), /* @__PURE__ */ React.createElement("path", { style: {
  fillRule: "evenodd",
  fillOpacity: 1,
  strokeWidth: 4.58258,
  strokeLinecap: "butt",
  strokeLinejoin: "miter",
  stroke: "rgb(100%,100%,100%)",
  strokeOpacity: 1,
  strokeMiterlimit: 4
}, d: "M 287.100403 179.004693 C 285.79067 179.004693 284.49353 178.816217 283.234172 178.414135 L 34.347107 102.144192 C 28.793335 100.447909 25.002665 95.296232 25.002665 89.516302 C 25.002665 83.723807 28.793335 78.559566 34.347107 76.875847 L 283.234172 0.580774 C 285.752889 -0.19826 288.447917 -0.19826 290.979228 0.580774 L 539.853699 76.875847 C 545.420064 78.559566 549.185547 83.698677 549.185547 89.516302 C 549.185547 95.321362 545.420064 100.447909 539.853699 102.144192 L 290.979228 178.414135 C 289.707275 178.816217 288.397542 179.004693 287.100403 179.004693 Z M 83.310974 89.516302 L 287.100403 151.952109 L 490.877238 89.516302 L 287.100403 27.0428 Z M 83.310974 89.516302 ", transform: "matrix(0.310178,0,0,0.310882,106.826762,77.854604)" }), /* @__PURE__ */ React.createElement("path", { style: {
  fillRule: "evenodd",
  fillOpacity: 1,
  strokeWidth: 4.58258,
  strokeLinecap: "butt",
  strokeLinejoin: "miter",
  stroke: "rgb(100%,100%,100%)",
  strokeOpacity: 1,
  strokeMiterlimit: 4
}, d: "M 38.225932 191.192806 C 30.909058 191.192806 25.002665 185.287226 25.002665 177.961793 L 25.002665 89.516302 C 25.002665 82.19087 30.909058 76.285289 38.225932 76.285289 C 45.542806 76.285289 51.449198 82.19087 51.449198 89.516302 L 51.449198 177.961793 C 51.449198 185.287226 45.517619 191.192806 38.225932 191.192806 Z M 38.225932 191.192806 ", transform: "matrix(0.310178,0,0,0.310882,106.826762,77.854604)" }), /* @__PURE__ */ React.createElement("path", { style: {
  fillRule: "evenodd",
  fillOpacity: 1,
  strokeWidth: 4.58258,
  strokeLinecap: "butt",
  strokeLinejoin: "miter",
  stroke: "rgb(100%,100%,100%)",
  strokeOpacity: 1,
  strokeMiterlimit: 4
}, d: "M 38.225932 242.521096 C 17.144267 242.521096 0.00439453 225.394912 0.00439453 204.323299 L 0.00439453 202.966272 C 0.00439453 181.894658 17.144267 164.743345 38.225932 164.743345 C 59.295003 164.743345 76.422282 181.894658 76.422282 202.966272 L 76.422282 204.323299 C 76.422282 225.394912 59.295003 242.521096 38.225932 242.521096 Z M 38.225932 191.192806 C 31.715047 191.192806 26.438334 196.482698 26.438334 202.966272 L 26.438334 204.323299 C 26.438334 210.806872 31.715047 216.0842 38.225932 216.0842 C 44.736816 216.0842 50.013529 210.806872 50.013529 204.323299 L 50.013529 202.966272 C 50.013529 196.482698 44.711629 191.192806 38.225932 191.192806 Z M 38.225932 191.192806 ", transform: "matrix(0.310178,0,0,0.310882,106.826762,77.854604)" }), /* @__PURE__ */ React.createElement("path", { style: {
  stroke: "none",
  fillRule: "evenodd",
  fillOpacity: 1
}, d: "M 271.105469 199.117188 L 112.894531 199.117188 C 110.933594 199.117188 109.335938 197.519531 109.335938 195.558594 C 109.335938 193.59375 110.933594 192 112.894531 192 L 271.105469 192 C 273.066406 192 274.664062 193.59375 274.664062 195.558594 C 274.664062 197.519531 273.066406 199.117188 271.105469 199.117188 Z M 271.105469 199.117188 " }), /* @__PURE__ */ React.createElement("g", { clipPath: "url(#clip2)", clipRule: "nonzero" }, /* @__PURE__ */ React.createElement("path", { style: {
  fill: "none",
  strokeWidth: 11.4761,
  strokeLinecap: "butt",
  strokeLinejoin: "miter",
  stroke: "rgb(100%,100%,100%)",
  strokeOpacity: 1,
  strokeMiterlimit: 4
}, d: "M 521.431422 22.945516 L 11.467962 22.945516 C 5.147245 22.945516 -0.00250194 17.794739 -0.00250194 11.472758 C -0.00250194 5.138184 5.147245 0 11.467962 0 L 521.431422 0 C 527.752139 0 532.901886 5.138184 532.901886 11.472758 C 532.901886 17.794739 527.752139 22.945516 521.431422 22.945516 Z M 521.431422 22.945516 ", transform: "matrix(0.31024,0,0,0.310178,109.336714,192)" })), /* @__PURE__ */ React.createElement("path", { style: {
  stroke: "none",
  fillRule: "evenodd",
  fillOpacity: 1
}, d: "M 271.105469 232.238281 L 112.894531 232.238281 C 110.933594 232.238281 109.335938 230.644531 109.335938 228.679688 C 109.335938 226.71875 110.933594 225.121094 112.894531 225.121094 L 271.105469 225.121094 C 273.066406 225.121094 274.664062 226.71875 274.664062 228.679688 C 274.664062 230.644531 273.066406 232.238281 271.105469 232.238281 Z M 271.105469 232.238281 " }), /* @__PURE__ */ React.createElement("g", { clipPath: "url(#clip3)", clipRule: "nonzero" }, /* @__PURE__ */ React.createElement("path", { style: {
  fill: "none",
  strokeWidth: 11.4761,
  strokeLinecap: "butt",
  strokeLinejoin: "miter",
  stroke: "rgb(100%,100%,100%)",
  strokeOpacity: 1,
  strokeMiterlimit: 4
}, d: "M 521.431422 129.726542 L 11.467962 129.726542 C 5.147245 129.726542 -0.00250194 124.588359 -0.00250194 118.253784 C -0.00250194 111.931803 5.147245 106.781026 11.467962 106.781026 L 521.431422 106.781026 C 527.752139 106.781026 532.901886 111.931803 532.901886 118.253784 C 532.901886 124.588359 527.752139 129.726542 521.431422 129.726542 Z M 521.431422 129.726542 ", transform: "matrix(0.31024,0,0,0.310178,109.336714,192)" })), /* @__PURE__ */ React.createElement("path", { style: {
  stroke: "none",
  fillRule: "evenodd",
  fillOpacity: 1
}, d: "M 271.105469 265.363281 L 112.894531 265.363281 C 110.933594 265.363281 109.335938 263.773438 109.335938 261.804688 C 109.335938 259.835938 110.933594 258.246094 112.894531 258.246094 L 271.105469 258.246094 C 273.066406 258.246094 274.664062 259.839844 274.664062 261.804688 C 274.664062 263.765625 273.066406 265.363281 271.105469 265.363281 Z M 271.105469 265.363281 " }), /* @__PURE__ */ React.createElement("g", { clipPath: "url(#clip4)", clipRule: "nonzero" }, /* @__PURE__ */ React.createElement("path", { style: {
  fill: "none",
  strokeWidth: 11.4761,
  strokeLinecap: "butt",
  strokeLinejoin: "miter",
  stroke: "rgb(100%,100%,100%)",
  strokeOpacity: 1,
  strokeMiterlimit: 4
}, d: "M 521.431422 236.520162 L 11.467962 236.520162 C 5.147245 236.520162 -0.00250194 231.394572 -0.00250194 225.047404 C -0.00250194 218.700236 5.147245 213.574646 11.467962 213.574646 L 521.431422 213.574646 C 527.752139 213.574646 532.901886 218.71283 532.901886 225.047404 C 532.901886 231.369385 527.752139 236.520162 521.431422 236.520162 Z M 521.431422 236.520162 ", transform: "matrix(0.31024,0,0,0.310178,109.336714,192)" })), /* @__PURE__ */ React.createElement("path", { style: {
  stroke: "none",
  fillRule: "evenodd",
  fillOpacity: 1
}, d: "M 271.105469 298.484375 L 112.894531 298.484375 C 110.933594 298.484375 109.335938 296.894531 109.335938 294.925781 C 109.335938 292.957031 110.933594 291.375 112.894531 291.375 L 271.105469 291.375 C 273.066406 291.375 274.664062 292.960938 274.664062 294.925781 C 274.664062 296.890625 273.066406 298.484375 271.105469 298.484375 Z M 271.105469 298.484375 " }), /* @__PURE__ */ React.createElement("g", { clipPath: "url(#clip5)", clipRule: "nonzero" }, /* @__PURE__ */ React.createElement("path", { style: {
  fill: "none",
  strokeWidth: 11.4761,
  strokeLinecap: "butt",
  strokeLinejoin: "miter",
  stroke: "rgb(100%,100%,100%)",
  strokeOpacity: 1,
  strokeMiterlimit: 4
}, d: "M 521.431422 343.301188 L 11.467962 343.301188 C 5.147245 343.301188 -0.00250194 338.175598 -0.00250194 331.82843 C -0.00250194 325.481262 5.147245 320.380859 11.467962 320.380859 L 521.431422 320.380859 C 527.752139 320.380859 532.901886 325.493856 532.901886 331.82843 C 532.901886 338.163005 527.752139 343.301188 521.431422 343.301188 Z M 521.431422 343.301188 ", transform: "matrix(0.31024,0,0,0.310178,109.336714,192)" })), /* @__PURE__ */ React.createElement("g", { clipPath: "url(#clip6)", clipRule: "nonzero" }, /* @__PURE__ */ React.createElement("path", { style: {
  fill: "none",
  strokeWidth: 11.4761,
  strokeLinecap: "butt",
  strokeLinejoin: "miter",
  stroke: "rgb(100%,100%,100%)",
  strokeOpacity: 1,
  strokeMiterlimit: 4
}, d: "M 521.431422 450.107402 L 11.467962 450.107402 C 5.147245 450.107402 -0.00250194 444.969218 -0.00250194 438.634644 C -0.00250194 432.300069 5.147245 427.161886 11.467962 427.161886 L 521.431422 427.161886 C 527.752139 427.161886 532.901886 432.287476 532.901886 438.634644 C 532.901886 444.981812 527.752139 450.107402 521.431422 450.107402 Z M 521.431422 450.107402 ", transform: "matrix(0.31024,0,0,0.310178,109.336714,192)" }))));
export default SvgCurriculum;
