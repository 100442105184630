
import { UserProvider } from '@logic/contexts/AppStore/UserContext.tsx'
import { ActivitiesListProvider } from '@logic/contexts/Activities/ActivitiesListContext.tsx'
import { CircularProgress } from '@mui/material'
import { PolicyProvider } from '@logic/contexts/AppStore/AbacContext.tsx'
import { LayoutProvider } from '@logic/contexts/LayoutContext.tsx'
import { SubmitLoadingProvider } from './utils/SubmitContext'

export const AppProviders = ({ dataProvider,userId, children }) => {

  return (
    <PolicyProvider>
      {dataProvider ?
        (
          <LayoutProvider>
            <UserProvider dataProvider={dataProvider}  userId={userId}>
              <ActivitiesListProvider dataProvider={dataProvider}>
                <SubmitLoadingProvider>
                  {children}
                </SubmitLoadingProvider>
              </ActivitiesListProvider>
            </UserProvider>
          </LayoutProvider>
        )
        : <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%', color: '#2E2E52'}}>
          <CircularProgress />
        </div>
      }
    </PolicyProvider>
  );
};