import React, { FC, useEffect, useState } from 'react'
import {
  useDataProvider,
  useNotify,
  useRefresh,
  usePrevious,
} from 'react-admin'
import modalStyles from '@components/modal/modal.module.scss'
import { cn } from '@src/utils/cn.ts'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { LxSelect } from '@components/select/select.tsx'
import {
  GOALF,
  LearnerF,
} from '@logic/contexts/Learners/LearnersFrontend.type.ts'
import { useFormNotify } from '@components/formNotify/useFormNotify.tsx'
import { useModal } from '@logic/contexts/Modal/ModalContext.tsx'
import { FilterObject } from '@logic/useFiltering.hook.ts'
import { useSubmitLoader } from '@src/logic/contexts/utils/SubmitContext.tsx'
import styles from './GoalActions.module.scss'
import { LxLoadingSpinner } from '@components/loader/loadingSpinner.tsx'

export const stateFormValidation = yupResolver(
  yup
    .object()
    .shape({
      assignTo: yup.string().required('State is required'),
    })
    .required()
)

const LearnerGoalAssignTo: FC<{ goal: GOALF; learnerId: string }> = ({
  goal,
  learnerId,
}) => {
  const [userData, setUserData] = useState<FilterObject<string>[]>([])
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const refresh = useRefresh()
  const { hideModal, toggleOverlay } = useModal()
  const { isFormSubmitting, setIsFormSubmitting } = useSubmitLoader()

  const {
    control,
    handleSubmit,
    setError,
    reset,
    formState: { errors, submitCount, isValid },
  } = useForm({
    defaultValues: { assignTo: null },
    resolver: stateFormValidation,
  })

  const onClose = () => {
    hideModal()
    reset()
  }

  const prevState = usePrevious(goal.state)

  useFormNotify(errors, submitCount, isValid)

  useEffect(() => {
    const fetchUsers = async () => {
      toggleOverlay(true)
      await dataProvider
        .getList('User', {
          pagination: { page: 0, perPage: 100 },
          sort: { field: 'id', order: 'ASC' },
          filter: { list: 'all_users' },
        })
        .then((response) => {
          // Filtering out the user with the same id as userId
          const userDataSubset = response.data
            .filter((user) => user.id !== learnerId)
            .map((user) => ({
              value: user.id,
              label: `${user.first_name} ${user.last_name}`,
            }))

          setUserData(userDataSubset)
        })
        .catch((error) => {})
        .finally(() => toggleOverlay(false))
    }

    if (learnerId) {
      fetchUsers()
    }
  }, [learnerId])

  const onSubmit = async (formData: { assignTo: string }) => {
    if (isFormSubmitting) {
      return
    }

    if (prevState === formData.assignTo) {
      setError('state', {
        message: 'Please choose a state different from the current status.',
      })
      return
    }

    setIsFormSubmitting(true)

    try {
      await dataProvider.update('Goal', {
        id: goal.id,
        data: {
          assigned_user: formData.assignTo,
          action: 'update_goal_assignment',
        },
        previousData: null,
      })

      notify('Goal status updated', { type: 'success' })
    } catch (error) {
      const errorMessage = error.message || 'Something went wrong'
      notify(errorMessage, { type: 'warning' })
    } finally {
      setIsFormSubmitting(false)
      onClose()
      refresh()
    }
  }

  return (
    <div className={modalStyles.modalMd}>
      {isFormSubmitting && (
        <div className={styles.LoadingOverlay}>
          <LxLoadingSpinner />
        </div>
      )}
      <div className={modalStyles.dialogHeader}>
        <h3>Assign To: {goal.name}</h3>
      </div>
      <div className={modalStyles.dialogContent}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name='assignTo'
            control={control}
            render={({ field, fieldState }) => (
              <LxSelect
                value={field.value}
                hasError={!!fieldState.error}
                placeholder={'Assign To User'}
                onChange={field.onChange}
                options={userData}
              />
            )}
          />
        </form>
      </div>
      <div className={modalStyles.dialogActions}>
        <div
          className={cn('lxActionButton lxActionButtonDefaultSize')}
          onClick={onClose}
        >
          <span>Close</span>
        </div>
        <div
          className={cn(
            'lxActionButton lxActionButtonFilled lxActionButtonDefaultSize'
          )}
          onClick={handleSubmit(onSubmit)}
        >
          <span>Confirm</span>
        </div>
      </div>
    </div>
  )
}

export default LearnerGoalAssignTo
