import { useGetList } from "react-admin";
import React, { FC, useMemo } from 'react'

import { useLearnerShow } from '@logic/contexts/Learners/LearnerShowContext.tsx'
import { isNilOrEmpty } from '@src/utils/isNilOrEmpty.ts'
import sharedStyles from "../../../MultiPage.module.scss"

import styles from '@src/pages/Learner/Show/common.module.scss'
import { LearnerActivitiesSummary } from '@src/pages/Learner/Show/Activities/LearnerActivitiesSummary.tsx'
import { LearnerActivitiesTable } from '@src/pages/Learner/Show/Activities/LearnerActivitiesTable.tsx'
import { LxLoadingSpinner } from '@components/loader/loadingSpinner.tsx'
import { GOALB } from '@logic/contexts/Learners/LearnersBackend.type.ts'
import { useUserContext } from '@logic/contexts/AppStore/UserContext.tsx'
import { usePolicyContext } from '@logic/contexts/AppStore/AbacContext.tsx'
import { ActivityArea, parseGoalsToActivityAreas } from '@src/pages/Learner/Show/Activities/Activities.factory.ts'
import { LearnerGoalCreate } from '@src/pages/Learner/Show/Goals/GoalActions/LearnerGoalCreate.tsx'

const LearnerActivities: FC = () => {
 
  const { learner, isLoading: isUserLoading } = useLearnerShow();
  const { currentUser } = useUserContext() as any;
  const { canPerformAction } = usePolicyContext()



  const filter = { learner_id: learner?.id };
  const { data, isLoading: isDataLoading, error } = useGetList<GOALB>("Goal", {
    pagination: { page: 1, perPage: 100 },
    sort: { field: "last_update", order: "DESC" },
    filter,
  });

  const canAddGoal = canPerformAction(currentUser, { type: 'Learner' }, 'add_goal')


  const goalList: ActivityArea[] = useMemo( () => {
    if (isNilOrEmpty(data)) {
      return []
    }
    return parseGoalsToActivityAreas(data)
  }, [data])


  return (
    <div className={styles.tabContainer}>
      { isUserLoading || isDataLoading
        ? <LxLoadingSpinner className={styles.loading}/>
        : <>
            <LearnerActivitiesSummary data={goalList}/>
            {goalList.length === 0
              ?
              <>
                {canAddGoal && <LearnerGoalCreate></LearnerGoalCreate>}
                <div className={sharedStyles.notFound}>Sorry, we cannot find any activities. {canAddGoal && 'Please create new goals.'}</div>
              </>
              : <LearnerActivitiesTable data={goalList}></LearnerActivitiesTable>
            }
        </>
      }
    </div>
  )
}

export default LearnerActivities
