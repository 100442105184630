
import {
  LearnerForm,
  LearnerFormType,
  parseLearnerFormToBackend,
} from './LearnerForm.tsx'

import { useNavigate } from 'react-router-dom'
import { useDataProvider, useNotify } from 'react-admin'
import { useLearnerShow } from '@logic/contexts/Learners/LearnerShowContext.tsx'
import { LxLoadingSpinner } from '@components/loader/loadingSpinner.tsx'
import { StudentOutlineIcon } from '@icons/index.ts'
import { LxIcon } from '@components/icon/Icon.tsx'
import styles from './LearnerForm.module.scss'
import { useSubmitLoader } from '@src/logic/contexts/utils/SubmitContext.tsx'

export const LearnerEdit = () => {
  const { learner, isLoading } = useLearnerShow()
  const navigate = useNavigate()
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const {isFormSubmitting, setIsFormSubmitting} = useSubmitLoader();

  const onSubmit = async (formData: LearnerFormType) => {
    if (isFormSubmitting) {return};

    setIsFormSubmitting(true);
    
    try {
      await dataProvider.update('Learner', {
        id: formData.id,
        data: parseLearnerFormToBackend(formData),
        previousData: null,
      })
      // // Handle success case
      backToLearners()
      notify('Learner updated successfully', { type: 'success' })
    } catch (error) {
      const errorMessage =
        error.message || 'Something went wrong, Please try again after sometime'
      notify(errorMessage, { type: 'error' })
    } finally {
      setIsFormSubmitting(false);
    }
  }

  const backToLearners = () => {
    navigate('/Learner');  // this will navigate to the previous page
  };


  return (
    <div>
      {isLoading
        ? <LxLoadingSpinner/>
        :
        <>
          <div class={styles.ProfileHeader}>
            <LxIcon icon={StudentOutlineIcon} sxStyles={{ height: '80px', width: '80px' }}></LxIcon>
            <h3>Edit {learner?.fullName}</h3>
          </div>
          <LearnerForm
            learner={learner}
            onSubmit={onSubmit}
            onCancel={backToLearners}
            isEdit={true}
            isSubmitting={isFormSubmitting}
          ></LearnerForm>
        </>
      }
    </div>
  )
}
