import React, { useState, useContext } from 'react';
import { createContextFactory } from '@logic/contexts/createContextFactory.ts'

interface LayoutContextType {
  shouldPreventResponsiveness: boolean
  shouldOpenMobileNav: boolean
  setShouldOpenMobileNav: (shouldOpenMobileNav: boolean) => void
  setShouldPreventResponsiveness: (shouldPreventResponsiveness: boolean) => void
}

const LayoutContextDefault = {
  shouldPreventResponsiveness: false,
  shouldOpenMobileNav: false,
  setShouldPreventResponsiveness:  () => {},
  setShouldOpenMobileNav: () => {},
}

const LayoutContext = createContextFactory<LayoutContextType>(LayoutContextDefault)


export const LayoutProvider = ({ children }) => {
  const [shouldPreventResponsiveness, setShouldPreventResponsiveness] = useState(false)
  const [shouldOpenMobileNav, setShouldOpenMobileNav] = useState(false)

  return (
    <LayoutContext.Provider value={{ shouldPreventResponsiveness, setShouldPreventResponsiveness, shouldOpenMobileNav, setShouldOpenMobileNav}}>
      {children}
    </LayoutContext.Provider>
  );
};

export const useLayout = (): LayoutContextType => {
  return useContext(LayoutContext);
};