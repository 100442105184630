
import { AdminView } from "./AdminView.tsx";
import { AssistantView } from "./AssistantViews/AssistantView.tsx";
import { UserView } from "./UserView.tsx";
import { UserAssistantView } from "./AssistantViews/UserAssistantView.tsx";
import { LxLoadingSpinner } from "@components/loader/loadingSpinner.tsx";
import { useUserShow } from "@logic/contexts/Users/UserShowContext.tsx";
import { useUserContext } from "@logic/contexts/AppStore/UserContext.tsx";

export const UserLearners = () => {
  const { user, isLoading } = useUserShow();
  const { currentUser, isUserLoading } = useUserContext() as any;


  if (isLoading || isUserLoading) {
    return <LxLoadingSpinner />;
  }

 
  const commonProps = {
    userFullName: user.fullName,
    userAssignedLearners: user.assignedLearners,
    userId: user.id,
    userRole: user.userRole,
    userAssignedGoals: user.assignedGoals,
  };


  const assistantProbs = {
    ...commonProps,
    userAssigneeUsers: user.assigneeUsers,
  };

  const userAssistantProbs = {
    ...commonProps,
    userAssigneeUsers: user.assigneeUsers,
    superVisor: currentUser
  };

  if (currentUser.userRole == 'user') {
    return <UserAssistantView {...userAssistantProbs}   /> 
  }

  const roleComponents = {
    account_admin: <AdminView {...commonProps} />,
    admin: <AdminView {...commonProps} />,
    user: <UserView {...commonProps} />,
    assistant: <AssistantView {...assistantProbs}   />
  };

  const roleComponent = roleComponents[user.userRole] || null;

  return roleComponent;
};

