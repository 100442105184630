import {
  CommunicationAndLanguageIcon,
  EmotionalLiteracyIcon,
  MathematicsIcon,
  PhonicsAndLiteracyIcon,
  PhysicalDevelopmentIcon,
  PlaySkillsIcon,
  SocialSkillsIcon,
  UnderstandingTheWorldIcon,
} from '../skillAreas'; // Adjust the import path as necessary

const svgMapping = {
  'communication-and-language': CommunicationAndLanguageIcon,
  'emotional-literacy': EmotionalLiteracyIcon,
  'mathematics': MathematicsIcon,
  'phonics-and-literacy': PhonicsAndLiteracyIcon,
  'physical-development': PhysicalDevelopmentIcon,
  'play-skills': PlaySkillsIcon,
  'social-skills': SocialSkillsIcon,
  'understanding-the-world': UnderstandingTheWorldIcon,
};

export const getSvgComponent = (textValue: string) => {
  const normalizedText = textValue.toLowerCase().replace(/\s+/g, '-');
  return svgMapping[normalizedText] || null;
};
