import React, { FC, useMemo, useState } from 'react'
import { UnitF } from '@logic/contexts/Learners/LearnersFrontend.type.ts'
import styles from '@src/pages/Learner/Show/common.module.scss'
import { ActivityStatusB } from '@logic/contexts/Learners/LearnersBackend.type.ts'
import { ActivityArea, ActivityGoal } from '@src/pages/Learner/Show/Activities/Activities.factory.ts'

type Props = {
  data: ActivityArea[];
}

export const LearnerActivitiesSummary: FC<Props> = ({data}) => {

  // Calculate the counts for each completion_status value
  const statusCounts: Record<ActivityStatusB, number> = useMemo(() => {
    const counts: Record<ActivityStatusB, number> = {
      generalized: 0,
      mastered: 0,
      emerging: 0,
      in_progress: 0,
      not_introduced: 0
    }
    data?.forEach((area: ActivityArea) => {
      area.goals?.forEach((goal: ActivityGoal) => {
        goal.units.forEach((unit: UnitF) => {
          const status = unit.status as ActivityStatusB
          if (status in counts) {
            counts[status]++
          }
        })
      })
    })
    return counts;
  }, [data])

  return (
    <div className={styles.activitySummaryContainer}>
      <div className={styles.summaryTitle}>Activities Status Summary</div>
      <div className={styles.info}>
        <div>Generalized</div>
        <div>{statusCounts.generalized ? statusCounts.generalized : '-'}</div>
      </div>
      <div className={styles.info}>
        <div>Mastered</div>
        <div>{statusCounts.mastered ? statusCounts.mastered : '-'}</div>
      </div>
        <div className={styles.info}>
            <div>Emerging</div>
            <div>{statusCounts.emerging ? statusCounts.emerging : '-'}</div>
        </div>
      <div className={styles.info}>
        <div>In progress</div>
        <div>{statusCounts.in_progress ? statusCounts.in_progress : '-'}</div>
      </div>
      <div className={styles.info}>
        <div>Not introduced</div>
        <div>{statusCounts.not_introduced ? statusCounts.not_introduced : '-'}</div>
      </div>
    </div>
  )
}