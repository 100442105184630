import React, { useEffect, useReducer } from 'react'
import usePrevious from "@src/hooks/usePrevious.hook.ts";

export type FilterValues = {
  Q: string;
  state?: string;
  selection?: string;
}

export type DispatchType = (action: { type: string; field: string; value: any }) => void;

function filterReducer(state, action) {
  switch (action.type) {
    case 'UPDATE_FILTER':
      return { ...state, [action.field]: action.value };
    default:
      throw new Error('Unhandled action type');
  }
}

// Your debounce filter hook
export const useDebounceFilter = (initialFilterValues, debounceDelay = 300) => {
  const [filterValues, dispatch] = useReducer(filterReducer, initialFilterValues)
  const prevFilterValues = usePrevious(filterValues)

  useEffect(() => {
    const hasChanged = filterValues && prevFilterValues &&
      Object.keys(filterValues).some(key => filterValues[key] !== prevFilterValues[key])

    if (hasChanged) {
      const timeoutId = setTimeout(() => {
        // Update the filter state in your context or make an API call here
      }, debounceDelay);

      return () => clearTimeout(timeoutId);
    }
  }, [filterValues]);

  return [filterValues, dispatch];
}

// Usage
