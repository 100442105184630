import '@styles/common.scss'
import { cn } from '@src/utils/cn.ts'

export const PrintToPdf = () => {
  const handleExportToPdf = () => {
    window.print();
  };

  return (
    <>
      <div className={cn('lxActionButton ignoreOnPrint ')} onClick={handleExportToPdf}>Export to PDF</div>
    </>
  )
}
