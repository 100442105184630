import { useUserContext } from '@logic/contexts/AppStore/UserContext.tsx'
import { usePolicyContext } from '@logic/contexts/AppStore/AbacContext.tsx'

import { StudentOutlineIcon } from '@icons/index.ts'
import styles from './LearnerShow.module.scss'
import React, { type FC } from 'react'

import { useLearnerShow } from '@logic/contexts/Learners/LearnerShowContext.tsx'
import '@styles/common.scss'

import { ShowBase } from 'react-admin'
import { LearnerShowTabs } from '@src/pages/Learner/Show/LearnerShowTabs.tsx'
import { LxIcon } from '@components/icon/Icon.tsx'
import { useLocation, useNavigate } from 'react-router-dom'
import { LxLoadingSpinner } from '@components/loader/loadingSpinner.tsx'
import { cn } from '@src/utils/cn.ts'

export const LearnerShowHeader: FC = () => {
  const { currentUser } = useUserContext() as any
  const { canPerformAction } = usePolicyContext()
  const { learner, onEditProfile } = useLearnerShow()
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate('/Learner');  // this will navigate to the previous page
  };

  const can_edit_learner = canPerformAction(currentUser, { type: 'Learner' }, 'edit')

  return (
    <div className={styles.studentProfileHeader}>
      <div className={styles.studentName}>
        <LxIcon icon={StudentOutlineIcon} sxStyles={{ height: '80px', width: '80px' }}></LxIcon>
        <span className={styles.title}> Learner: </span>
        <span className={styles.boldStudentName}> {learner?.fullName}</span>
      </div>
      <div className={cn(styles.studentActions, 'ignoreOnPrint')}>
          { can_edit_learner
            && <div className={cn('lxActionButton lxActionButtonDefaultSize')} onClick={onEditProfile}><span>Edit learner info</span> </div>
          }
      </div>
    </div>
  )
}

export const LearnerShow = () => {
  const {  isLoading } = useLearnerShow()
  const location = useLocation();

  // Check if the current URL path includes '/members'
  if (location.pathname.includes('/members') || location.pathname.includes('/edit')) {
    return null
  }

  return (
    <ShowBase>
      {
        isLoading 
          ? <LxLoadingSpinner />
          : <>
            <LearnerShowHeader></LearnerShowHeader>
            <LearnerShowTabs></LearnerShowTabs>
          </>
      }
    </ShowBase>
  )
}

export default LearnerShow