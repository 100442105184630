import { LxClickAbleIcon } from '@components/icon/clickAbleIcon.tsx'
import { EditIcon, MoreActionsIcon } from '@icons/utils'
import { LxDropdown } from '@components/select/dropdown.tsx'
import styles from './GoalActions.module.scss'
import React, { ReactNode, useEffect, useMemo, useRef, useState } from 'react'
import { useModal } from '@logic/contexts/Modal/ModalContext.tsx'
import { FilterObject } from '@logic/useFiltering.hook.ts'
import { LxIcon } from '@components/icon/Icon.tsx'
import { GoalIcon, TeamMemberIcon } from '@icons/index.ts'
import { GOALF } from '@logic/contexts/Learners/LearnersFrontend.type.ts'
import { LearnerGoalEdit } from '@src/pages/Learner/Show/Goals/GoalActions/LearnerGoalEdit.tsx'
import LearnerGoalEditStatus from '@src/pages/Learner/Show/Goals/GoalActions/LearnerGoalEditStatus.tsx'
import LearnerGoalAssignTo from '@src/pages/Learner/Show/Goals/GoalActions/LearnerGoalAssignTo.tsx'
import { useLearnerShow } from '@logic/contexts/Learners/LearnerShowContext.tsx'
import { isNilOrEmpty } from '@src/utils/isNilOrEmpty.ts'

const generateOptions = (goal: GOALF, learnerId: string | undefined): FilterObject<ReactNode>[] => {
  const options = [
    {
      value: <LearnerGoalEdit goal={goal} />,
      optionToRender: (
          <div className={styles.dropdownOption}>
            <LxIcon icon={GoalIcon}></LxIcon>
            <span> Edit Goal </span>
          </div>
      )
    },
    {
      value: <LearnerGoalEditStatus goal={goal} />,
      optionToRender: (
        <div className={styles.dropdownOption}>
          <LxIcon icon={EditIcon}></LxIcon>
          <span> Manage Status</span>
        </div>
      )
    }
  ]

  if (goal.assigneeUsers?.length === 0 || isNilOrEmpty(learnerId)) {
    options.push({
      value: <LearnerGoalAssignTo goal={goal} learnerId={learnerId!}></LearnerGoalAssignTo>,
      optionToRender: (
        <div className={styles.dropdownOption}>
          <LxIcon icon={TeamMemberIcon}></LxIcon>
          <span> Assign to</span>
        </div>
      )
    })
  }

  return options
}

export const GoalActions = ({ goal, isLastChild }) => {
  const [ isEditDropdownOpen, setIsEditDropdownOpen ] = useState(false)
  const { showModal, hideModal } = useModal()
  const { learner } = useLearnerShow()

  const selectRef = useRef<HTMLDivElement>(null)

  const options = useMemo(() => generateOptions(goal, learner?.id), [learner?.id, goal])

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
        setIsEditDropdownOpen(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [ selectRef ])

  const handleOptionClick = (option: FilterObject<ReactNode>) => {
    showModal(option.value)
  }


  return (
    <div ref={selectRef}>
      <LxClickAbleIcon
        icon={MoreActionsIcon}
        dropdown={
          isEditDropdownOpen
            ? <LxDropdown <ReactNode>
              className={styles.dropdown}
              options={options}
              isLastChild={isLastChild}
              optionRenderer={(option) => option.optionToRender}
              onOptionClick={handleOptionClick}
            />
            : null
        }
        onClick={() => setIsEditDropdownOpen(prev => !prev)}
        // className={styles.svgIcon}
      />
    </div>
  )
}