import { ReportDataB} from '@logic/contexts/Learners/LearnersBackend.type.ts'
import {  UsageReportF } from '@logic/contexts/Learners/LearnersFrontend.type.ts'
import { isNilOrEmpty } from '@src/utils/isNilOrEmpty.ts'


export const parseReportDataToUsageReport = (reportData: ReportDataB): UsageReportF | null => {

   const report =  reportData?.report?.usage_report

  if (isNilOrEmpty(report)) {
    return null
  }

  const units: {
    [key: string]: {
      totalTime: string
      totalQuestions: string
    }
  } = {}

  Object.keys(report.units).forEach((date: string) => {
    const unit = report.units[date]
    units[date] = {
      totalTime: unit.tt,
      totalQuestions: unit.tuq
    }
  })

  const usageReport: UsageReportF = {
    totalTime: report.tt,
    totalActivities: report.ta,
    totalUniqQuest: report.tuq,
    lastActivityDate: report.lad,
    units: units
  }

  return usageReport;
};





