import styles from './Menu.module.scss'
import { Link, useLocation } from 'react-router-dom'
import { type FC,  useEffect, useState } from 'react'
import { LxIcon } from '@components/icon/Icon.tsx'
import { ArrowDownIcon, ArrowUpIcon, ExpandIcon, CollapseIcon, MenuIcon } from '@icons/utils'
import { StudentsIcon, TeamMembersIcon, DashboardOutlineIcon, ActivitiesIcon, AnalyticsIcon, CurriculumIcon, CurriculumIndIcon } from '@icons/index.ts'
import * as React from 'react'
import { cn } from '@src/utils/cn.ts'
import { LxClickAbleIcon } from '@components/icon/clickAbleIcon.tsx'
import { LxTooltip } from '@components/tooltip/tooltip.tsx'
import { useLayout } from '@logic/contexts/LayoutContext.tsx'
import { useUserContext } from '@logic/contexts/AppStore/UserContext.tsx'
import { isNilOrEmpty } from '@src/utils/isNilOrEmpty.ts'
import { usePolicyContext } from '@logic/contexts/AppStore/AbacContext.tsx'
import { LxLoadingSpinner } from '@components/loader/loadingSpinner.tsx'
import { USER_ROLE } from '@src/logic/LuxAI'


export interface ResourceType {
  name: string
  type: string
  icon?: React.ElementType | any // TODO: any is a hack
  disabled?: boolean
}

interface MenuSection {
  name: 'Dashboard' | 'My Account' | 'My Robots'
  icon?: React.ElementType | any // TODO: any is a hack
  submenu?: ResourceType[]
  resourceType?: ResourceType
}

export const DashboardSubmenu: ResourceType[] = [
  { name: 'Learners', type: 'Learner', icon: StudentsIcon },
  { name: 'Team members', type: 'User', icon: TeamMembersIcon },
  { name: 'Activities', type: 'CurriculumView', icon: ActivitiesIcon },
  { name: 'Curriculums', type: 'GroupCurriculums', icon: CurriculumIcon }
]


export const AccountSubmenu: ResourceType[] = [
  { name: 'Analytics', type: 'Analytics', icon: AnalyticsIcon }
]

type Props = {
  mobileVersion: boolean
}

export const LxMenu: FC<Props> = ({ mobileVersion = false }) => {
  const { shouldOpenMobileNav, setShouldOpenMobileNav } = useLayout()
  const [ isCollapsed, setIsCollapsed ] = useState(mobileVersion)
  const [ activeMenu, setActiveMenu ] = useState<string | null>(null)
  const [ activeSubmenu, setActiveSubmenu ] = useState<string | null>(null)

  const location = useLocation()

  const { currentUser, isUserLoading } = useUserContext() as any
  const { canPerformAction } = usePolicyContext()
  const canSeeAnalytics = !isUserLoading && !isNilOrEmpty(currentUser) && canPerformAction(currentUser, { type: 'Analytics' }, 'view_menu')

  const handleIsCollapsed = () => {
    setIsCollapsed(prev => {
      document.documentElement.style.setProperty('--sidebarWidth', !prev ? '64px' : '250px')
      return !prev
    })
  }

  const toggleMenu = (menuName: string) => {
    if (activeMenu === menuName) {
      setActiveMenu(null)
      setActiveSubmenu(null)
    } else {
      setActiveMenu(menuName)
    }
  }

  const menuSections: MenuSection[] = [
    {
      name: 'Dashboard',
      icon: DashboardOutlineIcon,
      submenu: DashboardSubmenu,
      resourceType: { name: 'Dashboard', type: '#' }
    },
    // Conditionally include 'My Account' section based on 'canSeeAnalytics'
    canSeeAnalytics ? {
      name: 'My Account',
      submenu: AccountSubmenu,
      resourceType: { name: 'Account', type: '#' }
    } : null
  ].filter(section => section !== null) // Filter out null values if 'canSeeAnalytics' is false

  if (currentUser?.roles.includes(USER_ROLE.PARENT)) {
    const index = DashboardSubmenu.findIndex(item => item.type === 'GroupCurriculums');

    if (index !== -1) {
      DashboardSubmenu.splice(index, 1);
    }
  }


  useEffect(() => {
    const currentPath = location.pathname.split('/')[1] // This will get the first segment after the initial '/'

    const matchedSection = menuSections.find(section =>
      section.submenu.some(sub => sub.type === currentPath)
    )

    if (matchedSection) {
      setActiveMenu(matchedSection.name)
      const matchedSubmenu = matchedSection.submenu.find(sub => sub.type === currentPath)
      if (matchedSubmenu) {
        setActiveSubmenu(matchedSubmenu.type)
      } else {
        setActiveSubmenu('/')
      }
    } else {
      setActiveMenu(null)
      setActiveSubmenu('/')

    }
  }, [ location.pathname ]) // This effect will run every time the path changes


  const isMenuActive = (section: MenuSection) => activeMenu === section.name
  const isSubmenuActive = (submenuName: string) => activeSubmenu === submenuName

  return (
    <div className={styles.menuContainer}>
      <div className={styles.menuStickyHook}>
        {
          !isCollapsed &&
            <div className={styles.siteLogoContainer}>
                <Link to={'/'}>
                    <img alt="luxai-logo" src={'/static/images/logo/white-logo.png'} className={styles.logo}/>
                </Link>
                <LxTooltip classNames={cn(styles.expandCollapseMenuContainer, styles.expandCollapse)}
                           tooltipText={isCollapsed ? 'Expand Menu' : 'Collapse Menu'}>
                    <LxClickAbleIcon
                        className={styles.expandCollapseMenu}
                        icon={isCollapsed ? ExpandIcon : CollapseIcon}
                        onClick={handleIsCollapsed}>
                    </LxClickAbleIcon>
                </LxTooltip>
            </div>
        }
        {isUserLoading
          ? <LxLoadingSpinner className={styles.menuLoader}/>
          :
          <div>
            {
              isCollapsed
                ?
                <>
                  <div className={cn(styles.subMenuShrink, { [styles.mobileNav]: mobileVersion })}>
                    <div className={cn(styles.siteLogoContainer)}>
                      {!mobileVersion
                        ?
                        <LxTooltip classNames={cn(styles.expandCollapseMenuContainer)}
                                   tooltipText={isCollapsed ? 'Expand Menu' : 'Collapse Menu'}>
                          <LxClickAbleIcon
                            className={styles.expandCollapseMenu}
                            icon={isCollapsed ? ExpandIcon : CollapseIcon}
                            onClick={handleIsCollapsed}>
                          </LxClickAbleIcon>
                        </LxTooltip>
                        : <LxClickAbleIcon icon={MenuIcon} customViewBox={'0 0 50 50'}
                                           onClick={() => setShouldOpenMobileNav(false)}></LxClickAbleIcon>
                      }
                    </div>
                  </div>
                  {
                    menuSections.map((menuSection: MenuSection, index: number) => (
                      <div key={`shrinked-menu-${index}`}
                           className={cn(styles.subMenuShrink, { [styles.mobileNav]: mobileVersion })}>
                        {menuSection.icon
                          ? <LxTooltip tooltipText={'Dashboard'}>
                            <Link key={'Dasbhoard Icon'} to={'/'} onClick={() => setShouldOpenMobileNav(false)}>
                              <div className={cn(styles.subMenuItem, isSubmenuActive('/') && styles.active)}>
                                <LxIcon icon={DashboardOutlineIcon}></LxIcon>
                                {mobileVersion && <span style={{ textDecoration: 'none' }}>Dashboard</span>}
                              </div>
                            </Link>
                          </LxTooltip>
                          : null
                        }
                        {menuSection?.submenu?.length && menuSection.submenu.map(subItem => (
                          <React.Fragment key={`Shrink-Menu-${menuSection.name}-${subItem.name}`}> {
                            // canPerformAction(currentUser, { type: subItem.type }, 'view')  &&
                            <LxTooltip tooltipText={subItem.name}>
                              <Link key={subItem.type} to={subItem.type}
                                    className={cn(subItem.disabled && styles.disabledOption)}
                                    onClick={() => setShouldOpenMobileNav(false)}>
                                <div className={cn(styles.subMenuItem, isSubmenuActive(subItem.type) && styles.active)}>
                                  <LxIcon icon={subItem.icon}></LxIcon>
                                  {mobileVersion && <span>{subItem.name}</span>}

                                </div>
                              </Link>
                            </LxTooltip>
                          }</React.Fragment>
                        ))}
                      </div>
                    ))
                  }
                </>
                : menuSections.map((menuSection: MenuSection) => (
                  <div className={cn(styles.menuSection, isMenuActive(menuSection) && styles.active)}
                       key={menuSection.name}>
                    <div onClick={() => {
                      toggleMenu(menuSection.name)
                    }} className={styles.sectionHeader}>
                      <h2>
                        {menuSection.name}
                      </h2>
                      <LxIcon icon={isMenuActive(menuSection) ? ArrowUpIcon : ArrowDownIcon}
                              customViewBox={'0 0 960 560'}></LxIcon>
                    </div>
                    {isMenuActive(menuSection) && menuSection?.submenu?.length && menuSection.submenu.map(subItem => (
                      <React.Fragment key={`Menu-${menuSection.name}-${subItem.name}`}> {
                        // canPerformAction(currentUser, { type: subItem.type }, 'view')  &&
                        <Link key={subItem.type} to={subItem.type}
                              className={cn(subItem.disabled && styles.disabledOption)}>
                          <div className={cn(styles.subMenuItem, isSubmenuActive(subItem.type) && styles.active)}>
                            <LxIcon icon={subItem.icon}></LxIcon>
                            <span> {subItem.name} </span>
                          </div>
                        </Link>
                      }</React.Fragment>
                    ))}
                  </div>
                ))}
          </div>
        }
      </div>
    </div>
  )
}

export default LxMenu
