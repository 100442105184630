import { buildQuery } from 'ra-data-graphql-simple'
import omitDeep from 'omit-deep'
import { gql } from 'graphql-tag'

export const myBuildQuery =
  (introspection: any) =>
  (fetchType: string, resource: string, params: any) => {
    const builtQuery = buildQuery(introspection)(fetchType, resource, params)

    if (fetchType === 'UPDATE') {
      return {
        ...builtQuery,
        // avoid errors due to __typename being included in embedded schema
        // see https://github.com/marmelab/react-admin/issues/2840
        variables: omitDeep(builtQuery.variables, '__typename'),
      }
    }

  
    if (resource === 'Goal' && fetchType === 'GET_ONE' && params.method == 'createCurriculumGoals') {
      const { id, curriculumId } = params;
      return {
        ...builtQuery,
        query: gql`
          mutation createCurriculumGoals($learner_id: String!, $curriculum_id: String!) {
              data: createCurriculumGoals(learner_id: $learner_id, curriculum_id: $curriculum_id)  
          }
        `,
        variables: { learner_id: id, curriculum_id: curriculumId },
        parseResponse: (response: any) => ({
          data: { id: response.data.data },  
        })
      }
    }

    return builtQuery
  }
