import React, { useState } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Logo from "@src/pages/layout/Logo";
import axios from "axios";
import { updateMsalConfig } from "@src/logic/MsalConfig";
import TextField from "@mui/material/TextField";
import LxSnackbar  from "@src/utils/LxSnackbar";
 

import { PublicClientApplication } from "@azure/msal-browser";

const Login = () => {
  const [email, setEmail] = useState("");
  const [openNotify, setOpenNotify] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleNotifyClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenNotify(false);
  };

  const isEmailValid = (email) => {
    // Basic email validation using a regular expression
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleLogin = async () => {
    // Check if the email is valid before proceeding
    if (!isEmailValid(email)) {
      setOpenNotify(true);
      return;
    }

    setIsSubmitting(true);

    const graphqlQuery = `
    query GetResourceGroup($identifier: String!) {
      ResourceGroup(identifier: $identifier) {
        name
        api {
          url
        }
        storage {
          url
        }
        identity_providers {
          target
          client_id
          url
        }
      }
    }
  `;

    const variables = {
      identifier: email,
    };

    const requestBody = {
      query: graphqlQuery,
      variables,
    };

    try {
      const response = await axios.post(
        import.meta.env.VITE_RESOLVER_URL,
        requestBody,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const resourceGroupData = response.data.data.ResourceGroup;

      // Check if ResourceGroup is null
      if (!resourceGroupData) {
        setOpenNotify(true);
        setIsSubmitting(false);
        return;
      }

      // store data in local storage

      const webIdentityProvider = resourceGroupData.identity_providers.find(
        (provider) => provider.target === "web"
      );

      const clientId = webIdentityProvider.client_id;
      const b2cUrl = webIdentityProvider.url;
      const apiUrl = resourceGroupData.api.url;

      localStorage.setItem("msalClientId", clientId);
      localStorage.setItem("msalClientUrl", b2cUrl);
      localStorage.setItem("apiUrl", apiUrl);
      

      // // Update MSAL instance configuration based on API response

      const modifiedMsalConfig = updateMsalConfig(clientId, b2cUrl);

      const instance = new PublicClientApplication(modifiedMsalConfig);
      await instance.initialize();


      // Check if there's an active account and logout if so
      const activeAccount = instance.getActiveAccount();
      if (activeAccount) {
        await instance.logoutRedirect ({
          account: activeAccount
        });
      }
      
      
      await instance.loginRedirect({
        extraQueryParameters: {
          login_hint: email,
        },
      });
    } catch (error) {
      // Handle GraphQL call error
      setIsSubmitting(false);
      console.error(error);
    }
  };

 
  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <CssBaseline />

      <Grid
        item
        xs={12}
        sm={6} // Width of the image side (e.g., sm={6} for half-width)
        md={6} // Width of the image side (e.g., md={6} for half-width)
        component={Paper}
        elevation={6}
        square
        sx={{
          backgroundImage: "url(/static/images/Login.jpg)",
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />

      <Grid
        item
        xs={12}
        sm={6} // Width of the content side (e.g., sm={6} for half-width)
        md={6} // Width of the content side (e.g., md={6} for half-width)
        component={Paper}
        elevation={6}
        square
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          p: 4,
          flexDirection: "column",
          background: "linear-gradient(45deg, #ebf1f4 60%, #ffffff 80%)",
          boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
          borderRadius: 0,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Logo style={{ maxWidth: "100%" }} height="80" />

          <Box noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Enter your email"
              name="email"
              autoComplete="email"
              autoFocus
              type={"email"}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleLogin();
                }
              }}
              onChange={(e) => setEmail(e.target.value)}
              sx={{
                borderRadius: 4,
              }}
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
                backgroundColor: "#78b4e2", 
                color: "#ffffff", 
                boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
                borderRadius: 4,
              }}
              onClick={() => {
                handleLogin();
              }}
              disabled={isSubmitting || !isEmailValid(email)}
            >
              Continue
            </Button>
          </Box>
        </Box>
      </Grid>
      <LxSnackbar open={openNotify} onClose={handleNotifyClose} message="Invalid Email: Please enter valid email" />
    </Grid>
  );
};

export default Login;
