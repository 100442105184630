import React, { FC } from 'react'
import styles from './toggleButton.module.scss';
import { cn } from '@src/utils/cn.ts'; // Path to your cn function

type Props<T> = {
  value: T
  onChange: (value: T) => void
}
export const ToggleButton: FC = <T,>({ value, onChange }: Props<T>) => {
  return (
    <div className={styles.toggleContainer} onClick={() => onChange(!value)}>
      <div className={cn(styles.toggleSwitch, { [styles.checked]: !!value })}></div>
    </div>
  );
};
