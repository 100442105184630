import * as React from "react";
const SvgQtIcon = (props) => /* @__PURE__ */ React.createElement("svg", { width: "384px", height: "384px", id: "svg22", xmlns: "http://www.w3.org/2000/svg", "xmlns:svg": "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("defs", { id: "defs2" }), /* @__PURE__ */ React.createElement("g", { id: "g20" }, /* @__PURE__ */ React.createElement("g", { transform: "matrix(0.0826967,0,0,0.0826967,-3.8211703,-3.8211704)", id: "g18" }, /* @__PURE__ */ React.createElement("g", { id: "g16" }, /* @__PURE__ */ React.createElement("g", { id: "g409", transform: "translate(17.033094,37.902391)" }, /* @__PURE__ */ React.createElement("g", { id: "g695", transform: "matrix(0.77894736,0,0,0.77894736,519.6751,515.06189)" }, /* @__PURE__ */ React.createElement("path", { d: "m 1500.3937,634.1748 c -798.34234,0 -1447.035007,648.6927 -1447.035007,1447.0351 v 497.6637 c 0,798.3423 648.692667,1447.0352 1447.035007,1447.0352 h 1701.0345 c 798.3386,0 1447.0353,-648.6926 1447.0353,-1447.0352 v -497.6637 c 0,-798.3426 -648.6967,-1447.0351 -1447.0353,-1447.0351 z m 0,140.2418 h 1701.0345 c 722.5455,0 1306.7931,584.2441 1306.7931,1306.7933 v 497.6637 c 0,722.5492 -584.2476,1306.7931 -1306.7931,1306.7931 H 1500.3937 c -722.54946,0 -1306.79323,-584.2437 -1306.79323,-1306.7931 v -497.6637 c 0,-722.5495 584.24377,-1306.7933 1306.79323,-1306.7933 z", id: "path4", style: {
  strokeWidth: 3.98468
} }), /* @__PURE__ */ React.createElement("path", { d: "m 898.94646,1546.4718 c -232.68031,0 -422.81821,190.1421 -422.81821,422.8182 v 1140.2715 c 0,232.6794 190.1388,422.8182 422.81821,422.8182 H 3802.8674 c 232.6957,0 422.8264,-190.1428 422.8264,-422.8182 V 1969.29 c 0,-232.6722 -190.1295,-422.8182 -422.8264,-422.8182 z m 0,141.718 H 3802.8674 c 156.1131,0 281.1081,125.0111 281.1081,281.1002 v 1140.2715 c 0,156.0938 -124.9938,281.1002 -281.1081,281.1002 H 898.94646 c -156.08994,0 -281.10021,-125.0103 -281.10021,-281.1002 V 1969.29 c 0,-156.0852 125.01123,-281.1002 281.10021,-281.1002 z", id: "path6", style: {
  strokeWidth: 4.02662
} }), /* @__PURE__ */ React.createElement("path", { d: "m 1521.4282,1985.3729 c -171.7254,0 -312.456,140.7305 -312.456,312.4559 0,171.7254 140.7306,312.4559 312.456,312.4559 h 24.1597 c 171.7253,0 312.4559,-140.7305 312.4559,-312.4559 0,-171.7254 -140.7306,-312.4559 -312.4559,-312.4559 z m 0,141.718 h 24.1597 c 95.1343,0 170.7379,75.6036 170.7379,170.7379 0,95.1343 -75.6036,170.7379 -170.7379,170.7379 h -24.1597 c -95.1343,0 -170.7379,-75.6036 -170.7379,-170.7379 0,-95.1343 75.6036,-170.7379 170.7379,-170.7379 z", id: "path8", style: {
  strokeWidth: 4.02662
} }), /* @__PURE__ */ React.createElement("path", { d: "m 3156.234,1985.3729 c -171.7253,0 -312.4559,140.7305 -312.4559,312.4559 0,171.7254 140.7306,312.4559 312.4559,312.4559 h 24.1597 c 171.7254,0 312.456,-140.7305 312.456,-312.4559 0,-171.7254 -140.7306,-312.4559 -312.456,-312.4559 z m 0,141.718 h 24.1597 c 95.1343,0 170.7379,75.6036 170.7379,170.7379 0,95.1343 -75.6036,170.7379 -170.7379,170.7379 h -24.1597 c -95.1343,0 -170.7379,-75.6036 -170.7379,-170.7379 0,-95.1343 75.6036,-170.7379 170.7379,-170.7379 z", id: "path10", style: {
  strokeWidth: 4.02662
} }), /* @__PURE__ */ React.createElement("path", { d: "m 2028.7817,1131.7304 v 141.718 h 647.4279 v -141.718 z", id: "path12", style: {
  strokeWidth: 4.02662
} }), /* @__PURE__ */ React.createElement("path", { d: "m 2636.9029,2791.0499 c -47.3026,76.713 -93.6298,150.8806 -138.525,202.5812 -44.8951,51.7007 -83.99,75.9332 -116.5281,77.827 -32.535,1.8936 -88.998,-20.286 -154.7022,-67.5717 -65.7041,-47.2857 -139.1389,-115.2704 -213.8746,-184.8783 l -96.5916,103.7011 c 74.7361,69.6082 150.7713,140.8505 227.6847,196.2031 76.9133,55.3527 156.2144,99.2292 245.7179,94.0199 89.5045,-5.2092 158.7286,-61.2377 215.2981,-126.3822 56.5694,-65.1446 104.8515,-144.4048 152.1541,-221.1178 z", id: "path14", style: {
  strokeWidth: 4.02662
} })))))));
export default SvgQtIcon;
