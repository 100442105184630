import React, { useState, useEffect } from 'react'
import { SearchIcon, PlusIcon, MinusIcon, SettingsIcon } from '@icons/utils'

import styles from './LearnerTeamMembers.module.scss'
import sharedStyles from '../../../MultiPage.module.scss'
import { LxIcon } from '@components/icon/Icon.tsx'
import { cn } from '@src/utils/cn.ts'
import { useLearnerShow } from '@logic/contexts/Learners/LearnerShowContext.tsx'
import { LxLoadingSpinner } from '@components/loader/loadingSpinner.tsx'
import { mapRoleToReadableRole } from '@src/utils/mapRoleToReadableRole.ts'
import { LxClickAbleIcon } from '@components/icon/clickAbleIcon.tsx'
import { AddNewLernerTeamMember } from '@src/pages/Learner/Show/TeamMembers/LearnerAddNewTeamMember.tsx'
import { UserF } from '@logic/contexts/Users/UsersFrontend.type.ts'
import { ConfirmationModal } from '@src/utils/confirmationModel'
import { LearnerMemberEditAccess } from '@src/pages/Learner/Show/TeamMembers/LearnerMemberEditAccess.tsx'
import {GoalReAssign} from "./GoalReAssign"
import { useModal } from '@logic/contexts/Modal/ModalContext.tsx'
import { InfoModal } from '../../../../components/modal/InfoModal'
import { useSubmitLoader } from '@src/logic/contexts/utils/SubmitContext.tsx'


import {
  useDataProvider,
  useNotify
} from 'react-admin'
import { isNilOrEmpty } from '@src/utils/isNilOrEmpty.ts'
import {
  CAN_EDIT_DELETE_ALL_USERS,
  CAN_RUN_ALL_ACTIVITIES,
  CAN_VIEW_ALL_USERS
} from '@logic/contexts/AppStore/UserPermissions.constant.ts'


export const AdminView = () => {
  const {learner, isLoading} = useLearnerShow()
  const learnerId = learner?.id
  const [openAccessModal, setOpenAccessModal] = useState(false)
  const {showModal, hideModal} = useModal()

  const dataProvider = useDataProvider()
  const notify = useNotify()

  const [assignedUserData, setAssignedUserData] = useState<User[]>([])
  const [isAssignedUserDataLoading, setIsAssignedUserDataLoading] =
    useState(true)
  const [userData, setUserData] = useState<User[]>([])
  const [isUserDataLoading, setIsUserDataLoading] = useState(true)
  const [filteredAssignedUserData, setFilteredAssignedUserData] = useState()

  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false)
  const [unassignParams, setUnassignParams] = useState({
    userId: null,
    superVisorId: null,
  })
  const [selectedUser, setSelectedUser] = useState({})
  const [refreshKey, setRefreshKey] = useState(0)

  // for handling goal assignment
  const [openGoalDialog, setOpenGoalDialog] = useState(false)
  const [goalAssignTo, setGoalAssignTo] = useState({})
  const [userChoice, setUserChoice] = useState("")
  const { isFormSubmitting, setIsFormSubmitting } = useSubmitLoader()


  const fullName = (user) => {
    return `${user.first_name} ${user.last_name}`
  }

  // get all users and details

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await dataProvider.getList("User", {
          pagination: {page: 0, perPage: 100},
          sort: {field: "id", order: "ASC"},
          filter: {"state": "active"},
        })

        const userDataSubset: User[] = []

        response.data.forEach((user: any) => {
          const userDataItem: User = {
            id: user.id,
            fullName: fullName(user),
            userRole: mapRoleToReadableRole(user.user_role),
          }

          userDataSubset.push(userDataItem)
        })

        setUserData(userDataSubset)
        setIsUserDataLoading(false)
      } catch (error) {
        // Handle error
        setIsUserDataLoading(false)
      }
    }
    fetchData()
  }, [dataProvider])

  // get assigned users and details
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await dataProvider.getList("User", {
          pagination: {page: 0, perPage: 100},
          sort: {field: "id", order: "ASC"},
          filter: {list: "learnerUsers", learner_id: learnerId},
        })

        const assignedUsersArray = []

        const userDataMap = userData.reduce((acc, user) => {
          acc[user.id] = {
            fullName: user.fullName,
            userRole: user.userRole,
          }
          return acc
        }, {})

        Object.entries(response.data || {}).map(([_, userData]) => {
          const userDict = {
            [CAN_VIEW_ALL_USERS]: false,
            [CAN_EDIT_DELETE_ALL_USERS]: false,
            id: userData.id,
            fullName: userDataMap[userData.id]?.fullName,
            userRole: userDataMap[userData.id]?.userRole,
            assistants: [],
          }
          const permissionsToCheck = [ CAN_VIEW_ALL_USERS, CAN_EDIT_DELETE_ALL_USERS ]

          permissionsToCheck.forEach((permission) => {
            if (userData.assigned_learners[learnerId].includes(permission)) {
              userDict[permission] = true
            }
          })

          Object.entries(userData.assigned_users || {}).map(
            ([key, assistantData]) => {
              if (learnerId in assistantData.learners) {
                userDict.assistants.push({
                  [CAN_RUN_ALL_ACTIVITIES]:
                    assistantData.learners[learnerId].includes(
                      CAN_RUN_ALL_ACTIVITIES
                    ),
                  id: key,
                  fullName: userDataMap[key]?.fullName,
                  userRole: userDataMap[key]?.userRole,
                  supervisorId: userData.id,
                  supervisorFullName: userDataMap[userData.id].fullName,
                })
              }
            }
          )
          assignedUsersArray.push(userDict)
        })

        // Mapping assignedUserData with assistants details
        assignedUsersArray.forEach((user) => {
          if (user.assistants.length > 0) {
            user.assistants.forEach((assistant) => {
              const assistantWithSupervisorId = {
                ...assistant,
              }
              assignedUsersArray.push(assistantWithSupervisorId)
            })

            delete user.assistants
          }
        })

        setAssignedUserData(assignedUsersArray)
        setFilteredAssignedUserData(assignedUsersArray)
        setIsAssignedUserDataLoading(false)
      } catch (error) {
        // Handle error
        setIsAssignedUserDataLoading(false)
      }
    }
    if (learnerId) {
      fetchData()
    }
  }, [userData, refreshKey])

  const handleSearchInputChange = (inputValue) => {
    const filteredUsers = assignedUserData.filter((user) =>
      user.fullName.toLowerCase().includes(inputValue.toLowerCase())
    )
    setFilteredAssignedUserData(filteredUsers)
  }

  // getting unassigned users
  const assignedUserIds = new Set(assignedUserData.map((user) => user.id))


  const handleAccessEdit = async (user) => {
    setSelectedUser(user)
    setOpenAccessModal(true)
  }

  const handleUnAssign = async (userId: string, superVisorId: string) => {
    try {
      // check is there any goal assigned to learner by this user
      const userGoals = learner.assignedGoals.find(obj => Object.keys(obj)[0] === userId);
      setUnassignParams({ userId, superVisorId })

      if (userGoals && userGoals[userId] && userGoals[userId].length > 0) {
        setOpenGoalDialog(true)
      } else {
        setIsConfirmationOpen(true)
      }
    } catch (error: any) {
      const errorMessage =
        error.message || "Something went wrong, Please try again after sometime"
      notify(errorMessage, {type: "error"})
      setRefreshKey((prevKey) => prevKey + 1)
    }
  }

  const handleConfirmUnassign = async () => {
    if (isFormSubmitting) {
      return
    }

    setIsFormSubmitting(true)
    try {
      const {userId, superVisorId} = unassignParams
      await dataProvider
        .update("Learner", {
          id: learnerId,
          data: {
            assign_user: {
              user_id: userId,
              supervisor_id: superVisorId,
              action: "remove_user",
            },
          },
          previousData: null,
        })
        notify("User is removed successfully", {type: "success"})
    } catch (error) {
      const errorMessage =
      error.message ||
      "Something went wrong, Please try again after sometime"
      notify(errorMessage, {type: "error"})
    } finally {
      setRefreshKey((prevKey) => prevKey + 1)
      setIsFormSubmitting(false)
      setIsConfirmationOpen(false)
    }
  }

  const handleCloseGoalDialog = () => {
    setOpenGoalDialog(false)
    setGoalAssignTo("")
  }

  const handleSelectedUserChange = (event) => {
    setGoalAssignTo(event.target.value)
  }

  const handleUserChoiceChange = (event) => {
    setUserChoice(event.target.value)
  }

  const handleUserChoiceSubmit = async () => {
    if (isFormSubmitting) {return};

    setIsFormSubmitting(true);

    const {userId, superVisorId} = unassignParams
    try {
      if (userChoice === "assign") {
        await dataProvider.update("Learner", {
          id: learnerId,
          data: {
            assign_user: {
              user_id: userId,
              supervisor_id: superVisorId,
              assign_goals: goalAssignTo,
              action: "remove_user",
            },
          },
          previousData: null,
        })
        notify("Learner is un assigned successfully", {type: "success"})
        // Close the dialog
        setRefreshKey((prevKey) => prevKey + 1)
        handleCloseGoalDialog()

      } else if (userChoice === "unassign") {
        await dataProvider.update("Learner", {
          id: learnerId,
          data: {
            assign_user: {
              user_id: userId,
              supervisor_id: superVisorId,
              assign_goals: null,
              action: "remove_user"
            },
          },
          previousData: null,
        })
        notify("Learner is un assigned successfully", {type: "success"})
      }
    } catch (error) {
      const errorMessage =
        error.message || "Something went wrong, Please try again after sometime"
      notify(errorMessage, {type: "error"})
    } finally {
      setRefreshKey((prevKey) => prevKey + 1)
      setIsFormSubmitting(false);
      handleCloseGoalDialog();
    }
  }

  return (
    <>
      <div className={styles.studentTeamMembersNavigation}>
        {isLoading || isAssignedUserDataLoading || isUserDataLoading ? (
          <LxLoadingSpinner />
        ) : (
          <>
            <div className={cn(styles.membersInfo)}>
              <h3>Team Members Assigned to {learner?.fullName} </h3>
            </div>
            <div className={cn(sharedStyles.tableActions)}>
              <div
                className={cn('lxActionButton', 'lxActionButtonFilled lxActionButtonDefaultSize')}
                onClick={() => showModal(
                  <AddNewLernerTeamMember
                    onClose={hideModal}
                    users={userData}
                    learnerId={learnerId}
                    learnerFullName={learner?.fullName}
                    setRefreshKey={setRefreshKey}
                    assignedUserIds={assignedUserIds}
                  />)}
              >
                <LxIcon icon={PlusIcon}/>
                Assign New Team Member
              </div>
              <div className={'lxActionButton lxActionButtonDefaultSize'}>
                <LxIcon icon={SearchIcon}/>
                <input
                  className={'pristineInput'}
                  type="text"
                  onChange={(e) => {
                    handleSearchInputChange(e.target.value)
                  }}
                  placeholder="Search"
                />
              </div>
            </div>
          </>
        )}
      </div>
      <div className={styles.studentTeamMembersList}>
        <div className={cn(sharedStyles.tableListHeader)}> Team Member Name</div>
        <div className={cn(sharedStyles.tableListHeader, sharedStyles.hide2column)}> Role</div>
        <div className={cn(sharedStyles.tableListHeader, sharedStyles.hide3column)}> Supervisor</div>
        <div className={cn(sharedStyles.tableListHeader, sharedStyles.hide4column)}> Access Control</div>
        <div className={cn(sharedStyles.tableListHeader, sharedStyles.hide5column)}> Unassign</div>
        <>
          {isAssignedUserDataLoading ? (
            <LxLoadingSpinner className={sharedStyles.loader}/>
          ) : isNilOrEmpty(filteredAssignedUserData) ? (
            <div className={sharedStyles.notFound}>
              No team members match your current search or filters.
            </div>
          ) : (
            filteredAssignedUserData.map((user: UserF) => (
              <React.Fragment key={`User-row-${user.id}-${user.supervisorId}`}>
                <div>{user.fullName}</div>
                <div className={sharedStyles.hide2column}>{user.userRole}</div>
                <div className={sharedStyles.hide3column}>
                  {user.supervisorFullName ? user.supervisorFullName : "N/A"}
                </div>
                <div className={sharedStyles.hide4column}>
                {user.userRole == "Assistant" || user.userRole == "User" ? (
                  <LxClickAbleIcon
                    onClick={() => {
                      handleAccessEdit(user)
                    }}
                    icon={SettingsIcon}
                  />
                ) : (
                  <InfoModal 
                    icon={SettingsIcon} 
                    message={`As an admin, ${user.fullName} has full access to view and edit all goals of all learners. Individual access controls are not applicable and cannot be modified.`} 
                    header={`Access of ${user.fullName} to ${learner?.fullName}`}
                  />
                )}
                </div>
                <LxClickAbleIcon
                  className={sharedStyles.hide5column}
                  onClick={() => {
                    handleUnAssign(user.id, user.supervisorId)
                  }}
                  icon={MinusIcon}
                />
              </React.Fragment>
            ))
          )}
        </>
      </div>

      <LearnerMemberEditAccess
        isModalOpen={openAccessModal}
        onClose={() => setOpenAccessModal(false)}
        user={selectedUser}
        learnerId={learnerId}
        learnerFullName={learner?.fullName}
        setRefreshKey={setRefreshKey}
        isFormSubmitting={isFormSubmitting}
        setIsFormSubmitting={setIsFormSubmitting}
      />

      <ConfirmationModal
        isOpen={isConfirmationOpen}
        onClose={() => setIsConfirmationOpen(false)}
        onConfirm={handleConfirmUnassign}
        message='Are you sure you want to unassign this user?'
        isFormSubmitting={isFormSubmitting}
      />

      <GoalReAssign
        open={openGoalDialog}
        handleClose={handleCloseGoalDialog}
        userChoice={userChoice}
        handleUserChoiceChange={handleUserChoiceChange}
        handleSelectedUserChange={handleSelectedUserChange}
        userData={userData}
        isUserDataLoading={isUserDataLoading}
        handleUserChoiceSubmit={handleUserChoiceSubmit}
        isFormSubmitting={isFormSubmitting}
      />
    </>
  )
}
