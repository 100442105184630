import { ReactNode, useRef } from 'react'
import { FilterObject } from '@logic/useFiltering.hook.ts'
import { cn } from '@src/utils/cn.ts'
import styles from './select.module.scss'
import { useCheckVisibility } from '@src/hooks/useCheckVisibility.ts'

interface DropdownContainerProps<T> {
  options: FilterObject<T>[];
  onOptionClick: (option: FilterObject<T>) => void;
  className?: string;
  isLastChild?: boolean;
  optionRenderer?: (option: FilterObject<T>) => ReactNode; // Optional custom renderer
}

export const LxDropdown = <T, >(
  {
    options,
    onOptionClick,
    isLastChild,
    className,
    optionRenderer,
  }: DropdownContainerProps<T>) => {
  const dropdownRef = useRef(null)
  if (isLastChild) {
    useCheckVisibility(dropdownRef, styles.dropdownTopOriented)
  }

  return (
    <div ref={dropdownRef}  className={cn(styles.dropdownContainer, className)}>
      {options.map((option: FilterObject<T>, index) => (
        <div key={option.label || index}
             className={cn(styles.dropdownItem, option.className)}
             onClick={() => onOptionClick(option)}>
          {optionRenderer ? optionRenderer(option) : option.label}
        </div>
      ))}
    </div>
  )
}