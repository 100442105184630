import {
  MsalProvider,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import Home from "./pages/Home";
import Login from "./pages/Login/Login.tsx";
import {
  PublicClientApplication,
  EventType,
  type AuthenticationResult,
  type EventMessage,
} from "@azure/msal-browser";
import { updateMsalConfig } from "./logic/MsalConfig";
import { LxModal } from '@components/modal/modal.tsx'

const App = () => {
  const clientId = localStorage.getItem("msalClientId");
  const b2cUrl = localStorage.getItem("msalClientUrl");

  let content;

  if (clientId && b2cUrl) {
    const newMsalConfig = updateMsalConfig(clientId, b2cUrl);

    const msalInstance = new PublicClientApplication(newMsalConfig);

    const allAccounts = msalInstance.getAllAccounts();

    if (!msalInstance.getActiveAccount() && allAccounts.length > 0) {
      if (allAccounts.length === 1) {
        msalInstance.setActiveAccount(allAccounts[0]);
      } else if (allAccounts.length > 1) {
        // https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/5642
        // if multiple accounts are used, sometimes b2c will not clear storage of previous session,
        // so we are comparing all the accounts and set the latest created as active account
        const storageB2CId = localStorage.getItem("msalB2CId")

        // if more than one account first check msalB2CId from cookies, make that account as active
        if (storageB2CId) {
          const activeAccount = allAccounts.find(account => account.localAccountId === storageB2CId);
          msalInstance.setActiveAccount(activeAccount);
        } else {
          msalInstance.setActiveAccount(allAccounts[0]);
        }
      }
    }

    msalInstance.addEventCallback((event: EventMessage) => {
      if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const payload = event.payload as AuthenticationResult;
        const account = payload.account;
        localStorage.setItem("msalB2CId",account?.localAccountId )
        msalInstance.setActiveAccount(account);
      }
    });

    content = (
      <MsalProvider instance={msalInstance}>
        <AuthenticatedTemplate>
          <Home />
          <LxModal></LxModal>

        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <Login />
        </UnauthenticatedTemplate>
      </MsalProvider>

    );
  } else {
    const itemKey = "msal.interaction.status";
    if (sessionStorage.getItem(itemKey)) {
      sessionStorage.removeItem(itemKey);
    }
    content = <Login />;
  }

  return content;
};

export default App;
