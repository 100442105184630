import React, {FC, useEffect} from "react"
import {Dialog} from "@mui/material"
import styles from "./LearnerAddNewTeamMember.module.scss"
import {cn} from "@src/utils/cn.ts"
import {ToggleButton} from "@components/toggleButton/toggleButton.tsx"
import {useForm, Controller} from "react-hook-form"
import {LxTooltip} from "@components/tooltip/tooltip.tsx"
import {InfoOutlineIcon} from "@icons/utils"
import {LxIcon} from "@components/icon/Icon.tsx"
import {UserData} from "@src/pages/Learner/Show/TeamMembers/LearnerMembers.type.ts"
import { LxLoadingSpinner } from '@src/components/loader/loadingSpinner'
import {useDataProvider, useNotify} from "react-admin"

import {
  CAN_EDIT_DELETE_ALL_USERS,
  CAN_RUN_ALL_ACTIVITIES,
  CAN_VIEW_ALL_USERS
} from '@logic/contexts/AppStore/UserPermissions.constant.ts'

interface Props {
  isModalOpen: boolean
  onClose: () => void
  user: UserData
  learnerId: string
  learnerFullName: string
  setRefreshKey: any
  isFormSubmitting: boolean
  setIsFormSubmitting: (isSubmitting: boolean) => void
}

export const LearnerMemberEditAccess: FC<Props> = ({
  isModalOpen,
  onClose,
  user,
  learnerId,
  learnerFullName,
  setRefreshKey,
  isFormSubmitting,
  setIsFormSubmitting
}) => {
  const dataProvider = useDataProvider()
  const notify = useNotify()

  // canRunActivities - "AC-2310-UUL-004"
  // canViewAllGoals - "AC-2310-UL-002"
  // canEditDeleteAllGoals - "AC-2310-UL-003"

  const defaultFormProps = {
    canRunActivities: user[CAN_RUN_ALL_ACTIVITIES],
    canViewAllGoals: user[CAN_VIEW_ALL_USERS],
    canEditDeleteAllGoals: user[CAN_EDIT_DELETE_ALL_USERS],
  }

  const {
    reset,
    control,
    handleSubmit,
    formState: {errors},
  } = useForm({
    defaultValues: defaultFormProps,
  })

  useEffect(() => {
    if (isModalOpen) {
      reset(defaultFormProps)
    }
  }, [isModalOpen])

  const onSubmit = async (formData) => {
    if (isFormSubmitting) {return};

    setIsFormSubmitting(true);

    try {
      const {canRunActivities, canViewAllGoals, canEditDeleteAllGoals} =
        formData

      const accesses = []

      if (user.userRole === "User") {
        if (canViewAllGoals) {
          accesses.push(CAN_VIEW_ALL_USERS)
        }

        if (canEditDeleteAllGoals) {
          accesses.push(CAN_EDIT_DELETE_ALL_USERS)
        }
      }

      if (user.userRole === "Assistant") {
        if (canRunActivities) {
          accesses.push(CAN_RUN_ALL_ACTIVITIES)
        }
      }

      const payload = {
        assign_user: {
          user_id: user.id,
          supervisor_id: user.supervisorId,
          accesses: accesses,
          action: "add_user",
        },
      }

      await dataProvider.update("Learner", {
        id: learnerId,
        data: payload,
        previousData: null,
      })
      notify("Access updated successfully", {type: "success"})
    } catch (error) {
      // Handle error case
      const errorMessage =
        error.message || "Something went wrong, Please try again after sometime"
      notify(errorMessage, {type: "error"})
    }  finally {
      setRefreshKey((prevKey) => prevKey + 1)
      onClose()
      setIsFormSubmitting(false)
    }
  }

  return (
    <Dialog
      open={isModalOpen}
      onClose={onClose}
    >
      <div className={styles.materialUiContainerWrapper}>
        <div className={styles.modalContainer}>
          <div className={styles.modalHeader}>
            <h3>Access of {user.fullName} to {learnerFullName}</h3>
          </div>
          <div className={styles.modalContent}>
            {isFormSubmitting && (
              <div className={styles.LoadingOverlay}>
                <LxLoadingSpinner />
              </div>
            )}

            <form onSubmit={handleSubmit(onSubmit)}>
              {user.userRole === "Assistant" && (
                <>
                  <div className={styles.optionalCheckboxRow}>
                    <span>
                      Can run un-planned activities for {learnerFullName}{" "}
                    </span>
                    <LxTooltip tooltipText={"Assistant can also run activities that are not pre-planned by the supervisor"}>
                      <LxIcon icon={InfoOutlineIcon} />
                    </LxTooltip>
                    <Controller
                      name="canRunActivities"
                      control={control}
                      render={({field: {onChange, value}}) => (
                        <ToggleButton value={value} onChange={onChange} />
                      )}
                    />
                  </div>
                </>
              )}
              {user.userRole === "User" && (
                <>
                  <div className={styles.optionalCheckboxRow}>
                    <span>
                      Can view all goals of {learnerFullName}
                    </span>
                    <LxTooltip tooltipText={"User can view all goals of the learner including goals created by other users"}>
                      <LxIcon icon={InfoOutlineIcon} />
                    </LxTooltip>
                    <Controller
                      name="canViewAllGoals"
                      control={control}
                      render={({field: {onChange, value}}) => (
                        <ToggleButton value={value} onChange={onChange} />
                      )}
                    />
                  </div>
                  <div className={styles.optionalCheckboxRow}>
                    <span>
                      Can edit and delete all goals of {learnerFullName}
                    </span>
                    <LxTooltip tooltipText={"User can edit and delete all goals of the learner including goals created by other users"}>
                      <LxIcon icon={InfoOutlineIcon} />
                    </LxTooltip>
                    <Controller
                      name="canEditDeleteAllGoals"
                      control={control}
                      render={({field: {onChange, value}}) => (
                        <ToggleButton value={value} onChange={onChange} />
                      )}
                    />
                  </div>
                </>
              )}
            </form>
          </div>
          <div className={styles.modalFooter}>
            <div
              className={cn("lxActionButton lxActionButtonDefaultSize")}
              onClick={onClose}
            >
              <span>Cancel</span>
            </div>
            <div
              className={cn(
                "lxActionButton",
                "lxActionButtonFilled lxActionButtonDefaultSize"
              )}
              onClick={handleSubmit(onSubmit)}
            >
              <span>Update Access</span>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  )
}
