import Snackbar from '@mui/material/Snackbar'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'

const LxSnackbar = ({
  open,
  message,
  onClose = { onClose },
  duration = 2000,
}) => {
  const action = (
    <IconButton
      size='small'
      aria-label='close'
      color='inherit'
      onClick={onClose}
    >
      <CloseIcon fontSize='small' />
    </IconButton>
  )

  return (
    <Snackbar
      open={open}
      autoHideDuration={duration}
      onClose={onClose}
      message={message}
      action={action}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    />
  )
}

export default LxSnackbar
