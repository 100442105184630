import { useEffect, useState } from "react";

import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { type SilentRequest } from "@azure/msal-browser";

// graphql -react admin middleware
import buildGraphQLProvider from "ra-data-graphql-simple";
import { myBuildQuery } from "@logic/contexts/AppStore/DataProvider.tsx";
import { DataProvider } from "react-admin";
import axios from "axios";

export const useTokenManagement = () => {
  const clientId = localStorage.getItem("msalClientId");

  const tokenRequest: SilentRequest = {
    scopes: [clientId, "openid", "offline_access"],
    forceRefresh: false, // Set this to "true" to skip a cached token and go to the server to get a new token
  };

  const isAuthenticated = useIsAuthenticated();
  const [dataProvider, setDataProvider] = useState<null | DataProvider>(
    () => null
  );
  const [accessTokenExpiration, setAccessTokenExpiration] =
    useState<Date | null>(null);
  const [userId, setUserId] = useState<null | string>(null);
  const [accountId, setAccountId] = useState<null | string>(null);
  const { instance } = useMsal();

  const [selectAccountDialogOpen, setSelectAccountDialogOpen] =
    useState<boolean>(false);
  const [accounts, setAccounts] = useState<any[]>([]);
  const [accessToken, setAccessToken] = useState<string | null>(null);

  const handleLogout = () => {
    // userId, AccoutnId - will set during useTokenManagement context - Home component
    // msalClientId, msalClientUrl, apiUrl- will be set in login page
    // msalB2CId - will be set after login - App component
    const keysToRemove = [
        "userEmail",
        "msalB2CId",
        "UserId",
        "AccountId",
        "msalClientId",
        "msalClientUrl",
        "apiUrl"
    ];

    // issue in msal, sometimes this below status is not cleared so manually clearing the msal.interaction.status
    const itemKey = "msal.interaction.status";
    if (sessionStorage.getItem(itemKey)) {
      sessionStorage.removeItem(itemKey);
    }

    keysToRemove.forEach(key => localStorage.removeItem(key));
    instance.logoutRedirect();
  }


  const apiUrl = localStorage.getItem("apiUrl");

  useEffect(() => {
    const fetchADB2C = async () => {
      const localUserId = localStorage.getItem("UserId");
      const localAccountId = localStorage.getItem("AccountId");

      // get user id from ADb2C call
      const account = instance.getActiveAccount();
      const b2cId = account?.localAccountId;
      const authResult = await instance.acquireTokenSilent(tokenRequest);
      const newAccessToken = authResult.accessToken;
      setAccessToken(newAccessToken);

      
      if (localUserId && localAccountId ) {
        setUserId(localUserId);
        setAccountId(localAccountId);
        createDataProvider(newAccessToken, localUserId, localAccountId);
      } else {
        const graphqlQuery = `
          query GetAdb2cUser($id: ID!) {
            Adb2c(id: $id) {
              id
              accounts {
                account_name
                user_id
                account_id
              }
            }
          }
        `;

        const variables = {
          id: b2cId,
        };

        const requestBody = {
          query: graphqlQuery,
          variables,
        };

        try {
          const response = await axios.post(apiUrl, requestBody, {
            headers: {
              Authorization: `Bearer ${newAccessToken}`,
              "Content-Type": "application/json",
              authMethod: "B2CWEB",
            },
          });

          
          const accounts = response.data.data.Adb2c?.accounts;

          if (accounts && accounts.length > 1) {
            setAccounts(accounts);
            setSelectAccountDialogOpen(true);
          } else if (accounts?.length === 1) {
            const userId = accounts[0].user_id;
            const accountId = accounts[0].account_id;

            localStorage.setItem("UserId", userId);
            localStorage.setItem("AccountId", accountId);

            setUserId(userId);
            setAccountId(accountId);

            createDataProvider(newAccessToken, userId, accountId);
          } else {
            handleLogout();
          }
        } catch (error) {
          handleLogout();
        }
      }
    };

    fetchADB2C();
  }, [instance]);

  const renewAccessToken = async () => {
    try {
      const authResult = await instance.acquireTokenSilent(tokenRequest);
      const newAccessToken = authResult.accessToken;
      setAccessToken(newAccessToken);
      setAccessTokenExpiration(authResult.expiresOn);

      if (userId && accountId) {
        createDataProvider(newAccessToken, userId, accountId);
      }
    } catch (error) {
      handleLogout();
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (
        accessTokenExpiration === null ||
        accessTokenExpiration.getTime() < Date.now()
      ) {
        await renewAccessToken();
      }
    };

    fetchData();

    const tokenCheckInterval = setInterval(() => {
      if (accessTokenExpiration !== null) {
        const timeUntilExpiration =
          accessTokenExpiration.getTime() - Date.now();
        if (timeUntilExpiration <= 600000) {
          renewAccessToken();
        }
      }
    }, 600000);

    return () => {
      clearInterval(tokenCheckInterval);
    };
  }, [isAuthenticated, instance, accessTokenExpiration, userId]);

  const createDataProvider = async (newAccessToken, userId, accountId) => {
    try {
      // Create a new data provider with the updated access token
      const graphQlDataProvider = await buildGraphQLProvider({
        buildQuery: myBuildQuery,
        clientOptions: {
          uri: apiUrl,
          headers: {
            authMethod: "B2CWEB",
            UserId: userId,
            AccountId: accountId,
            Authorization: `Bearer ${newAccessToken}`,
          },
        },
      });

      setDataProvider(() => graphQlDataProvider);
    } catch (error) {
      console.error("Error in creating data provider:", error);
    }
  };

  const handleAccountSelection = (event, value) => {
    if (value) {
      const selectedUserId = value.user_id;
      const selectedAccountId = value.account_id;

      setUserId(selectedUserId);
      setAccountId(selectedAccountId);

      localStorage.setItem("UserId", selectedUserId);
      localStorage.setItem("AccountId", selectedAccountId);
      createDataProvider(accessToken, selectedUserId, selectedAccountId);
      setSelectAccountDialogOpen(false);
    }
  };

  return {
    dataProvider,
    selectAccountDialogOpen,
    setSelectAccountDialogOpen,
    userId,
    accounts,
    handleAccountSelection,
  };
};
