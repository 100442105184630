import React, {FC, useState} from 'react'
import {useActivityReport} from '@logic/contexts/Learners/ActivityReport/LearnerActivityReportContext.tsx'
import {Bar} from 'react-chartjs-2'
import styles from './LearnerActivityReport.module.scss'
import {
  barChartConfig,
  chartConfigSecondary,
  generateChartOptions,
} from '@src/pages/Learner/Show/Activities/activityReport/chart.utils.ts'
import {useCustomCursor} from '@logic/contexts/Learners/ActivityReport/useCustomCursor.hook.ts'
import {LxLoadingSpinner} from '@components/loader/loadingSpinner.tsx'
import {isNilOrEmpty} from '@src/utils/isNilOrEmpty.ts'
import {
  ActivityDetailDialog,
  SelectedDetailsActivity,
} from '@src/pages/Learner/Show/Activities/activityReport/ActivityReportDetailsModal.tsx'

const options = generateChartOptions(
  'Total No. of questions and correct answers'
) as any

type Props = {
  unitName?: string
}
export const QuestionChart: FC<Props> = ({unitName}) => {
  const {activityChartData, activityAssessment, isAssessmentLoading} =
    useActivityReport()
  const {onHover, ref} = useCustomCursor()

  const [selectedActivity, setSelectedActivity] =
    useState<SelectedDetailsActivity>(null)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleCloseModal = () => {
    setIsModalOpen(false) // Close the modal
  }

  const onChartClick = (event, elements) => {
    if (elements.length > 0) {
      const dataIndex = elements[0].index
      if (activityAssessment && activityAssessment.length > dataIndex) {
        setSelectedActivity({
          results: activityAssessment[dataIndex].results,
          unitName,
          sessionDate: activityChartData?.labels[dataIndex],
        })

        setIsModalOpen(true) // Open the modal
      } else {
        setSelectedActivity(null)
      }
    }
  }

  return (
    <>
      <div className={styles.questionRaportContainer}>
        <h5>Questions practiced per session</h5>
        <p>(click on individual result to see table of details)</p>
        <div className={styles.chartContainer}>
          {isAssessmentLoading ? (
            <LxLoadingSpinner className={styles.chartLoader} />
          ) : isNilOrEmpty(activityChartData) ? (
            <div className={styles.noDataFound}> No data found</div>
          ) : (
            <Bar
              ref={ref}
              options={{...options, onClick: onChartClick, onHover}}
              data={{
                labels: activityChartData!.labels,
                datasets: [
                  {
                    label: 'Total number of questions',
                    data: activityChartData!.allData,
                    ...barChartConfig,
                  },
                  {
                    label: 'Number of correct answers',
                    data: activityChartData!.correctData,
                    ...chartConfigSecondary,
                  },
                  // {
                  //   label: 'Number of wrong answers',
                  //   data: activityChartData.wrongData,
                  //   borderColor: '#191015',
                  //   backgroundColor: '#c03403',
                  //   stack: 'stack2',
                  //   barThickness: 40, // Adjust the width of the bars (adjust as needed)
                  //
                  // },
                ],
              }}
            />
          )}
        </div>
      </div>
      <ActivityDetailDialog
        isModalOpen={isModalOpen}
        handleCloseModal={handleCloseModal}
        selectedActivity={selectedActivity}
        unitName={unitName}
      ></ActivityDetailDialog>
    </>
  )
}
