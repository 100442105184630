import { ChartOptions } from 'chart.js'

export const generateChartOptions = (yScaleText: string, maxY?: number): ChartOptions => {
  return {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        max: maxY,
        title: {
          display: true,
          text: yScaleText,
          font: {
            size: 14,
            family: 'Roboto',
          },
        },
      },
    },
  }
}

export const chartConfig = {
  borderColor: '#191015',
  backgroundColor: '#2e2e52',
}

export const chartConfigSecondary = {
  borderColor: '#191015',
  backgroundColor: '#8fb3dc',
  barThickness: 5, // Adjust the width of the bars (adjust as needed)
}

export const barChartConfig = {
  ...chartConfig,
  barThickness: 5,
}
