import  { FC} from "react";
import { ReportUnitF } from "@logic/contexts/Learners/LearnersFrontend.type.ts";
import styles from "./SkillAreaUnit.module.scss";
import { Line } from "react-chartjs-2";
import {
  chartConfig,
  generateChartOptions,
} from "@src/pages/Learner/Show/Activities/activityReport/chart.utils.ts";
import {
  mapStatusToReadableStatus, TaskStatus,
} from '@src/utils/mapStatusToReadableStatus.ts'
import { cn } from '@src/utils/cn.ts'

const options = generateChartOptions("% of correct answers", 100) as any;

const statusMap = {
  in_progress: "In Progress",
  not_introduced: "Not Introduced",
  emerging: "Emerging",
  mastered: "Mastered",
  generalized: "Generalized",
};

const readableStatus = (status: keyof typeof statusMap) => {
  return statusMap[status];
};

type Props = {
  unit: ReportUnitF;
};
export const SkillAreaUnit: FC<Props> = ({ unit, learner }) => {
  return (
    <div className={styles.activityPdfContainer}>
        <>
          <div id="toPdfReport" className={cn(styles.pdfAdjust)}>
            <h3 className={styles.unitTitle}>{unit.name}</h3>
            <div className={styles.reportActivitySummary}>
              <p className={styles.summaryHeader}>Status</p>
              <p className={styles.summaryHeader}>First Date Introduced</p>
              <p className={styles.summaryHeader}>Last Practiced</p>
              <p className={styles.summaryHeader}>Practiced sessions</p>
              <p className={styles.summaryHeader}>MasteryDate</p>
              <p>{mapStatusToReadableStatus(unit.status as TaskStatus)}</p>
              <p>{unit.firstActivityDate}</p>
              <p>{unit.lastPracticed}</p>
              <p>{unit.activityCount}</p>
              <p>{unit.masteredDate}</p>
            </div>
          </div>
          <div id="toPdfReport" className={styles.pdfAdjust}>
            <h5>Activity Objective: </h5>
            <p>{unit.activityObjective}</p>
          </div>
          <div id="toPdfReport" className={styles.pdfAdjust}>
            <h5>Progress Story: </h5>
            <p>
              {learner.fullName} started {unit.name} on{" "}
              {unit.firstActivityDate}. In the initial attempt, The score achieved
              was {  !isNaN(parseFloat(unit.firstActivityScore)) ? Math.floor(parseFloat(unit.firstActivityScore)) : '-' }%. Since then, the activity has been
              practiced {unit.activityCount} times, with scores of{" "}
              {unit.last3Scores.map((score: string) => `${!isNaN(parseFloat(score)) ? Math.floor(parseFloat(score)) : '-'}%`).join(", ")} in
              the recent trials. {learner.fullName}'s progress status in this
              activity is {readableStatus(unit.status)}.
            </p>
          </div>
          <div id="toPdfReport">
            <h5 className={styles.pdfAdjust}>Progress graph: </h5>
            <div className={styles.chartContainer}>
              <Line
              id="activityChart"
              options={options}
              data={{
                labels: Object.keys(unit.scoresData),
                datasets: [
                  {
                    label: "% score",
                    data: Object.values(unit.scoresData),
                    ...chartConfig,
                  },
                ],
              }}
            />
            </div>
          </div>
        </>
    </div>
  );
};