import React, { createContext, useState, useContext } from 'react';

interface SubmitContextType {
  isFormSubmitting: boolean;
  setIsFormSubmitting: (isSubmitting: boolean) => void;
}

const SubmitContext = createContext<SubmitContextType | undefined>(undefined);

export const SubmitLoadingProvider: React.FC = ({ children }) => {
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);

  return (
    <SubmitContext.Provider value={{ isFormSubmitting, setIsFormSubmitting }}>
      {children}
    </SubmitContext.Provider>
  );
};

export const useSubmitLoader = () => {
  const context = useContext(SubmitContext);
  if (!context) {
    throw new Error('useSubmitLoader must be used within a SubmitLoadingProvider');
  }
  return context;
};
