import { cn } from '@src/utils/cn.ts'

export const GeneratePdfReport = () => {

  const handleExportToPdf = () => {
    window.print();
  };

  return (
    <>
      <div className={cn('lxActionButton ignoreOnPrint lxActionButtonDefaultSize')} onClick={handleExportToPdf}><span>Export to PDF</span></div>
    </>
  )
}
