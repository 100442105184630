import { ChartOptions } from 'chart.js'

const formatTime = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  if (hours === 0) {
    return `${minutes}M`;
  } else {
    if (minutes === 0) {
      return `${hours}H`;
    } else {
      return `${hours}H ${minutes}M`;
    }
  }
};


export const generateTimeChartOptions = (yScaleText: string,   stepSize: number | null = null, max: number | null = null ): ChartOptions => {
  return {
    responsive: true,
    plugins: {
      tooltip: {
        callbacks: {
          label: (context) => {
            let label = context.dataset.label || '';
            if (label) {
              label += ': ';
            }
            if (context.parsed.y !== null) {
              label += formatTime(context.parsed.y * 3600); // Convert back to seconds
            }
            return label;
          },
        },
      },
      legend: {
        display: false
      },
    },
    scales: {
      x: {
        grid: {
          display: false
        }
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: yScaleText,
          font: {
            size: 14,
            family: 'Roboto',
          },
        },
        ticks: {
          stepSize: stepSize,
          callback: (value) => formatTime(value * 3600),
        }, 
        max: max
      },
    },
  }
}



export const generateChartOptions = (yScaleText: string , stepSize: number | null = null, max: number | null = null ): ChartOptions => {
  return {
    responsive: true,
    plugins: {
      legend: {
        display: false
      },
    },
    scales: {
      x: {
        grid: {
          display: false
        }
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: yScaleText,
          font: {
            size: 14,
            family: 'Roboto',
          },
        },
        ticks: {
          stepSize:  stepSize
        },
        max: max
      },
    },
  }
}

export const getChartConfig = (backgroundColor: string, barThickness: number, hidden: boolean = true): any => {
  return {
    borderColor: '#191015',
    backgroundColor: backgroundColor,
    barThickness: barThickness,
    hidden: !hidden
  }
}



