interface LogoProps {
  style?: React.CSSProperties
  height: string
}

const Logo = ({ style, height }: LogoProps) => {
  return (
        <img src="/static/images/logo/logo.png" alt="" height={height} style={style} />
  )
}

export default Logo
