import React, { FC, useState } from 'react'
import styles from "./LearnerGoalsTable.module.scss";
 import { useUserContext } from '@logic/contexts/AppStore/UserContext.tsx'
import { usePolicyContext } from '@logic/contexts/AppStore/AbacContext.tsx'
import { GoalActions } from '@src/pages/Learner/Show/Goals/GoalActions/GoalActions.tsx'
import { GOALF } from '@logic/contexts/Learners/LearnersFrontend.type.ts'
import { mapStatusToReadableStatus } from '@src/utils/mapStatusToReadableStatus.ts'

export const LearnerGoalsTable: FC<any> = ({ goalList }: {goalList: GOALF[]}) => {
  const { currentUser } = useUserContext() as any
  const { canPerformAction } = usePolicyContext()

  const [sortColumn, setSortColumn] = useState<string>('name');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');

  const canEditGoal = canPerformAction(
    currentUser,
    { type: 'Learner' },
    'edit_goal'
  )
 
  const handleSort = (column: string) => {
    if (sortColumn === column) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortOrder('asc');
    }
  };

  const sortedGoals = [...goalList].sort((a, b) => {
    let columnA = a[sortColumn];
    let columnB = b[sortColumn];

    if (sortColumn === 'status') {
      columnA = mapStatusToReadableStatus(columnA);
      columnB = mapStatusToReadableStatus(columnB);
    }

    if (typeof columnA === 'string') {
      return sortOrder === 'asc' ? columnA.localeCompare(columnB) : columnB.localeCompare(columnA);
    } else {
      return sortOrder === 'asc' ? columnA - columnB : columnB - columnA;
    }
  });

  return (
    <div className={styles.summaryTable7Columns}>
      <div className={styles.summaryTableHeader} onClick={() => handleSort('name')}>Goal</div>
      <div className={styles.summaryTableHeader} onClick={() => handleSort('ownerName')}>Owner</div>
      <div className={styles.summaryTableHeader} onClick={() => handleSort('skillAreaName')}>Targeted skill area</div>
      <div className={styles.summaryTableHeader} onClick={() => handleSort('status')}>Status</div>
      <div className={styles.summaryTableHeader} onClick={() => handleSort('priority')}>Priority</div>
      <div className={styles.summaryTableHeader} onClick={() => handleSort('lastPracticed')}>Last Practiced</div>
      <div className={styles.summaryTableHeader}>{canEditGoal ? "Actions" : "" }</div>
      {sortedGoals.map((goal: GOALF, index) => (
        <React.Fragment key={`StudentsGoals-${index}`}>
          <div>{goal.name}</div>
          <div>{goal.ownerName}</div>
          <div>{goal.skillAreaName}</div>
          <div>{mapStatusToReadableStatus(goal.status)}</div>
          <div>{goal.priority}</div>
          <div>{goal.lastPracticed}</div>
          <div>
            { canEditGoal && <GoalActions goal={goal} isLastChild={index === goalList.length - 1}></GoalActions> }
          </div>
        </React.Fragment>
      ))}
    </div>
  );
};