import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";


export const AccountSelectionDialog = ({
  selectAccountDialogOpen,
  accounts,
  handleAccountSelection
}) => {

  return (
    <React.Fragment>
      <Dialog open={selectAccountDialogOpen}  fullWidth={true} maxWidth="xs">
        <DialogTitle >Please Select Account to Continue</DialogTitle>
        <DialogContent>
          <DialogContentText>
          <Autocomplete
              options={accounts}
              getOptionLabel={(account) => `${account.account_name}`}
              onChange={handleAccountSelection}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select an Account"
                  fullWidth
                  margin="normal"
                />
              )}
            />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};
