import React, { ReactNode, useEffect, useRef, useState } from 'react'
import styles from './autocomplete.module.scss'
import { cn } from '@src/utils/cn.ts'
import { LxIcon } from '@components/icon/Icon.tsx'
import { ArrowDownIcon, ArrowUpIcon, SearchIcon } from '@icons/utils'
import { FilterObject } from '@logic/useFiltering.hook.ts'
import { uniqueArrayByKey } from '@src/utils/uniqueArrayByKey.ts'

interface Props {
  value: FilterObject<any>[] | FilterObject<any> | null;
  onChange: (val: any) => void;
  options: FilterObject<string>[];
  className?: string;
  multiple?: boolean;
  simple?: boolean;
  selectVariant?: boolean;
  placeholder?: string;
}

export const LxAutocomplete: React.FC<Props> = (
  {
    onChange,
    options,
    simple,
    value,
    className,
    multiple = true,
    placeholder,
    selectVariant
  }) => {
  const [ isOpen, setIsOpen ] = useState(false)
  const [ selectedOptions, setSelectedOptions ] = useState(value ?? [])
  const [ filteredOptions, setFilteredOptions ] = useState<FilterObject<any>[]>(options)
  const [ searchQuery, setSearchQuery ] = useState('')


  const selectRef = useRef<HTMLDivElement>(null)
  const toggleDropdown = () => setIsOpen(!isOpen)


  const resetFilter = () => {
    setSelectedOptions([])
    onChange([])
  }

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value)
  }

  const handleOptionClick = (optionValue: FilterObject<any>) => {
    // Check if the option is already in the selectedOptions array
    const isOptionSelected = selectedOptions.some((selectedOption) => selectedOption.label === optionValue.label)

    // If it's already selected, remove it; otherwise, add it
    const newOptions = isOptionSelected
      ? selectedOptions.filter((selectedOption) => selectedOption.label !== optionValue.label)
      : multiple
        ? uniqueArrayByKey([ ...selectedOptions, optionValue ], 'value')
        : optionValue

    // Update state and invoke onChange
    setSelectedOptions(newOptions)
    onChange(newOptions)

    // Close the dropdown if not in multiple mode
    if (!multiple) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
        setIsOpen(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [ selectRef ])

  useEffect(() => {
    // Filtering logic based on the input value
    const filteredOptions = options.filter((option) =>
      option.label.toLowerCase().includes(searchQuery.toLowerCase())
    )

    setFilteredOptions(filteredOptions)
  }, [ searchQuery, options ])


  useEffect(() => {
    setSelectedOptions(value ?? [])
  }, [ value ])


  const autoCompletePlaceholder = () => {
    if (( selectedOptions as [] ).length > 0) {
      return ( selectedOptions as FilterObject[] ).map((option, index) => <div
        key={`selected-option-${index}`}>{option.label}</div>)
      // return `(${(selectedOptions as []).length}) ${placeholder || 'Select an option'}`
    }
    return placeholder || 'Select an option'
  }


  return (
    <div
      className={cn(styles.autocompleteContainer,
        { [styles.selected]: selectedOptions.some(selectedOption => selectedOption.value) },
        { [styles.autocompleteContainerSelectVariant]: selectVariant },
        className)}
      ref={selectRef}>
      <div onClick={toggleDropdown}>
        <div className={styles.placeholder}>{autoCompletePlaceholder()}</div>
        <LxIcon icon={isOpen ? ArrowUpIcon : ArrowDownIcon} customViewBox={'0 0 960 560'}></LxIcon>
      </div>
      {isOpen && (
        <div className={styles.selectItems}>
          <div className={styles.filters}>
            <div>
              <div className={cn('lxActionButton', { [styles.hide]: simple })}>
                <LxIcon icon={SearchIcon} sxStyles={{ height: '24px', width: '24px' }}></LxIcon>
                <input
                  className={'pristineInput'}
                  type="text"
                  onChange={handleInputChange}
                  value={searchQuery}
                  placeholder="Search"
                ></input>
              </div>
              {selectVariant && <div className={'lxActionButton'} onClick={() => setIsOpen(false)}>Close</div>}
              <div className={cn('lxActionButton', { 'disabled': !selectedOptions.length }, styles.clearButton)}
                   onClick={resetFilter}>Clear
              </div>
            </div>
            {/*<div className={styles.truncateText}>Selected: {selectedOptions.map(option => option.value).toString()}</div>*/}
          </div>
          {filteredOptions.map((option, index) => (
            <div key={`${index}-${option.value}`}
                 className={cn(styles.selectItem,
                   {
                     [styles.selected]: selectedOptions.some(selectedOption => {
                       return selectedOption.label === option.label
                     })
                   },
                   { 'disabled': !option.isEnabled }
                 )}
                 onClick={() => handleOptionClick(option)}>
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
