import React, { FC, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { SkillAreaF, GoalF, UnitF } from '@logic/contexts/Activities/ActivitiesFrontend.type.ts';
import { FilterObject, generateSimpleOptions } from '@logic/useFiltering.hook.ts'
import { LxSelect } from '@components/select/select.tsx'
import { cn } from '@src/utils/cn.ts'
import styles from './GoalForm.module.scss'
import { LxTooltip } from '@components/tooltip/tooltip.tsx'
import { LxAutocomplete } from '@components/autocomplete/autocomplete.tsx'
import modalStyles from '@components/modal/modal.module.scss'
import { LearnerF } from '@logic/contexts/Learners/LearnersFrontend.type.ts'
import { LxSelectInput } from '@components/select/selectInput.tsx'
import { yupResolver } from '@hookform/resolvers/yup'
import { isNilOrEmpty } from '@src/utils/isNilOrEmpty.ts'
import { useFormNotify } from '@components/formNotify/useFormNotify.tsx'


export const clientUnitToServerUnit = (units: FilterObject<UnitF>[]) => units.map((unit: FilterObject<UnitF>) => ({
    id: unit.value.unitId,
    sort_order: unit.value.sortOrder ?? unit.value.levelNumber,
    title: unit.value.title ?? unit.value.unitTitle})
).flat()


const schema = (dataSet: GoalFormDataSet) => yup.object({
  learner: !isNilOrEmpty(dataSet.learners) ? yup.object().required() : yup.object().nullable(),
  skillArea: !isNilOrEmpty(dataSet.skillAreas) ? yup.object().required('Skill area is required') : yup.object().nullable(),
  goal: !isNilOrEmpty(dataSet.skillAreas) ? yup.object().required('Goal is required') : yup.object().nullable(),
  units: yup.array().of(yup.object()).required('At least one activity is required'),
  description: yup.string(),
  priority: yup.string().required('Priority is required'),
}).required();

export type GoalFormType = {
  learner?: LearnerF;
  skillArea?: SkillAreaF;
  goal?: GoalF;
  units: UnitF[];
  description: string;
  priority: string;
};

export type GoalFormDataSet = {
  learners?: FilterObject<LearnerF>[];
  skillAreas?: FilterObject<SkillAreaF>[];
  units?: FilterObject<UnitF>[];
};

type Props = {
  onSubmit: (formData: GoalFormType) => void;
  defaultValues?: GoalFormType;
  dataSet: GoalFormDataSet;
  isEdit?: boolean;
  onCancel: () => void;
};

export const GoalForm: FC<Props> = ({ onSubmit, defaultValues, dataSet, isEdit, onCancel }) => {
  const [goalsOptions, setGoalsOptions] = useState<FilterObject<GoalF>[]>([]);
  const [unitsOptions, setUnitsOptions] = useState<FilterObject<UnitF>[]>(dataSet.units || []);

  const { control, handleSubmit, setValue, formState: { errors, submitCount, isValid } } = useForm<GoalFormType>({
    defaultValues: defaultValues || {},
    resolver: yupResolver(schema(dataSet)),
  });

  useFormNotify(errors, submitCount, isValid)

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className={styles.goalFormContainer}>
        {dataSet.learners && (
          <Controller
            name="learner"
            control={control}
            render={({ field }) => (
              <LxSelectInput
                value={field.value}
                onChange={field.onChange}
                options={dataSet.learners}
                placeholder="Select Learner"
                hasError={!!errors.learner}
              />
            )}
          />
        )}

        {dataSet.skillAreas && (
          <Controller
            name="skillArea"
            control={control}
            render={({ field, fieldState }) => (
              <LxSelect
                value={field.value}
                onChange={(selectedSkillArea: SkillAreaF) => {
                  field.onChange(selectedSkillArea)
                  setValue('goal', null)
                  setValue('units', [])
                  const goalOptions = generateSimpleOptions(selectedSkillArea.goals, 'name')
                  setGoalsOptions(goalOptions)
                  setUnitsOptions([]) // reset units to ensure they match the new goal options
                }}
                options={dataSet.skillAreas}
                placeholder="Targeted Skill Area"
                hasError={!!fieldState.error}
              />
            )}
          />
        )}

        {
          dataSet.skillAreas &&
            <Controller
                name="goal"
                control={control}
                render={({ field, fieldState }) => (
                  <LxTooltip
                    tooltipText={goalsOptions.length === 0 ? 'Please select targeted Skill Area to load goal options' : null}>
                    <LxSelect
                      className={cn({ ['disabled']: goalsOptions.length === 0 })}
                      value={field.value}
                      onChange={(selectedGoal: GoalF) => {
                        field.onChange(selectedGoal)
                        setValue('units', [])
                        const unitOptions = generateSimpleOptions(selectedGoal.units, 'title')
                        setUnitsOptions(unitOptions)
                      }}
                      options={goalsOptions}
                      placeholder="Goal"
                      hasError={!!fieldState.error}
                    />
                  </LxTooltip>
                )}
            />
        }

        <Controller
          name="units"
          control={control}
          render={({ field, fieldState }) => (
            <LxTooltip
              tooltipText={unitsOptions.length === 0 ? 'Please select targeted Goal to load activity options' : null}>
              <LxAutocomplete
                multiple
                selectVariant
                className={cn({ ['disabled']: unitsOptions.length === 0 }, {['formFieldRequired']: !!fieldState.error})}
                placeholder={'Add Associated Activity'}
                options={unitsOptions}
                value={field.value}
                onChange={(values: FilterObject<string>[]) => field.onChange(values)}
              ></LxAutocomplete>
            </LxTooltip>
          )}
        />

        <Controller
          name="priority"
          control={control}
          render={({ field }) => (
            <LxSelect
              value={field.value}
              onChange={value => field.onChange(value)}
              options={[
                { value: 'low', label: 'Low' },
                { value: 'medium', label: 'Medium' },
                { value: 'high', label: 'High' },
              ]}
              placeholder="Priority"
              hasError={!!errors.priority}
            />
          )}
        />
        <Controller
          name="description"
          control={control}
          render={({ field: { onChange, value }, fieldState}) => (
            <div className={cn(styles.inputField, styles.descriptionField, 'lxActionButton', {['formFieldRequired']: !!fieldState.error})}>
            <textarea
              className={'pristineInput'}
              type="text"
              value={value}
              onChange={(e) => onChange(e.target.value)}
              placeholder="Goal Description"
            />
            </div> )}
        />
      </form>
      <div className={modalStyles.dialogActions}>
        <div
          className={cn('lxActionButton lxActionButtonDefaultSize')}
          onClick={onCancel}
        >
          <span>Cancel</span>
        </div>
        <div
          className={cn('lxActionButton lxActionButtonFilled lxActionButtonDefaultSize')}
          onClick={handleSubmit(onSubmit)}
        >
          <span>{isEdit ? 'Save Changes' : 'Add This Goal'}</span>
        </div>
      </div>
    </>
  );
};
