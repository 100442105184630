import * as React from "react";
const SvgCurriculumIndividual = (props) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", width: "384px", height: "384px", viewBox: "0 0 384 384", ...props }, /* @__PURE__ */ React.createElement("g", { id: "surface1" }, /* @__PURE__ */ React.createElement("path", { style: {
  stroke: "none",
  fillRule: "nonzero",
  fill: "rgb(18.039216%,18.039216%,32.156863%)",
  fillOpacity: 1
}, d: "M 299.199219 87.199219 C 289.601562 87.199219 281.601562 79.199219 281.601562 69.601562 C 281.601562 60 289.601562 52 299.199219 52 C 308.800781 52 316.800781 60 316.800781 69.601562 C 316.800781 79.199219 308.800781 87.199219 299.199219 87.199219 Z M 299.199219 28 C 285.601562 28 272.800781 34.800781 265.199219 46 C 257.601562 57.199219 256 71.601562 260.800781 84.398438 L 279.601562 125.601562 L 295.601562 159.601562 C 296.800781 161.601562 299.199219 162.398438 300.800781 161.199219 C 301.601562 160.800781 302 160.398438 302.398438 159.601562 L 318.398438 125.601562 L 337.199219 84.398438 C 342 71.601562 340.398438 57.199219 332.800781 46 C 325.601562 34.800781 312.800781 28 299.199219 28 Z M 299.199219 28 " }), /* @__PURE__ */ React.createElement("path", { style: {
  stroke: "none",
  fillRule: "nonzero",
  fill: "rgb(18.039216%,18.039216%,32.156863%)",
  fillOpacity: 1
}, d: "M 95.199219 247.199219 C 83.199219 247.199219 73.199219 237.199219 73.199219 225.199219 C 73.199219 213.199219 83.199219 203.199219 95.199219 203.199219 C 107.199219 203.199219 117.199219 213.199219 117.199219 225.199219 C 117.199219 237.199219 107.199219 247.199219 95.199219 247.199219 Z M 95.199219 174 C 78.398438 174 62.398438 182.398438 52.800781 196.398438 C 43.199219 210.398438 41.199219 228.398438 47.601562 244 L 70.800781 295.199219 L 90.800781 337.199219 C 92 339.601562 95.199219 340.398438 97.601562 339.199219 C 98.398438 338.800781 99.199219 338 99.601562 337.199219 L 119.601562 295.199219 L 142.800781 244 C 148.800781 228.398438 146.800781 210.398438 137.601562 196.398438 C 128 182.398438 112 174 95.199219 174 Z M 95.199219 174 " }), /* @__PURE__ */ React.createElement("path", { style: {
  stroke: "none",
  fillRule: "nonzero",
  fill: "rgb(18.039216%,18.039216%,32.156863%)",
  fillOpacity: 1
}, d: "M 151.199219 340 L 140.800781 340 C 136 340 131.601562 340 127.199219 340 L 127.199219 316 C 131.199219 316 136 316 140.800781 316 L 151.199219 316 Z M 151.199219 340 " }), /* @__PURE__ */ React.createElement("path", { style: {
  stroke: "none",
  fillRule: "nonzero",
  fill: "rgb(18.039216%,18.039216%,32.156863%)",
  fillOpacity: 1
}, d: "M 167.601562 340 L 167.601562 316 C 176.398438 316 184 316 191.199219 315.601562 L 192 339.601562 C 184.398438 340 176.398438 340 167.601562 340 Z M 167.601562 340 " }), /* @__PURE__ */ React.createElement("path", { style: {
  stroke: "none",
  fillRule: "nonzero",
  fill: "rgb(18.039216%,18.039216%,32.156863%)",
  fillOpacity: 1
}, d: "M 208 338.800781 L 206.800781 314.800781 C 215.199219 314.398438 223.199219 314 230 313.199219 L 232.398438 337.199219 C 225.199219 338 216.800781 338.398438 208 338.800781 Z M 208 338.800781 " }), /* @__PURE__ */ React.createElement("path", { style: {
  stroke: "none",
  fillRule: "nonzero",
  fill: "rgb(18.039216%,18.039216%,32.156863%)",
  fillOpacity: 1
}, d: "M 249.199219 335.199219 L 245.601562 311.601562 C 253.199219 310.398438 260.398438 309.199219 267.601562 307.199219 L 274 330.398438 C 265.601562 332.398438 257.601562 334 249.199219 335.199219 Z M 249.199219 335.199219 " }), /* @__PURE__ */ React.createElement("path", { style: {
  stroke: "none",
  fillRule: "nonzero",
  fill: "rgb(18.039216%,18.039216%,32.156863%)",
  fillOpacity: 1
}, d: "M 290.800781 324.398438 L 281.199219 302.398438 C 286.398438 300.800781 290.800781 297.199219 294 292.800781 L 315.199219 304.398438 C 309.199219 313.601562 300.800781 320.398438 290.800781 324.398438 Z M 290.800781 324.398438 " }), /* @__PURE__ */ React.createElement("path", { style: {
  stroke: "none",
  fillRule: "nonzero",
  fill: "rgb(18.039216%,18.039216%,32.156863%)",
  fillOpacity: 1
}, d: "M 294.398438 284.800781 C 293.601562 279.601562 291.199219 274.398438 287.601562 270.800781 L 304.398438 254 C 312 261.601562 316.800781 271.199219 318 282 Z M 294.398438 284.800781 " }), /* @__PURE__ */ React.createElement("path", { style: {
  stroke: "none",
  fillRule: "nonzero",
  fill: "rgb(18.039216%,18.039216%,32.156863%)",
  fillOpacity: 1
}, d: "M 277.601562 263.601562 C 271.199219 260.398438 264.398438 257.601562 257.601562 255.601562 L 264.398438 232.800781 C 272.800781 235.199219 281.199219 238.398438 288.800781 242.398438 Z M 277.601562 263.601562 " }), /* @__PURE__ */ React.createElement("path", { style: {
  stroke: "none",
  fillRule: "nonzero",
  fill: "rgb(18.039216%,18.039216%,32.156863%)",
  fillOpacity: 1
}, d: "M 242.398438 251.601562 L 236 250 C 230.398438 248.398438 224.398438 247.199219 218.800781 245.601562 L 225.199219 222.398438 C 230.398438 224 236 225.199219 241.601562 226.800781 L 248 228.398438 Z M 242.398438 251.601562 " }), /* @__PURE__ */ React.createElement("path", { style: {
  stroke: "none",
  fillRule: "nonzero",
  fill: "rgb(18.039216%,18.039216%,32.156863%)",
  fillOpacity: 1
}, d: "M 202.398438 240 C 192.800781 236.398438 184 230.398438 177.601562 222.398438 L 197.199219 208.800781 C 200.800781 213.199219 206 216.398438 211.199219 218 Z M 202.398438 240 " }), /* @__PURE__ */ React.createElement("path", { style: {
  stroke: "none",
  fillRule: "nonzero",
  fill: "rgb(18.039216%,18.039216%,32.156863%)",
  fillOpacity: 1
}, d: "M 195.601562 200.800781 L 171.601562 199.199219 C 172.398438 187.601562 178.800781 177.199219 188 170.398438 L 202.800781 189.199219 C 195.601562 195.199219 195.601562 199.601562 195.601562 200.800781 Z M 195.601562 200.800781 " }), /* @__PURE__ */ React.createElement("path", { style: {
  stroke: "none",
  fillRule: "nonzero",
  fill: "rgb(18.039216%,18.039216%,32.156863%)",
  fillOpacity: 1
}, d: "M 214 183.199219 L 204.398438 161.199219 C 212.398438 157.601562 220.800781 154.800781 229.199219 152.800781 L 234.800781 176 C 227.601562 178 220.800781 180.398438 214 183.199219 Z M 214 183.199219 " }), /* @__PURE__ */ React.createElement("path", { style: {
  stroke: "none",
  fillRule: "nonzero",
  fill: "rgb(18.039216%,18.039216%,32.156863%)",
  fillOpacity: 1
}, d: "M 249.601562 173.199219 L 245.601562 149.601562 C 256 148 264.800781 146.800781 270.398438 146.398438 L 272.398438 170.398438 C 268.398438 170.800781 259.601562 171.601562 249.601562 173.199219 Z M 249.601562 173.199219 " })));
export default SvgCurriculumIndividual;
