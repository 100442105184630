import React, { useEffect, useState } from 'react'
import { useDataProvider } from 'react-admin'
import styles from './ActivitiesShowModal.module.scss'
import { LxLoadingSpinner } from '@src/components/loader/loadingSpinner'
import { Dialog } from '@mui/material'
import { cn } from '@src/utils/cn'

interface ActivitiesShowModalProps {
  isOpen: boolean
  onClose: () => void
  unitId: string
}

const ActivitiesShowModal: React.FC<ActivitiesShowModalProps> = ({
  isOpen,
  onClose,
  unitId,
}) => {
  const dataProvider = useDataProvider()
  const [activity, setActivity] = useState<any>(null)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (unitId) {
      setIsLoading(true)
      dataProvider
        .getOne('Item', { id: unitId })
        .then(({ data }) => {
          setActivity(data)
          setIsLoading(false)
        })
        .catch(() => {
          setIsLoading(false)
        })
    }
  }, [unitId, dataProvider])

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="md" fullWidth>
      <div className={styles.materialUiContainerWrapper}>
        <div className={styles.modalContainer}>
          <div className={styles.modalHeader}>
            <h3>{activity?.extras?.unit_title}</h3>
            {!isLoading && (
              <div
                className={cn(
                  'lxActionButton',
                  'lxActionButtonFilled' 
                )}
                onClick={onClose}
                style={{ marginLeft: '30px'  }}
              >
                <span>Close</span>
              </div>
            )}
          </div>
          <div className={styles.modalContent}>
            {isLoading && (
              <div className={styles.LoadingOverlay}>
                <LxLoadingSpinner />
              </div>
            )}
            {(activity?.extras?.app_notes || []).map(
              (note, index) =>
                note.desc && (
                  <div className={styles.activityNote} key={index}>
                    <h5>{note.title}</h5>
                    <p>{note.desc}</p>
                  </div>
                )
            )}
          </div>
           
        </div>
      </div>
    </Dialog>
  )
}

export default ActivitiesShowModal
