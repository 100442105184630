import React, { useState, useRef, useEffect, ReactNode, FC } from 'react'
import styles from './select.module.scss'
import { cn } from '@src/utils/cn.ts'
import { LxIcon } from '@components/icon/Icon.tsx' // Assuming you have a CSS module for styles
import { ArrowDownIcon, ArrowUpIcon, CloseIcon, SearchIcon } from '@icons/utils'
import { FilterObject } from '@logic/useFiltering.hook.ts'
import { isNilOrEmpty } from '@src/utils/isNilOrEmpty.ts'
import { LxClickAbleIcon } from '@components/icon/clickAbleIcon.tsx'
import { LxDropdown } from '@components/select/dropdown.tsx'

type Props<T> = {
  value?: T
  onChange: (value: T | null) => void
  onReset?: () => void
  options: FilterObject<T>[]
  className?: string
  hasError?: boolean
  placeholder?: string
}


export const LxSelectInput: FC = <T,>(
  {
    placeholder,
    value,
    onChange,
    onReset,
    options,
    hasError,
    className,
  }: Props<T>) => {
  const [ isOpen, setIsOpen ] = useState(false)
  const [ selectedOption, setSelectedOption] = useState< FilterObject<T>>(null)
  const [ searchQuery, setSearchQuery ] = useState('')
  const [ displaySearchQuery, setDisplaySearchQuery ] = useState('')
  const [ filteredOptions, setFilteredOptions] = useState<FilterObject<T>[]>(options)
  const selectRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef(null);

  const handleOptionClick = (optionValue: FilterObject<T>) => {
    onChange(optionValue)
    setSearchQuery(optionValue.label!)
    setDisplaySearchQuery(optionValue.label!)
    setSelectedOption(optionValue)
    setIsOpen(false)
  }

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
        setIsOpen(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [ selectRef ])


  useEffect(() => {
    // Filtering logic based on the input value
    if (isNilOrEmpty(searchQuery)) {
      setFilteredOptions(options)
      return
    }
    const filteredOptions = options.filter((option) =>
      option.label.toLowerCase().includes(searchQuery.toLowerCase())
    );

    setFilteredOptions(filteredOptions);
  }, [searchQuery, options]);


  useEffect(() => {
    setSearchQuery(value?.label ? value.label : '')
    setSelectedOption(options.find(option => option.value?.id === value?.id) ?? null) //TODO improve
  }, [value, options]);

  useEffect(() => {
    if(isOpen) {
      inputRef.current.focus()
    }
  }, [isOpen]);


  const handleInputChange = (event) => {
    setDisplaySearchQuery(event.target.value)
    setSearchQuery(event.target.value);
  };

  const resetInput = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation()
    onReset && onReset()
    setSearchQuery('')
    setDisplaySearchQuery('')
    setSelectedOption(null)
    setIsOpen(false)
    onChange(null);
  }


  const selected = options.find(option => option.value === value)

  const toggleDropdown = () => setIsOpen(prev => !prev)

  return (
    <div className={cn(styles.customSelect, className)} ref={selectRef}>
      <div
        className={cn(styles.defaultStyles, styles.selectSelected, {
          ['formFieldRequired']: hasError,
        })}
        onClick={toggleDropdown}>
        <div className={styles.selectInput}>
          <LxIcon icon={SearchIcon} sxStyles={{height: '24px', width: '24px'}}></LxIcon>
          <input
            ref={inputRef}
            className={'pristineInput'}
            onBlur={() => {setDisplaySearchQuery(prev => selectedOption?.label ? selectedOption.label : prev)}}
            type="text"
            value={displaySearchQuery}
            onChange={handleInputChange}
            placeholder={placeholder || 'Search'}
          />
        </div>
        <div className={styles.actions}>
          <LxClickAbleIcon
            icon={CloseIcon}
            onClick={resetInput}
            sxStyles={{height: '16px', width: '16px'}}
            customViewBox={"0 0 460 460"}
            className={cn({['disabled']: isNilOrEmpty(selectedOption)})}
          >
          </LxClickAbleIcon>
          <LxIcon icon={isOpen ? ArrowUpIcon : ArrowDownIcon}
                  customViewBox={'0 0 960 560'}></LxIcon>
        </div>
      </div>
      {isOpen &&
          <LxDropdown
              options={filteredOptions}
              onOptionClick={handleOptionClick}
              optionRenderer={(option) => option.optionToRender ? option.optionToRender : option.label}
          />
      }
    </div>
  )
}
