import { useActivitiesList } from '@logic/contexts/Activities/ActivitiesListContext.tsx'
import styles from './GroupCurriculum.module.scss'
import { LxLoadingSpinner } from '@components/loader/loadingSpinner.tsx'
import { isNilOrEmpty } from '@src/utils/isNilOrEmpty.ts'
import { useLearnersList } from '@logic/contexts/Learners/LearnersListContext.tsx'
import { getSvgComponent } from '@src/assets/icons/skillAreas/svgMapper.util'
import { LxIcon } from '@src/components/icon/Icon'
import { cn } from '@src/utils/cn'
import { PlusOutlineIcon } from '@icons/utils'
import React, {  } from 'react'
import CurriculumEnrollModal from './GroupCurriculumEnroll'
import { useModal } from '@src/logic/contexts/Modal/ModalContext'
import { generateSimpleOptions } from '@src/logic/useFiltering.hook'
import { CurriculumIcon, CurriculumIndIcon } from '@src/assets/icons'

export const GroupCurriculum = () => {
  const { displayData, isLoading } = useActivitiesList()
  const { learnerList, isLoading: isLearnerListLoading } = useLearnersList()
  const { showModal } = useModal();

  const filteredSkillAreas = !isNilOrEmpty(displayData)
  ? displayData?.skillAreas.filter((skillArea) => skillArea.type === 'curriculum')
  : []

  const learnerOptions = generateSimpleOptions(learnerList, 'fullName')


  return (
    <div className={styles.activitiesContainer}>
      {isLoading || isLearnerListLoading ? (
        <div className={styles.placeholderContainer}>
          <LxLoadingSpinner />
        </div>
      ) : (
        <>
          <div className={cn(styles.skillAreaRow)}>
            <LxIcon
              sxStyles={{ height: '32px', width: '32px' }}
               
              icon={CurriculumIcon}
            ></LxIcon>
            <h4>Curriculums</h4>
          </div>
          {!isNilOrEmpty(filteredSkillAreas) ? (
            filteredSkillAreas?.map((curriculum, index) => (
              <React.Fragment key={`${curriculum.id}-${index}`}>
                <div className={styles.curriculumContainer} onClick={() => showModal(<CurriculumEnrollModal curriculum={curriculum} allLearners={learnerOptions} />)}>
                  <div className={styles.curriculumHeader}>
                    <div className={styles.curriculumContent}>
                    <LxIcon
                      sxStyles={{ height: '24px', width: '24px', marginRight: '8px' }}
                      icon={CurriculumIndIcon}
                    />
                      {curriculum.name.replace("Curriculum - ", "")}
                    </div>
                    <LxIcon
                      sxStyles={{ height: '40px', width: '40px' }}
                      icon={PlusOutlineIcon}
                    />
                  </div>
                </div>
              </React.Fragment>
            ))
          ) : (
            <div className={styles.placeholderContainer}>
              <h5>No data found, please try changing filters</h5>
            </div>
          )}
        </>
      )}

    
    </div>
  )
}

export default GroupCurriculum
