// This page is for user viewing his assistans assignments

import React, {useState, useEffect} from "react"
import {SearchIcon, PlusIcon, MinusIcon,SettingsIcon} from "@icons/utils"

import styles from "../UserLearners.module.scss"
import sharedStyles from "../../../../MultiPage.module.scss"
import {LxIcon} from "@components/icon/Icon.tsx"
import {cn} from "@src/utils/cn.ts"
import {LxLoadingSpinner} from "@components/loader/loadingSpinner.tsx"
import {LxClickAbleIcon} from "@components/icon/clickAbleIcon.tsx"
import {AddNewUserLearner} from "@src/pages/User/Show/Learners/AssistantViews/UserAddLearnerToAssistant.tsx"
import {ConfirmationModal} from "@src/utils/confirmationModel"
import {isNilOrEmpty} from "@src/utils/isNilOrEmpty.ts"
import {mapRoleToReadableRole} from "@src/utils/mapRoleToReadableRole.ts"
import {EditAccess} from "../EditAccess"

import {useDataProvider, useNotify, useRefresh} from "react-admin"
import { useModal } from '@logic/contexts/Modal/ModalContext.tsx'
import { useSubmitLoader } from '@src/logic/contexts/utils/SubmitContext.tsx'

export const UserAssistantView = ({
  userAssignedLearners,
  userFullName,
  userId,
  userRole,
  userAssigneeUsers,
  superVisor
}) => {

  const dataProvider = useDataProvider()
  const notify = useNotify()
  const refresh = useRefresh()

  const readableUserRole = mapRoleToReadableRole(userRole)

  const [learnerData, setLearnerData] = useState<Learner[]>([])
  const [isLearnerDataLoading, setIsLearnerDataLoading] = useState(true)
  const [assignedLearnerData, setAssignedLearnerData] = useState([])
  const [filteredAssignedLearnerData, setFilteredAssignedLearnerData] =
    useState()

  const { showModal, hideModal } = useModal()
  const { isFormSubmitting, setIsFormSubmitting } = useSubmitLoader()

  const [unassignParams, setUnassignParams] = useState({
    learnerId: null,
    superVisorId: null,
  })
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false)
  const [selectedLearner, setSelectedLearner] = useState("")
  const [openAccessModal, setOpenAccessModal] = useState(false)


  const fullName = (learner) => {
    return learner.first_name && learner.last_name
      ? `${learner.first_name} ${learner.last_name}`
      : learner.first_name
      ? learner.first_name
      : learner.last_name
      ? learner.last_name
      : learner.nick_name
      ? learner.nick_name
      : learner.id_code
  }


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await dataProvider.getList("Learner", {
          pagination: {page: 0, perPage: 100},
          sort: {field: "id", order: "ASC"},
          filter: {"state": "active"},
        })

        const learnerDataSubSet: Learner[] = []

        response.data.forEach((learner: any) => {
          const learnerDataItem: Learner = {
            id: learner.id,
            fullName: fullName(learner)
          }
          learnerDataSubSet.push(learnerDataItem)
        })

        setLearnerData(learnerDataSubSet)
        setIsLearnerDataLoading(false)

        const assignedLearnerData = []

        Object.keys(userAssigneeUsers).forEach((superVisorId) => {
          if (superVisorId == superVisor.id){
            const learners = userAssigneeUsers[superVisorId].learners
            // Loop through learners of the current supervisor
            Object.keys(learners).forEach((learnerId) => {
              const learnerName = learnerDataSubSet.find(
                (learner) => learner.id === learnerId
              )?.fullName
              
              const learnerAccess = {}
            
              learners[learnerId].forEach(access => {
                learnerAccess[access] = true;
              });
          
              const access = learnerAccess

              // Construct the learner object
              const learnerObj = {
                id: learnerId,
                fullName: learnerName || "",
                access,
                superVisorId,
                superVisorName: superVisor.name,
              }

              assignedLearnerData.push(learnerObj)
          })}
        })

        setAssignedLearnerData(assignedLearnerData)
        setFilteredAssignedLearnerData(assignedLearnerData)
      } catch (error) {
        // Handle error
        setIsLearnerDataLoading(false)
      }
    }

    fetchData()
  }, [ userAssigneeUsers])

  
  const handleSearchInputChange = (inputValue) => {
    const filteredLearners = assignedLearnerData.filter((learner) =>
      learner.fullName.toLowerCase().includes(inputValue.toLowerCase())
    )
    setFilteredAssignedLearnerData(filteredLearners)
  }

  const handleUnAssign = async (learnerId: string, superVisorId: string) => {
    setIsConfirmationOpen(true)
    setUnassignParams({learnerId, superVisorId})
  }


  const handleAccessEdit = async (learner) => {
    setSelectedLearner(learner)
    setOpenAccessModal(true)
  }

  const handleConfirmUnassign = async () => {
    if (isFormSubmitting) {return};

    setIsFormSubmitting(true);

    try {
      const { learnerId, superVisorId } = unassignParams
      await dataProvider
        .update("User", {
          id: userId,
          data: {
            assign_learner: {
              action: "remove_learner",
              learner_id:  learnerId,
              supervisor_id: superVisorId
            },
          },
          previousData: null,
        })
        notify('Learner is removed successfully', { type: 'success' })
    } catch (error) {
      const errorMessage =   error.message ||  "Something went wrong, Please try again after sometime"
        notify(errorMessage, {type: "error"})
    } finally {
      refresh()
      setIsFormSubmitting(false)
      setIsConfirmationOpen(false)
    }
  }

   // getting unassigned learnes
   const assignedLearnerIds = new Set(assignedLearnerData.map(learner => learner.id));
   const unAssignedLearnerData  = learnerData.filter(learner => !assignedLearnerIds.has(learner.id));

  return (
    <>
      <div className={styles.userLearnersNavigation}>
        {isLearnerDataLoading  ? (
          <LxLoadingSpinner />
        ) : (
          <>
            <div className={cn(styles.userLearnerInfo)}>
              <h3>Learners Assigned to {userFullName} </h3>
            </div>
            <div className={cn(sharedStyles.tableActions)}>
              <div
                className={cn('lxActionButton', 'lxActionButtonFilled lxActionButtonDefaultSize')}
                onClick={() => showModal(<AddNewUserLearner
                  onClose={hideModal}
                  learners={unAssignedLearnerData}
                  userId={userId}
                  userFullName={userFullName}
                  userRole={readableUserRole}
                  superVisor={superVisor}
                ></AddNewUserLearner>)}
              >
                <LxIcon icon={PlusIcon}/>
                Assign New Learner
              </div>
              <div className={'lxActionButton lxActionButtonDefaultSize'}>
                <LxIcon icon={SearchIcon}/>
                <input
                  className={'pristineInput'}
                  type="text"
                  onChange={(e) => {
                    handleSearchInputChange(e.target.value)
                  }}
                  placeholder="Search"
                />
              </div>
            </div>
          </>
        )}
      </div>
      <div className={styles.userLearnersList}>
        <div className={cn(sharedStyles.tableListHeader)}> Learner Name</div>
        <div
          className={cn(sharedStyles.tableListHeader, sharedStyles.hide2column)}
        >
          Supervisor
        </div>
        <div
          className={cn(sharedStyles.tableListHeader, sharedStyles.hide3column)}
        >
          Access Control
        </div>
        <div
          className={cn(sharedStyles.tableListHeader, sharedStyles.hide4column)}
        >
          Unassign
        </div>
        <React.Fragment>
          {isLearnerDataLoading ? (
            <LxLoadingSpinner className={sharedStyles.loader}/>
          ) : isNilOrEmpty(filteredAssignedLearnerData) ? (
            <div className={sharedStyles.notFound}>
              No learners match your current search or filters.
            </div>
          ) : (
            filteredAssignedLearnerData.map((learner: LearnerF) => (
              <React.Fragment
                key={`User-row-${learner.id}-${learner.superVisorId}`}
              >
                <div>{learner.fullName}</div>
                <div className={sharedStyles.hide2column}>
                  {learner.superVisorName}
                </div>
                <div className={sharedStyles.hide3column}>
                <LxClickAbleIcon
                  onClick={() => {
                    handleAccessEdit(learner)
                  }}
                  icon={SettingsIcon}
                />
                </div>
                <LxClickAbleIcon
                  className={sharedStyles.hide4column}
                  onClick={() => {
                    handleUnAssign(learner.id,learner.superVisorId)
                  }}
                  icon={MinusIcon}
                />
              </React.Fragment>
            ))
          )}
        </React.Fragment>
      </div>
      <ConfirmationModal
        isOpen={isConfirmationOpen}
        onClose={() => setIsConfirmationOpen(false)}
        onConfirm={handleConfirmUnassign}
        message="Are you sure you want to unassign this learner?"
        isFormSubmitting={isFormSubmitting}
      />
      <EditAccess
        isModalOpen={openAccessModal}
        onClose={() => setOpenAccessModal(false)}
        learner={selectedLearner}
        userRole={readableUserRole}
        learnerFullName={userFullName}
        userFullName={userFullName}
        userId={userId}
        superVisorId={superVisor.id}
        isFormSubmitting={isFormSubmitting}
        setIsFormSubmitting={setIsFormSubmitting}
      />
    </>
  )
}
