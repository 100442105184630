import React, { useContext, useMemo, useState } from 'react'
import { useGetList, useResourceContext } from 'react-admin'
import { LearnerB } from '@logic/contexts/Learners/LearnersBackend.type.ts'
import { LearnerF } from '@logic/contexts/Learners/LearnersFrontend.type.ts'
import { createContextFactory } from '@logic/contexts/createContextFactory.ts'
import { DispatchType, FilterValues, useDebounceFilter } from '@src/hooks/useDebounceFilter.ts'
import {
  rawLearnerToLearnerFullName,
} from '@logic/contexts/Learners/LearnerShowContext.tsx'

interface LearnersContextType {
  learnerList: LearnerF[] | null
  isLoading: boolean
  filterValues: FilterValues
  dispatch: DispatchType
}

const LearnerListContextDefault = {
  learnerList: null,
  isLoading: true,
  filterValues: {
    Q: '',
    state: 'active',
    selection: 'all',
  },
  dispatch: () => {},
}

const LearnersListContext = createContextFactory<LearnersContextType>(LearnerListContextDefault)

const mapServerLearnersListToClient = (data: LearnerB[] | undefined): LearnerF[] | null => {
  if (!data) {
    return null;
  }


  return data.map((learner: LearnerB) => {

    return {
      accountId: learner.account_id,
      assigneeUsers: learner.assignee_users ?? [],
      birthday: learner.birthday ?? '',
      firstName: learner.first_name,
      grade: learner.grade ?? '',
      id: learner.id,
      lastName: learner.last_name,
      idCode: learner.id_code,
      fileCode: learner.file_code,
      gender: learner.gender,
      nickName: learner.nick_name ?? '',
      fullName: rawLearnerToLearnerFullName(learner),
      state: learner.state
    };
  });
}

export const LearnerListProvider = ({ children, filter }) => {
  const initialFilterValues: FilterValues = { Q: '', state: 'active', selection: 'all' };
  const [filterValues, dispatch] = useDebounceFilter(initialFilterValues);

  const { data, isLoading, error } = useGetList<LearnerB>(
    'Learner',
    {
      pagination: { page: 1, perPage: 100 },
      sort: { field: 'last_update', order: 'DESC' },
      filter: filter ? filter : filterValues
    }
  )

  const sanitizedLearnerList = useMemo(() => mapServerLearnersListToClient(data), [data])

  // Handle errors
  if (error) {
    // You can handle the error here, e.g., notify the user, log the error, etc.
    console.error('Error while fetching learner data:', error);
    // You can also return an error message or a different UI in case of an error.
    return (
      <div>
        <p>An error occurred while fetching learner data. Please try again later.</p>
      </div>
    );
  }

  return (
    <LearnersListContext.Provider value={{ learnerList: sanitizedLearnerList, isLoading, filterValues, dispatch}}>
      {children}
    </LearnersListContext.Provider>
  )
}

export const useLearnersList = (): LearnersContextType => {
  return useContext(LearnersListContext)
}
