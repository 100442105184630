import { ReportDataB, ReportUnitB } from '@logic/contexts/Learners/LearnersBackend.type.ts'
import {
  ProgressGoals,
  ProgressReport, ProgressReportArea,
  ReportUnitF
} from '@logic/contexts/Learners/LearnersFrontend.type.ts'
import { isNilOrEmpty } from '@src/utils/isNilOrEmpty.ts'
import { pushUnique } from '@src/utils/pushUnique.ts'
import {
  ACTIVITY_UNIT_FILTER_KEY,
  FilterObject,
  GOALS_FILTER_KEY,
  ProgressReportFilterType,
  SKILL_AREAS_FILTER_KEY, STATUS_FILTER_KEY
} from '@logic/useFiltering.hook.ts'
import styles from '@src/pages/Learner/Show/Report/ProgressReport.module.scss'
import { mapStatusToReadableStatus } from '@src/utils/mapStatusToReadableStatus.ts'
import { ActivitiesF } from '@logic/contexts/Activities/ActivitiesFrontend.type.ts'

export const sanitizeDate = (date: Date) => {
  return (<strong>{date.getFullYear()}.{(date.getMonth() + 1).toString().padStart(2, '0')}.{date.getDate().toString().padStart(2, '0')}</strong>)
}

export function formatReportPeriod(days) {
  const currentDate = new Date()
  const startDate = new Date()
  startDate.setDate(currentDate.getDate() - days)
  return <h5 id="toPdfReport" className={styles.reportHeader}>Report period from {sanitizeDate(startDate)} to {sanitizeDate(currentDate)}</h5>
}

export const mapUnitBToF = (unitB: ReportUnitB): ReportUnitF => ({
  name: unitB.unit_title,
  firstActivityDate: unitB.first_session_date,
  firstActivityScore: unitB.first_session_score,
  activityCount: unitB.session_count,
  scoresData: unitB.scores_data,
  lastPracticed: unitB.last_practiced,
  last3Scores: unitB.last_3_scores,
  status: unitB.status,
  masteredDate: unitB.mastered_date,
  activityObjective: unitB.activity_objective
});

export const parseReportDataToProgressReport = (reportData: ReportDataB, duration: string): ProgressReport | null => {
  if (isNilOrEmpty(reportData?.report?.progress_report)) {
    return null
  }
  const periodEl = formatReportPeriod(duration)

  const progressReport: ProgressReport = {
    id: reportData.id,
    report: [],
    periodEl: periodEl,
  };

  // Create a map to aggregate data by skillAreaName
  const skillAreaMap = new Map<string, ProgressReportArea>();

  reportData.report.progress_report.forEach((reportItem) => {
    const skillAreaName = reportItem.category_name; // Convert categoryName to skillAreaName

    // Check if the skillAreaName is already in the map
    let progressReportArea = skillAreaMap.get(skillAreaName);

    if (!progressReportArea) {
      // If not, create a new ProgressReportArea
      progressReportArea = {
        name: skillAreaName,
        goals: [],
      };
      skillAreaMap.set(skillAreaName, progressReportArea);
    }

    // Create a ProgressGoals object
    const progressGoal: ProgressGoals = {
      name: reportItem.sub_category_name, // Assuming subCategoryName is the goalName
      description: reportItem.description,
      listOfUnits: reportItem.list_of_units.map(unit => ({isEnabled: true, value: unit})),
      units: reportItem.units.length ? reportItem.units.map(mapUnitBToF) : [],
    };

    // Add the ProgressGoals to the ProgressReportArea
    progressReportArea.goals.push(progressGoal);
  });

  // Convert the skillAreaMap values to an array and assign it to progressReport.report
  progressReport.report = Array.from(skillAreaMap.values());

  return progressReport;
};


export type ReportFilters = {
  skillAreaOptions: FilterObject<string>[];
  goalOptions: FilterObject<string>[];
  unitOptions: FilterObject<string>[];
  statusOptions: FilterObject<string>[];
};


export const InitialReportFilters: ReportFilters = {
  skillAreaOptions: [],
  goalOptions: [],
  unitOptions: [],
  statusOptions: []
}

export const mapProgressReportToFilterOptions = (data: ProgressReport | null ): ReportFilters => {
  const reportFilters: ReportFilters = {
    skillAreaOptions: [...InitialReportFilters.skillAreaOptions],
    goalOptions: [...InitialReportFilters.goalOptions],
    unitOptions: [...InitialReportFilters.unitOptions],
    statusOptions: [...InitialReportFilters.statusOptions]
  };

  if (isNilOrEmpty(data?.report)) {
    return InitialReportFilters;
  }

  data!.report.forEach((skillArea: ProgressReportArea) => {
    pushUnique(reportFilters.skillAreaOptions, {isEnabled: true, value: skillArea.name, label: skillArea.name}, 'value')
    skillArea.goals.forEach((goal: ProgressGoals) => {
      pushUnique(reportFilters.goalOptions,
        {
          isEnabled: true,
          label: goal.name,
          value: goal.name,
          id: `${skillArea.name}-${goal.name}`
        }, 'id'
      )
      goal.units.forEach((unit: ReportUnitF) => {
        pushUnique(reportFilters.unitOptions,
          {
            isEnabled: true,
            value: unit.name,
            label: unit.name,
            id: `${skillArea.name}-${goal.name}-${unit.name}`
          }, 'id'
        )
        pushUnique(reportFilters.statusOptions, {isEnabled: true, value: unit.status, label: mapStatusToReadableStatus(unit.status)}, 'value')
      })
    })
  });

  return reportFilters;
}

export const filterProgressReport = (
  report: ProgressReport | null,
  filters: ProgressReportFilterType
): ProgressReport | null => {
  if (!report || !report.report || report.report.length === 0) {
    return null;
  }

  const isFilterActive = (key: string) => filters[key].data && filters[key].data.length > 0;

  const filteredReport = report.report.reduce((filteredAreas, area) => {
    let includeArea = true;
    let filteredGoals = area.goals;

    // Filter by skill area
    if (isFilterActive(SKILL_AREAS_FILTER_KEY)) {
      const skillAreaFilter = filters[SKILL_AREAS_FILTER_KEY].data.find(option => option.value === area.name);
      includeArea = !!skillAreaFilter && skillAreaFilter.isEnabled;
    }

    // Filter by goal
    if (isFilterActive(GOALS_FILTER_KEY)) {
      filteredGoals = filteredGoals.filter(goal => {
        const goalFilter = filters[GOALS_FILTER_KEY].data.find(option => option.value === goal.name);
        return !!goalFilter && goalFilter.isEnabled;
      });
    }

    // After filtering the units for each goal
    // Filter goals' units
    filteredGoals = filteredGoals.map(goal => {
      const filteredUnits = goal.units.filter(unit => {
        let includeUnit = true;

        // Filter by unit
        if (isFilterActive(ACTIVITY_UNIT_FILTER_KEY)) {
          const unitFilter = filters[ACTIVITY_UNIT_FILTER_KEY].data.find(option => option.value === unit.name);
          includeUnit = !!unitFilter && unitFilter.isEnabled;
        }

        // Filter by status
        if (isFilterActive(STATUS_FILTER_KEY)) {
          const statusFilter = filters[STATUS_FILTER_KEY].data.find(option => option.value === unit.status);
          includeUnit = includeUnit && (!!statusFilter && statusFilter.isEnabled);
        }

        return includeUnit;
      });

      // Update listOfUnits based on filteredUnits
      const updatedListOfUnits = goal.listOfUnits.map(listUnit => {
        const isUnitEnabled = filteredUnits.some(fUnit => fUnit.name === listUnit.value);
        return { ...listUnit, isEnabled: isUnitEnabled };
      });

      return { ...goal, units: filteredUnits, listOfUnits: updatedListOfUnits };
    }).filter(goal => goal.units.length > 0); // Only include goals with units;

    if (includeArea && filteredGoals.length > 0) {
      filteredAreas.push({ ...area, goals: filteredGoals });
    }

    return filteredAreas;
  }, []);

  return {
    id: report.id,
    report: filteredReport,
    periodEl: report.periodEl
  };
}


export const createFakeReport = (data: ActivitiesF | null): ProgressReport | null => {
  if (!data) {
    return null;
  }
  const report: ProgressReport = {
    id: 'report_' + new Date().getTime(), // Unique ID based on timestamp
    report: [],
    periodEl: <span>{new Date().toLocaleDateString()}</span>, // Example period element
  };

  data.skillAreas.forEach(skillArea => {
    const reportArea: ProgressReportArea = {
      name: skillArea.name,
      goals: []
    };

    skillArea.goals.forEach(goal => {
      const reportGoal: ProgressGoals = {
        name: goal.name,
        description: goal.description,
        listOfUnits: [], // Assuming FilterObject can be empty or generated
        units: []
      };

      goal.units.forEach(unit => {
        const unitReport: ReportUnitF = {
          name: unit.title,
          firstActivityDate: '2024-01-01', // Example date
          firstActivityScore: '80', // Example score
          activityCount: 5, // Example count
          scoresData: { '2024-01-01': '80', '2024-01-02': '85' }, // Example scores
          lastPracticed: '2024-01-05', // Example last practiced date
          last3Scores: ['80', '85', '90'], // Example last 3 scores
          status: 'In Progress', // Example status
          masteredDate: '', // Example mastered date
          activityObjective: unit.description // Example objective
        };

        reportGoal.units.push(unitReport);
      });

      reportArea.goals.push(reportGoal);
    });

    report.report.push(reportArea);
  });

  return report;
};

export function fakeDummyFiltersParser(data: ActivitiesF | null): ReportFilters {
  const skillAreaOptions: FilterObject<string>[] = [];
  const goalOptions: FilterObject<string>[] = [];
  const unitOptions: FilterObject<string>[] = [];
  const statusOptions: FilterObject<string>[] = [];

  if (data) {
    for (const skillArea of data.skillAreas) {
      skillAreaOptions.push({
        value: skillArea.name,
        isEnabled: true,
        label: skillArea.name,
      });
      // Process sub-categories
      for (const goal of skillArea.goals) {
        goalOptions.push({
          value: goal.name,
          isEnabled: true,
          label: goal.name,
        });
        // Process units
        for (const unit of goal.units) {
          unitOptions.push({
            value: unit.title,
            isEnabled: true,
            label: unit.title,
          });
        }
      }
    }
  }

  return {
    skillAreaOptions,
    goalOptions,
    unitOptions,
    statusOptions,
  };
}

