import React, { useEffect, useState } from 'react'
import { useNotify, useUpdate } from 'react-admin'

import styles from './AnalyticsList.module.scss'
import { TeamMemberIcon } from '@icons/index.ts'
import { LxLoadingSpinner } from '@components/loader/loadingSpinner.tsx'
import { Dialog } from '@mui/material'
import { LxClickAbleIcon } from '@components/icon/clickAbleIcon.tsx'
import { cn } from '@src/utils/cn.ts'

export type UserPropsB = {
  id: string
  first_name: string
  last_name: string
  primary_email: string
}

export type UserPropsF = {
  id: string
  firstName: string
  lastName: string
  primaryEmail: string
}

const AdminInfo = ({ accountId, accountName }) => {
  const [showDialog, setShowDialog] = useState(false)
  const [isDataLoading, setIsDataLoading] = useState(false)
  const [userData, setUserData] = useState<[UserPropsF] | null>(null)

  const notify = useNotify()
  const [update] = useUpdate('Report')

  const sanitizeData = (data: any) => {
    if (!data || !data.report) {
      return null
    }

    return data.report.map((user: UserPropsB) => ({
      id: user.id,
      firstName: user.first_name,
      lastName: user.last_name,
      primaryEmail: user.primary_email,
    }))
  }

  const fetchData = async () => {
    setIsDataLoading(true)
    try {
      await update(
        'Report',
        {
          id: accountId,
          data: {
            filter: {},
            type: 'admin_info',
          },
          previousData: null,
        },
        {
          onSuccess: (data) => {
            const sanitizedData = sanitizeData(data)
            setUserData(sanitizedData)
            setIsDataLoading(false)
          },
          onFailure: (error) => {
            setUserData(null)
            const errorMessage = error.message || 'Something went wrong'
            notify(errorMessage, { type: 'warning' })
            setIsDataLoading(false)
          },
        }
      )
    } catch (error) {
      setIsDataLoading(false)
    }
  }

  useEffect(() => {
    if (showDialog) {
      fetchData()
    }
  }, [showDialog])

  return (
    <>
      <LxClickAbleIcon
        onClick={() => {
          setShowDialog(true)
        }}
        icon={TeamMemberIcon}
      ></LxClickAbleIcon>
      <Dialog
        open={showDialog}
        onClose={() => {
          setShowDialog(false)
        }}
        maxWidth='md'
        fullWidth
      >
        <div className={styles.dialogHeader}>
          <h3>Account Admins of {accountName}</h3>
        </div>
        <div className={styles.userDialogContent}>
          <div className={styles.headerRow}>
            <strong>First Name:</strong>
            <strong>Last Name:</strong>
            <strong>Email:</strong>
          </div>

          {isDataLoading ? (
            <LxLoadingSpinner />
          ) : (
            userData?.map((user, index) => (
              <React.Fragment key={`StudentsGoals-${index}`}>
                <div className={styles.infoRow}>
                  <span>{user?.firstName}</span>
                  <span>{user?.lastName}</span>
                  <span>{user?.primaryEmail}</span>
                </div>
              </React.Fragment>
            ))
          )}
        </div>
        <div className={styles.dialogActions}>
          <div
            className={cn('lxActionButton lxActionButtonDefaultSize')}
            onClick={() => {
              setShowDialog(false)
            }}
          >
            <span>Close</span>
          </div>
        </div>
      </Dialog>
    </>
  )
}

export default AdminInfo
