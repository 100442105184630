// import { formatDate } from '@logic/contexts/Learners/ActivityReport/formatDate.util.ts'
import { formatDate } from '@src/utils/dateFormatter';
import { ActivityAssessmentF } from '@logic/contexts/Learners/LearnersFrontend.type.ts'
import { isNilOrEmpty } from '@src/utils/isNilOrEmpty.ts'


export type GroupedQuestionData = {
  labels: string[];
  correctData: number[];
  wrongData: number[];
  allData: number[];
  allDataByTime: number[];
  allDataScore: string[];
} | null;



const groupByStartDateAndMinutes = (activity: ActivityAssessmentF) => {
  let minutesPlayed = 0;

  const startTime = new Date(activity.start).getTime();
  let endTime;

  if (activity.end) {
    endTime = new Date(activity.end).getTime();
  } else if (activity.results.length > 0) {
    // If 'end' is missing, use the last 'answer_time' as 'end' time
    const lastAnswerTime = new Date(activity.results[activity.results.length - 1].answerTime);
    endTime = lastAnswerTime.getTime();
  } else {
    // So minutesPlayed will be zero and not shown in the graph
    endTime = startTime;
  }

  minutesPlayed = (endTime - startTime) / (1000 * 60); // Calculate minutes played

  return minutesPlayed;
};

export const groupQuestionData = (reportData: ActivityAssessmentF[] | null): GroupedQuestionData => {
  const groupedData: Record<string, { correct: number, wrong: number, all: number, byTime: number, byScore: string }> = {};

  if (isNilOrEmpty(reportData)) {
    return null;
  }

  reportData.forEach((activity: ActivityAssessmentF) => {
    const startDate = formatDate(activity.start, true, false);
    const minutesData = groupByStartDateAndMinutes(activity); // Aggregate data by minutes for the current activity

    if (activity.results) {
      // Group data for 'correct' and 'wrong' answers based on 'startDate'
      const correctData = activity.results.filter(result => result.answer === 'correct');
      const wrongData = activity.results.filter(result => result.answer === 'wrong');

      if (!groupedData[startDate]) {
        groupedData[startDate] = { correct: 0, wrong: 0, all: 0, byTime: 0, byScore: '0' };
      }

      // Count 'correct' and 'wrong' answers for each date
      groupedData[startDate].correct += correctData.length;
      groupedData[startDate].wrong += wrongData.length;

      // Count all answers for each date
      groupedData[startDate].all += activity.results.length;

      // Combine minutes data with existing 'byTime' data
      groupedData[startDate].byTime += minutesData;

      // Set the score for 'byScore'
      groupedData[startDate].byScore = activity.score;
      //groupedData[startDate].byScore  = ((groupedData[startDate].correct / groupedData[startDate].all) * 100).toFixed(2).toString();
    }
  });

  // Convert grouped data into labels and data for the chart
  const labels = Object.keys(groupedData);
  const correctData = labels.map(key => groupedData[key].correct);
  const wrongData = labels.map(key => groupedData[key].wrong);
  const allData = labels.map(key => groupedData[key].all);
  const allDataByTime = labels.map(key => groupedData[key].byTime);
  const allDataScore = labels.map(key => groupedData[key].byScore);

  return { labels, correctData, wrongData, allData, allDataByTime, allDataScore };
};
