import React from 'react'
import styles from '../MultiPage.module.scss'
import { LearnerF } from '@logic/contexts/Learners/LearnersFrontend.type.ts'
import { Link } from 'react-router-dom'

import { useUserContext } from '@logic/contexts/AppStore/UserContext.tsx'
import { usePolicyContext } from '@logic/contexts/AppStore/AbacContext.tsx'

import { cn } from '@src/utils/cn.ts'
import modalStyles from '@components/modal/modal.module.scss'
import { useModal } from '@logic/contexts/Modal/ModalContext.tsx'
import { formatDate } from '@src/utils/dateFormatter'


interface LearnerInfoProps {
  learner: LearnerF;
}

const LearnerInfo: React.FC<LearnerInfoProps> = ({ learner }) => {
  const { hideModal } = useModal()

  const { currentUser } = useUserContext() as any
  const { canPerformAction } = usePolicyContext()

  const canEditLearner = canPerformAction(
    currentUser,
    { type: 'Learner' },
    'edit'
  )

  return (
    <>
      <div className={modalStyles.dialogHeader}>
        <h3>{learner.fullName}</h3>
      </div>
      <div className={modalStyles.dialogContent}>
        <div className={styles.infoRow}>
          <strong>First Name:</strong>
          <span>{learner.firstName}</span>
        </div>
        <div className={styles.infoRow}>
          <strong>Last Name:</strong>
          <span>{learner.lastName}</span>
        </div>
        <div className={styles.infoRow}>
          <strong>Date of Birth:</strong>
          <span>{formatDate(learner.birthday, false, false)}</span>
        </div>
        <div className={styles.infoRow}>
          <strong>Gender:</strong>
          <span>{learner.gender}</span>
        </div>
        <div className={styles.infoRow}>
          <strong>Grade:</strong>
          <span>{learner.grade}</span>
        </div>
        <div className={styles.infoRow}>
          <strong>Identification Code/Number:</strong>
          <span>{learner.idCode}</span>
        </div>
        <div className={styles.infoRow}>
          <strong>IEP/EHCP Code:</strong>
          <span>{learner.fileCode}</span>
        </div>
      </div>
      <div className={modalStyles.dialogActions}>
        <div
          className={cn('lxActionButton lxActionButtonDefaultSize')}
          onClick={() => hideModal()}
        >
          <span>Close</span>
        </div>
        {
          canEditLearner &&
            <Link to={`/Learner/${learner.id}/show/edit`}
                  onClick={() => hideModal()}
                  className={cn('lxActionButton lxActionButtonFilled lxActionButtonDefaultSize')}>
                <span>Edit Learner Info</span>
            </Link>
        }
      </div>
    </>
  )
}

export default LearnerInfo
