import React, { FC, useEffect,  useState } from 'react'

import {
  useActivitiesList
} from '@logic/contexts/Activities/ActivitiesListContext.tsx'
import styles from './ActivitiesList.module.scss'
import {
  ActivitiesF, GoalF, SkillAreaF,
} from '@logic/contexts/Activities/ActivitiesFrontend.type.ts'
import { isNilOrEmpty } from '@src/utils/isNilOrEmpty.ts'
import { useSearchParams } from 'react-router-dom'
import { deserializeQuery, serializeQuery } from '@src/utils/querySerialization.ts'
import { FilterObject, GOALS_FILTER_KEY, SKILL_AREAS_FILTER_KEY } from '@logic/useFiltering.hook.ts'
import { LxAutocomplete } from '@components/autocomplete/autocomplete.tsx'
import { LxIcon } from '@components/icon/Icon.tsx'
import { ActivitiesOutlineIcon } from '@icons/index.ts'
import { cn } from '@src/utils/cn.ts'
import { FilterIcon } from '@icons/utils'

type ActivitiesFilters = {
  skillAreas?: string;
  goals?: string;
}

type Props = {
  handleExpandAll: () => void;
  expandAll: boolean;
}

export const ActivitiesFilterBar: FC<Props> = ({ handleExpandAll, expandAll }) => {
  const {
    allData,
    resetFilters,
    filtersOptions,
    filters
  } = useActivitiesList()

  const [ skillAreaOptions, setSkillAreaOptions ] = useState<FilterObject<string>[]>(filtersOptions.skillAreaFilters)
  const [ goalOptions, setGoalOptions ] = useState<FilterObject<string>[]>(filtersOptions.goalFilters)

  const [ searchParams, setSearchParams ] = useSearchParams()
  const [ firstLoad, setFirstLoad ] = useState(false)

  const setDefaultFilters = () => {
    resetFilters()
    setSkillAreaOptions(filtersOptions.skillAreaFilters)
    setGoalOptions(filtersOptions.goalFilters)
  }


  useEffect(() => {
    setSkillAreaOptions(filtersOptions.skillAreaFilters)
    setGoalOptions(filtersOptions.goalFilters)
    return () => resetFilters()
  }, [ filtersOptions ])


  useEffect(() => {
    if (isNilOrEmpty(filtersOptions.skillAreaFilters) || isNilOrEmpty(filtersOptions.goalFilters) || firstLoad) {
      return
    }

    const skillAreasParam = searchParams.get('skillAreas')
    const goalsParam = searchParams.get('goals')

    const urlSkillAreas =
      deserializeQuery(skillAreasParam)
        .map(name => filtersOptions.skillAreaFilters.find(skillAreaFilter => skillAreaFilter.value === name))
        .filter(Boolean)

    const urlGoals = deserializeQuery(goalsParam)
      .map(name => filtersOptions.goalFilters.find(goalFilter => goalFilter.value === name))
      .filter(Boolean)

    filters[SKILL_AREAS_FILTER_KEY].set(urlSkillAreas)
    filters[GOALS_FILTER_KEY].set(urlGoals)

    if (urlSkillAreas.length > 0) {
      handleSkillAreaChange(urlSkillAreas)
    }

    if (urlGoals.length > 0) {
      handleGoalChange(urlGoals)
    }

    setFirstLoad(true)

  }, [ filtersOptions ])

  useEffect(() => {
    if (!firstLoad) {
      return
    }

    let skillAreasParam = serializeQuery(filters[SKILL_AREAS_FILTER_KEY].data)
    let goalsParam = serializeQuery(filters[GOALS_FILTER_KEY].data)

    const newSearchParams: ActivitiesFilters = {}

    if (skillAreasParam) {
      newSearchParams.skillAreas = skillAreasParam
    }

    if (goalsParam) {
      newSearchParams.goals = goalsParam
    }
    setSearchParams(newSearchParams)

  }, [ filters[SKILL_AREAS_FILTER_KEY].data, filters[GOALS_FILTER_KEY].data, firstLoad ])


  const getAllMatchingSkillAreas = (allData: ActivitiesF | null, goals: FilterObject<string>[]): SkillAreaF[] => {
    if (isNilOrEmpty(allData)) {
      return []
    }
    return allData!.skillAreas.filter(skillArea =>
      skillArea.goals.some(goal =>
        goals.some(selectedGoal => selectedGoal.value === goal.name)
      )
    )
  }

  const getAllMatchingGoals = (allData: ActivitiesF | null, categories: FilterObject<string>[]): GoalF[] => {
    if (isNilOrEmpty(allData)) {
      return []
    }

    return categories.reduce((acc, selectedCategory) => {
      const matchingCategory = allData!.skillAreas.filter(category => category.name === selectedCategory.value)
      const subCategories: GoalF[] = matchingCategory.flatMap(category => category.goals || [])
      return acc.concat(subCategories) // Accumulate subCategories for each selectedCategory
    }, [])
  }


  const enableFilterOption
    = (prevOptions: FilterObject<string>[]) => (matching: SkillAreaF[] | GoalF[]) =>
    prevOptions.map(category => ( {
      ...category,
      isEnabled: matching.some(matchingCategory => matchingCategory.name === category.value),
    } ))
      .sort((a, b) => ( b.isEnabled ? 1 : 0 ) - ( a.isEnabled ? 1 : 0 ))


  const handleGoalChange = (selectedSubCategories: FilterObject<string>[]) => {
    // Get all categories that contain any of the selected subcategories
    const matchingCategories = getAllMatchingSkillAreas(allData, selectedSubCategories)
    setSkillAreaOptions(prevCategories => enableFilterOption(prevCategories)(matchingCategories))
  }


  const handleSkillAreaChange = (selectedCategories: FilterObject<string>[]) => {
    const matchingSubCategories = getAllMatchingGoals(allData, selectedCategories)
    setGoalOptions(prevSubCategories => enableFilterOption(prevSubCategories)(matchingSubCategories))
  }

  return (
    <>
      <div className={styles.activitiesListHeadingRow}>
        <div className={styles.activitiesListHeading}>
          <LxIcon sxStyles={{ height: '80px', width: '80px' }}
                  icon={ActivitiesOutlineIcon}/>
          <h3>List of QTrobot Activities</h3>
        </div>
        <div className={styles.activitiesActions}>
          <div
            className={cn('lxActionButton lxActionButtonDefaultSize', { 'disabled': filters[GOALS_FILTER_KEY].data.length === 0 && filters[SKILL_AREAS_FILTER_KEY].data.length === 0 })}
            onClick={setDefaultFilters}><span>Reset filters</span>
          </div>
          <div className={cn('lxActionButton', 'lxActionButtonFilled lxActionButtonDefaultSize')}
               onClick={handleExpandAll}>
            <span>{expandAll ? 'Collapse All' : 'Expand All'}</span>
          </div>
        </div>
      </div>
      <div className={styles.activitiesFilterBar}>
        <div className={cn('lxActionButton', 'lxActionButtonFilled lxActionButtonDefaultSize', styles.filteringModal)}
             onClick={() => {}}>
          <LxIcon
            className={styles.filteringModal}
            icon={FilterIcon}
            customViewBox={'0 0 256 256'}
            sxStyles={{ height: '26px', width: '32px' }}
          ></LxIcon>
          Filter Activities
        </div>
        <LxAutocomplete
          multiple
          placeholder={'Filter Activities by Skill Area'}
          options={skillAreaOptions}
          value={filters[SKILL_AREAS_FILTER_KEY].data}
          onChange={(values: FilterObject<string>[]) => {
            filters[SKILL_AREAS_FILTER_KEY].set(values)
            if (values.length > 0) {
              handleSkillAreaChange(values)
            } else {
              handleSkillAreaChange(filtersOptions.skillAreaFilters)
            }
          }}
        ></LxAutocomplete>
        <LxAutocomplete
          multiple
          placeholder={'Filter Activities by Goal'}
          value={filters[GOALS_FILTER_KEY].data}
          options={goalOptions}
          onChange={(values: FilterObject<string>[]) => {
            filters[GOALS_FILTER_KEY].set(values)
            if (values.length > 0) {
              handleGoalChange(values)
            } else {
              setSkillAreaOptions(filtersOptions.skillAreaFilters)
              handleSkillAreaChange(filters[SKILL_AREAS_FILTER_KEY].data.length ? filters[SKILL_AREAS_FILTER_KEY].data : filtersOptions.skillAreaFilters)
            }
          }}
        ></LxAutocomplete>
      </div>
    </>
  )
}