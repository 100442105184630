import Popover from '@mui/material/Popover';
import { FC, ReactNode, useState } from 'react'
import styles from './tooltip.module.scss'
import { cn } from '@src/utils/cn.ts'


type Props = {
  tooltipText: string | null
  children: ReactNode
  classNames?: string
}
export const LxTooltip:FC <Props> = ({children, tooltipText, classNames}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null | undefined>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  if (!tooltipText) {
    return children
  }

  return (
    <div className={cn(styles.anchorContainer, classNames)}>
      <div
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        onClick={handlePopoverClose}
      >
        {children}
      </div>
      <Popover
        id="mouse-over-popover"
        disableScrollLock={ true }
        sx={{
          pointerEvents: 'none',
          fontFamily: 'Roboto',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <div className={styles.tooltipContainer}>{tooltipText}</div>
      </Popover>
    </div>
  );
}