import { useEffect, useRef } from 'react';

const isArray = a => a instanceof Array;
const isNull = a => a == null;
const cursor = a => {
  if (isNull(a)) return 'default';
  if (!isArray(a)) return 'pointer';
  if (isArray(a) && a.length > 0) return 'pointer';
  return 'default';
};

export const useCustomCursor = () => {
  const ref = useRef(null);

  const onHover = (e, item) => {
    const cursorType = cursor(item);
    if (ref.current && ref.current.canvas) {
      ref.current.canvas.style.cursor = cursorType;
    }
  };

  useEffect(() => {
    return () => {
      if (ref.current && ref.current.canvas) {
        ref.current.canvas.style.cursor = 'default';
      }
    };
  }, []);

  return { onHover, ref };
};
