import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import '@src/styles/global.scss'
import './styles/index.css'
import { ActivitiesIcon } from '@icons/index.ts'

import { BrowserRouter } from 'react-router-dom'

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <div className={'activitiesIconDirtyFIx'}>
      <ActivitiesIcon/>
    </div>
    <BrowserRouter>
        <App />
    </BrowserRouter>
  </React.StrictMode>
)