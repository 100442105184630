export const parsePriority = (inputString: string) => {
  const lowerCaseInput = inputString.toLowerCase()

  switch (lowerCaseInput) {
    case 'low':
      return 'Low Priority'
    case 'medium':
      return 'Medium Priority'
    case 'high':
      return 'High Priority'
    default:
      return 'Invalid Priority'
  }
}