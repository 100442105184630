import React, { useContext, useMemo, useState } from 'react'
import { GetOneParams, useGetOne, useRedirect, useResourceContext } from 'react-admin'
import { createContextFactory } from '@logic/contexts/createContextFactory.ts'
import {
  ActivityItemB,
} from '@logic/contexts/Activities/ActivitiesBackend.type.ts'
import {
  ActivityItemF,
} from '@logic/contexts/Activities/ActivitiesFrontend.type.ts'
import { useParams } from 'react-router-dom'
import { LearnerB } from '@logic/contexts/Learners/LearnersBackend.type.ts'
// import { mockedSingleActivity } from '@logic/contexts/Activities/mockedDataShow.ts'
import { isNilOrEmpty } from '@src/utils/isNilOrEmpty.ts'

type ActivitiesShowType = {
  activity: ActivityItemF | null
  isLoading: boolean
}

const ActivitiesShowTypeDefault = {
  activity: null,
  isLoading: true
}


const ActivitiesShowContext = createContextFactory<ActivitiesShowType>(ActivitiesShowTypeDefault)


function mapToActivityItemBToF(itemB: ActivityItemB | null | undefined): ActivityItemF | null {
  if (isNilOrEmpty(itemB)) {
    return null
  }

  return {
    id: itemB?.id,
    name: itemB?.name,
    extras: {
      title: itemB?.extras?.unit_title,
      appNotes: itemB?.extras.app_notes.map(note => ({
        title: note.title,
        desc: note.desc
      }))
    }
  };
}

export const ActivitiesShowProvider = ({ children }) => {
  const resourceContext = useResourceContext()
  const { id } = useParams() // this component is rendered in the /books/:id path
  const redirect = useRedirect()
  const params: GetOneParams<LearnerB> = { id: id ?? '' } // Provide a default value for undefined id
  const { data, isLoading } = useGetOne<any>(
    'Item',
    params,
    { onError: () => { redirect(`/${resourceContext}`) } }
  )

  const sanitizedData = mapToActivityItemBToF(data)

  return (
    <ActivitiesShowContext.Provider value={{ activity: sanitizedData, isLoading }}>
      {children}
    </ActivitiesShowContext.Provider>
  )
}

export const useActivitiesShow = (): ActivitiesShowType => {
  return useContext(ActivitiesShowContext)
}
