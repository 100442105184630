import { type GetOneParams, useGetList, useGetOne, useRecordContext, useRedirect, useResourceContext } from 'react-admin'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { type UserB } from '@logic/contexts/Users/UsersBackend.type.ts'
import { useParams } from 'react-router-dom'
import { type UserF } from '@logic/contexts/Users/UsersFrontend.type.ts'
import { createContextFactory } from '@logic/contexts/createContextFactory.ts'

interface UserShowContextType {
  user: UserF | null
  onEditProfile: () => any
  isLoading: boolean
}

const UserShowContextDefault = {
  user: null,
  onEditProfile: () => {},
  isLoading: true
}

const UserShowContext = createContextFactory<UserShowContextType>(UserShowContextDefault)

const mapUserShowServerToClient = (user: UserB | undefined): UserF | null => {
  if (!user) {
    return null
  }
  
  return {
    id: user.id,
    firstName: user.first_name,
    fullName: `${user.first_name} ${user.last_name}`,
    lastName: user.last_name,
    emails: user.emails ?? [],
    primaryEmail: user.primary_email ?? [],
    b2cIds: user.b2c_ids ?? [],
    creationInfo: user.creation_info ?? null,
    userRole: user.user_role ?? null,
    state: user.state ?? null,
    jobTitle: user.job_title ?? null,
    assignedLearners: user.assigned_learners ?? [],
    assigneeUsers: user.assignee_users ?? [],
    assignedGoals: user.assigned_goals ?? []
  }
}

export const UserShowProvider = ({ children }) => {
  const resourceContext = useResourceContext() || "User"
  const { id } = useParams() // this component is rendered in the /books/:id path
  const redirect = useRedirect()
  const params: GetOneParams<UserB> = { id: id ?? '' } // Provide a default value for undefined id
  const { data, isLoading } = useGetOne<UserB>(
    resourceContext,
    params,
    { onError: () => { redirect(`/${resourceContext}`) } }
  )

  const sanitizedUser = useMemo(() => data ? mapUserShowServerToClient(data) : null, [data])
  const onEditProfile = () => { redirect(`/${resourceContext}/${id}`) }

  return (
    <UserShowContext.Provider value={{ user: sanitizedUser, isLoading, onEditProfile }}>
      {children}
    </UserShowContext.Provider>
  )
}

export const useUserShow = (): UserShowContextType => {
  return useContext(UserShowContext)
}
