import React, { FC } from 'react'
import {
  useDataProvider,
  useNotify,
  useRefresh,
  usePrevious,
} from 'react-admin'
import modalStyles from '@components/modal/modal.module.scss'
import { cn } from '@src/utils/cn.ts'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { LxSelect } from '@components/select/select.tsx'
import { GOALF } from '@logic/contexts/Learners/LearnersFrontend.type.ts'
import { useFormNotify } from '@components/formNotify/useFormNotify.tsx'
import { useModal } from '@logic/contexts/Modal/ModalContext.tsx'
import { useSubmitLoader } from '@src/logic/contexts/utils/SubmitContext.tsx'
import styles from './GoalActions.module.scss'
import { LxLoadingSpinner } from '@components/loader/loadingSpinner.tsx'

export const stateFormValidation = yupResolver(
  yup
    .object()
    .shape({
      state: yup.string().required('State is required'),
    })
    .required()
)

const LearnerGoalEditStatus: FC<{ goal: GOALF }> = ({ goal }) => {
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const refresh = useRefresh()
  const { hideModal } = useModal()
  const { isFormSubmitting, setIsFormSubmitting } = useSubmitLoader()

  const {
    control,
    handleSubmit,
    setError,
    reset,
    formState: { errors, submitCount, isValid },
  } = useForm({
    defaultValues: { state: goal.state },
    resolver: stateFormValidation,
  })

  const onClose = () => {
    hideModal()
    reset()
  }

  const prevState = usePrevious(goal.state)

  useFormNotify(errors, submitCount, isValid)

  const onSubmit = async (formData: {
    state: 'active' | 'archived' | 'on_hold'
  }) => {
    if (isFormSubmitting) {
      return
    }

    if (prevState === formData.state) {
      setError('state', {
        message: 'Please choose a state different from the current status.',
      })
      return
    }

    setIsFormSubmitting(true)

    try {
      await dataProvider.update('Goal', {
        id: goal.id,
        data: {
          state: formData.state,
          action: 'update_goal',
        },
        previousData: null,
      })
      notify('Goal status updated', { type: 'success' })
    } catch (error) {
      const errorMessage = error.message || 'Something went wrong'
      notify(errorMessage, { type: 'warning' })
    } finally {
      refresh()
      setIsFormSubmitting(false)
      onClose()
    }
  }

  return (
    <div className={modalStyles.modalMd}>
      {isFormSubmitting && (
        <div className={styles.LoadingOverlay}>
          <LxLoadingSpinner />
        </div>
      )}
      
      <div className={modalStyles.dialogHeader}>
        <h3>Manage Status of {goal.name}</h3>
      </div>
      <div className={modalStyles.dialogContent}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name='state'
            control={control}
            render={({ field, fieldState }) => {
              const options = [
                ...(prevState !== 'on_hold'
                  ? [{ value: 'on_hold', label: 'On Hold' }]
                  : []),
                ...(prevState !== 'active'
                  ? [{ value: 'active', label: 'Active' }]
                  : []),
                ...(prevState !== 'permanent_deleted'
                  ? [{ value: 'permanent_deleted', label: 'Delete' }]
                  : []),
              ]

              return (
                <LxSelect
                  value={field.value}
                  hasError={!!fieldState.error}
                  placeholder={'Select Goal State'}
                  onChange={field.onChange}
                  options={options}
                />
              )
            }}
          />
        </form>
      </div>
      <div className={modalStyles.dialogActions}>
        <div
          className={cn('lxActionButton lxActionButtonDefaultSize')}
          onClick={onClose}
        >
          <span>Close</span>
        </div>
        <div
          className={cn(
            'lxActionButton lxActionButtonFilled lxActionButtonDefaultSize'
          )}
          onClick={handleSubmit(onSubmit)}
        >
          <span>Confirm</span>
        </div>
      </div>
    </div>
  )
}

export default LearnerGoalEditStatus
