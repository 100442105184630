import React, { ReactNode, useEffect, useRef, useState } from 'react'
import styles from './autocompleteTmp.module.scss';
import { cn } from '@src/utils/cn.ts'
import { LxIcon } from '@components/icon/Icon.tsx'
import { ArrowDownIcon, ArrowUpIcon } from '@icons/utils'


interface Option {
  value: string
  label: string
}

interface Props {
  value: string;
  onChange: (value: string) => void;
  options: Option[];
  className?: string;
}


export const LxAutocompleteTmp: React.FC<Props> = ({onChange, options, value, className}) => {
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef<HTMLDivElement>(null);
  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleOptionClick = (optionValue: string) => {
    onChange(optionValue);
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [selectRef]);

  const selected = options.find(option => option.value === value);

  return (
      <div className={cn(styles.autocompleteContainer)} ref={selectRef}>
        <div onClick={toggleDropdown}>
          <h3>{selected.label}</h3>
          <LxIcon icon={isOpen ? ArrowUpIcon : ArrowDownIcon}
                  customViewBox={'0 0 960 560'}></LxIcon>
        </div>
        {isOpen && (
          <div className={styles.selectItems}>
            {options.map(option => (
              <div key={option.value}
                   className={cn(styles.selectItem)}
                   onClick={() => handleOptionClick(option.value)}>
                {option.label}
              </div>
            ))}
          </div>
        )}
      </div>
  )
};
