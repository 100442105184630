import React from 'react'
import { useState } from 'react'
import styles from '../MultiPage.module.scss'
import { Link } from 'react-router-dom'
import { useUserContext } from '@logic/contexts/AppStore/UserContext.tsx'
import { usePolicyContext } from '@logic/contexts/AppStore/AbacContext.tsx'
import { UserF } from '@src/logic/contexts/Users/UsersFrontend.type'
import { mapRoleToReadableRole } from '@src/utils/mapRoleToReadableRole.ts'
import { cn } from '@src/utils/cn.ts'
import modalStyles from '@components/modal/modal.module.scss'
import { useModal } from '@logic/contexts/Modal/ModalContext.tsx'


interface UserInfoProps {
  user: UserF;
}

const UserInfo: React.FC<UserInfoProps> = ({ user }) => {
  const { hideModal } = useModal()

  const { currentUser } = useUserContext() as any
  const { canPerformAction } = usePolicyContext()

  const canEditUser = canPerformAction(currentUser, { type: 'User' }, 'edit')

  return (
    <>
      <div className={modalStyles.dialogHeader}>
        <h3>{user.fullName}</h3>
      </div>
      <div className={modalStyles.dialogContent}>
        <div className={styles.infoRow}>
          <strong>First Name:</strong>
          <span>{user.firstName}</span>
        </div>
        <div className={styles.infoRow}>
          <strong>Last Name:</strong>
          <span>{user.lastName}</span>
        </div>
        <div className={styles.infoRow}>
          <strong>Email:</strong>
          <span>{user.primaryEmail}</span>
        </div>
        <div className={styles.infoRow}>
          <strong>Title:</strong>
          <span>{user.jobTitle}</span>
        </div>
        <div className={styles.infoRow}>
          <strong>Role:</strong>
          <span>{mapRoleToReadableRole(user.userRole)}</span>
        </div>
      </div>
      <div className={cn(modalStyles.dialogActions, modalStyles.actionsFullWidth)}>
        <div
          className={cn('lxActionButton lxActionButtonDefaultSize')}
          onClick={() => hideModal()}
        >
          <span>Close</span>
        </div>
        {
          canEditUser &&
            <Link to={`/User/${user.id}`}
                  onClick={() => hideModal()}
                  className={cn('lxActionButton lxActionButtonFilled lxActionButtonDefaultSize')}>
                <span>Edit User Info</span>
            </Link>
        }
      </div>
    </>
  )
}

export default UserInfo