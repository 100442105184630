import React, { useState, useRef, useEffect, ReactNode, FC } from 'react'
import styles from './select.module.scss'
import { cn } from '@src/utils/cn.ts'
import { LxIcon } from '@components/icon/Icon.tsx' // Assuming you have a CSS module for styles
import { ArrowDownIcon, ArrowUpIcon } from '@icons/utils'
import { FilterObject } from '@logic/useFiltering.hook.ts'
import { LxDropdown } from '@components/select/dropdown.tsx'

interface CustomSelectProps<T> {
  value?: T
  onChange: (value: T) => void
  icon?: ReactNode
  options: FilterObject<T>[]
  shrinked?: boolean
  hasError?: boolean
  className?: string
  placeholder?: string
}


export const LxSelect: FC = <T,>(
  {
    placeholder,
    value,
    onChange,
    options,
    icon,
    className,
    shrinked,
    hasError
  }: CustomSelectProps<T>) => {
  const [ isOpen, setIsOpen ] = useState(false)
  const selectRef = useRef<HTMLDivElement>(null)

  const toggleDropdown = () => setIsOpen(!isOpen)

  const handleOptionClick = (optionValue: FilterObject<T>) => {
    onChange(optionValue.value)
    setIsOpen(false)
  }

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
        setIsOpen(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [ selectRef ])

  const selected = options.find(option => {
    if (!value) {
      return null;
    }
    if (typeof value === 'string') {
      return option.value === value;
    } else {
      return option.id === value?.id;
    }
  })

  return (
    <div className={cn(styles.customSelect, className)} ref={selectRef}>
      <div
        className={cn(
          styles.selectSelected,
          { [styles.shrinked]: !!shrinked },
          { ['formFieldRequired']: hasError})}
        onClick={toggleDropdown}>
        {icon && <>{icon}</>}
        <span>{selected?.label || placeholder || 'Select an option'}</span>
        <LxIcon icon={isOpen ? ArrowUpIcon : ArrowDownIcon}
                customViewBox={'0 0 960 560'}></LxIcon>
      </div>
      {isOpen &&
          <LxDropdown
            options={options}
            onOptionClick={handleOptionClick}
          />
      }
    </div>
  )
}
