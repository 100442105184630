export const cn = (...args: any[]): string => {
  return args
    .flat(Infinity) // handle deeply nested arrays
    .reduce((acc: string[], arg) => {
      if (typeof arg === 'string') {
        acc.push(arg)
      } else if (typeof arg === 'object' && arg !== null) {
        Object.keys(arg).forEach(key => {
          if (arg[key]) {
            acc.push(key)
          }
        })
      }
      return acc
    }, [])
    .join(' ')
}
