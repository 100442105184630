import { useNotify, useUpdate } from 'react-admin'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { createContextFactory } from '@logic/contexts/createContextFactory.ts'
import { AnalyticsReportF } from '@logic/contexts/Analytics/Report/AnalyticsReport.type/ts'
import { parseReportDataToAnalyticsReport } from '@logic/contexts/Analytics/Report/AnalyticsReport.factory.tsx'

import { AnalyticsReportB } from '@logic/contexts/Analytics/Report/AnalyticsReport.type/ts'

interface AnalyticReportContextType {
  reportData: AnalyticsReportF | null
  isReportFetching: boolean
  handleDurationChange: (event: string) => void
  duration: string
  handleGenerateReportClick: () => void
}

const AnalyticReportContextDefault = {
  reportData: null,
  isReportFetching: false,
  handleDurationChange: () => {},
  duration: '14',
  handleGenerateReportClick: () => {},
}

const AnalyticsReportContext = createContextFactory<AnalyticReportContextType>(
  AnalyticReportContextDefault
)

export const AnalyticsReportProvider = ({ children }) => {
  const [duration, setDuration] = useState('30')
  const [isDataLoading, setIsDataLoading] = useState(false)
  const { accountId } = useParams()

  const [report, setReport] = useState<AnalyticsReportF | null>(null)

  const notify = useNotify()
  const [update] = useUpdate('Report')

  const handleDurationChange = (value: string) => {
    setDuration(value)
  }

  const fetchData = useCallback(async () => {
    try {
      setIsDataLoading(true)
      await update(
        'Report',
        {
          id: accountId,
          data: {
            filter: { duration },
            type: 'analytics_report',
          },
          previousData: null,
        },
        {
          onSuccess: (data) => {
            const sanitizedData = parseReportDataToAnalyticsReport(
              data as AnalyticsReportB,
              duration
            )
            setReport(sanitizedData)
            setIsDataLoading(false)
          },
          onFailure: (error) => {
            setReport(null)
            const errorMessage = error.message || 'Something went wrong'
            notify(errorMessage, { type: 'warning' })
            setIsDataLoading(false)
          },
        }
      )
    } catch (error) {
      console.error('Unexpected error', error)
      setIsDataLoading(false)
    }
  }, [duration, accountId])

  return (
    <AnalyticsReportContext.Provider
      value={{
        reportData: report,
        isReportFetching: isDataLoading,
        handleDurationChange,
        handleGenerateReportClick: fetchData,
        duration,
      }}
    >
      {children}
    </AnalyticsReportContext.Provider>
  )
}

export const useAnalyticsReport = (): AnalyticReportContextType => {
  return useContext(AnalyticsReportContext)
}
