import { useNotify, useUpdate } from 'react-admin'

import React, { useState, useEffect } from 'react'
import { SearchIcon } from '@icons/utils'
import { SchoolIcon,AnalyticsOutlineIcon } from '@icons/index.ts'
import { LxLoadingSpinner } from '@components/loader/loadingSpinner.tsx'

import styles from './AnalyticsList.module.scss'
import sharedStyles from '../MultiPage.module.scss'
import { cn } from '@src/utils/cn.ts'
import { LxIcon } from '@components/icon/Icon.tsx'
import { isNilOrEmpty } from '@src/utils/isNilOrEmpty.ts'
import { Link } from 'react-router-dom'
import { parseTime, formatDate } from '@src/utils/dateFormatter'

import AccountInfo from './AccountInfo'
import RobotInfo from './RobotInfo'
import AdminInfo from './AdminInfo'


export type AccountsListB = {
  account_id: string
  account_name: string
  tt: string
  tau: string
  tal: string
  lad: string
}

export type AccountsListF = {
  accountId: string
  accountName: string
  totalUsage: string
  totalActiveUsers: string
  totalActiveLearners: string
  lastActiveAt: string
}

export const AnalyticsList = () => {
  const [update] = useUpdate('Report')
  const notify = useNotify()

  const [isDataLoading, setIsDataLoading] = useState(false)
  const [accountData, setAccountData] = useState<AccountsListF | null>(null)
  const [filteredData, seFilteredData] = useState<AccountsListF | null>(null)
  const [sortColumn, setSortColumn] = useState<string>('lastActiveAt');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');

  const sanitizeData = (data) => {
    if (!data || !data.report) {
      return null
    }

    return data.report.map((account: AccountsListB) => ({
      accountId: account.account_id,
      accountName: account.account_name,
      totalUsage: account.tt ?  account.tt : '-',
      totalActiveUsers: account.tau,
      totalActiveLearners: account.tal,
      lastActiveAt: account.lad ? formatDate(account.lad, false, false) : '-'
    }))
  }

  useEffect(() => {
    setIsDataLoading(true)
    const fetchData = async () => {
      try {
        await update(
          'Report',
          {
            id: '1', //sending dummy account id from front -end , original accountid is fetched form logged in user at backend
            data: {
              filter: {},
              type: 'accounts_lists',
            },
            previousData: null,
          },
          {
            onSuccess: (data) => {
              const sanitizedData = sanitizeData(data)
              setAccountData(sanitizedData)
              seFilteredData(sanitizedData)
              setIsDataLoading(false)
            },
            onFailure: (error) => {
              setAccountData(null)
              const errorMessage = error.message || 'Something went wrong'
              notify(errorMessage, { type: 'warning' })
              setIsDataLoading(false)
            },
          }
        )
      } catch (error) {
        // Handle error
        setIsDataLoading(false)
      }
    }
    fetchData()
  }, [])

  const handleSearchInputChange = (inputValue) => {
    const filteredAccounts = accountData.filter((account) =>
      account.accountName.toLowerCase().includes(inputValue.toLowerCase())
    )
    seFilteredData(filteredAccounts)
  }

  const handleSort = (column: string) => {
    if (sortColumn === column) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortOrder('desc');
    }
  };

  const sortedData = filteredData?.sort((a, b) => {
    let columnA = a[sortColumn];
    let columnB = b[sortColumn];
     // Handle hyphen "-" values for lastActiveAt 
    if (sortColumn === 'lastActiveAt') {
      if (columnA === '-') return 1;
      if (columnB === '-') return -1;
    }

    // Handle sorting when totalUsage is null
    if (sortColumn === 'totalUsage') {
      
      columnA = (columnA === '-' ? 0 : parseInt(columnA));
      columnB = (columnB === '-' ? 0 : parseInt(columnB));
    }
    
    if (sortColumn === 'accountName') {
      return sortOrder === 'asc' ? columnA.localeCompare(columnB) : columnB.localeCompare(columnA);
    } else if (sortColumn === 'totalUsage') {
      return sortOrder === 'asc' ? columnA - columnB : columnB - columnA;
    }
    return sortOrder === 'asc' ? new Date(columnA).getTime() - new Date(columnB).getTime() : new Date(columnB).getTime() - new Date(columnA).getTime();
    
  });

  return (
    <>
      <div className={styles.analyticsListHeadingRow}>
        <div className={cn(styles.analyticsListHeading)}>
          <div>
            <LxIcon
              sxStyles={{ height: '80px', width: '80px' }}
              icon={SchoolIcon}
            ></LxIcon>
          </div>
          <div>
            <h3>Facilities</h3>
          </div>
        </div>
        <div className={cn(sharedStyles.tableActions)}>
          <div className={'lxActionButton lxActionButtonDefaultSize'}>
            <LxIcon icon={SearchIcon} />
            <input
              className={'pristineInput'}
              type='text'
              onChange={(e) => {
                handleSearchInputChange(e.target.value)
              }}
              placeholder='Search'
            />
          </div>
        </div>
      </div>
      <div className={styles.analyticsListContent}>
        <div className={cn(styles.analyticsTableListHeader, styles.facilityName)} onClick={() => handleSort('accountName')}> Facility</div>
        <div className={cn(styles.analyticsTableListHeader)} onClick={() => handleSort('lastActiveAt')}>Last Activity</div>
        <div className={cn(styles.analyticsTableListHeader, sharedStyles.hide2column)} onClick={() => handleSort('totalUsage')}>Total Usage</div>
        <div className={cn(sharedStyles.tableListHeader, sharedStyles.hide3column)}>Info</div>
        <div
          className={cn(sharedStyles.tableListHeader, sharedStyles.hide5column)}
        >
          Robots
        </div>
        <div
          className={cn(sharedStyles.tableListHeader, sharedStyles.hide5column)}
        >
          Reports
        </div>
        <div
          className={cn(sharedStyles.tableListHeader, sharedStyles.hide6column)}
        >
          Account Admins
        </div>

        {isDataLoading ? (
          <LxLoadingSpinner className={sharedStyles.loader} />
        ) : isNilOrEmpty(filteredData) ? (
          <div className={sharedStyles.notFound}>
            No account match your current search.
          </div>
        ) : (
          sortedData.map((account: AccountsListF) => (
            <React.Fragment key={account.accountId}>
              <div>{account.accountName}</div>
              <div className={sharedStyles}>
                {account.lastActiveAt}
              </div>
              <div className={sharedStyles.hide2column}>
              {account.totalUsage != '-' ? parseTime(account.totalUsage)  : '-' }
              </div>
              <div className={sharedStyles.hide3column}>
                <AccountInfo accountId={account.accountId} />
              </div>
              <div className={sharedStyles.hide4column}>
                <RobotInfo accountId={account.accountId} accountName={account.accountName}/>
              </div>
              <div className={sharedStyles.hide6column}>
              <Link
                      to={`${account.accountId}/report?name=${account.accountName}&tau=${account.totalActiveUsers}&tal=${account.totalActiveLearners}&lad=${account.lastActiveAt}`}
                    >
                      <LxIcon icon={AnalyticsOutlineIcon}/>
                    </Link>
              </div>
              <div className={sharedStyles.hide6column}>
                <AdminInfo accountId={account.accountId} accountName={account.accountName}/>
              </div>
            </React.Fragment>
          ))
        )}
      </div>
    </>
  )
}
