import React, { useContext, useMemo, useState } from 'react';
import { useGetList, useResourceContext } from 'react-admin';
import { UserB, UserDataB } from '@logic/contexts/Users/UsersBackend.type.ts'
import { UserDataF, UserF } from '@logic/contexts/Users/UsersFrontend.type.ts';
import { createContextFactory } from '@logic/contexts/createContextFactory.ts';
import { isNilOrEmpty } from '@src/utils/isNilOrEmpty.ts'
import { DispatchType, FilterValues, useDebounceFilter } from '@src/hooks/useDebounceFilter.ts'

interface UsersContextType {
  userList: UserF[] | null;
  isLoading: boolean;
  filterValues: FilterValues;
  dispatch: DispatchType;
}

const UserListContextDefault = {
  userList: null,
  isLoading: true,
  filterValues: {
    Q: '',
    state: 'active'
  },
  dispatch: () => {},
};

const UsersListContext = createContextFactory<UsersContextType>(UserListContextDefault);

const mapServerUsersListToClient = (data: UserB[] | undefined): UserF[] | null => {
  if (isNilOrEmpty(data)) {
    return null;
  }

  return data!.map((user: UserB) => ({
    id: user.id,
    firstName: user.first_name,
    fullName: `${user.first_name} ${user.last_name}`,
    lastName: user.last_name,
    emails: user.emails ?? [],
    primaryEmail: user.primary_email ?? [],
    b2cIds: user.b2c_ids ?? [],
    creationInfo: user.creation_info ?? null,
    userRole: user.user_role ?? null,
    state: user.state ?? null,
    jobTitle: user.job_title ?? null,
    assignedLearners: user.assigned_learners ?? [],
    assignedGoals: user.assigned_goals ?? [],
    assignedUsers: user.assigned_users ?? []
  }));
};

export const UserListProvider: React.FC = ({ children, filter }) => {
  const initialFilterValues = { Q: '', state: 'active' };
  const [filterValues, dispatch] = useDebounceFilter(initialFilterValues);

  const { data, isLoading } = useGetList<UserB>(
    'User',
    {
      pagination: { page: 1, perPage: 100 },
      sort: { field: 'last_update', order: 'DESC' },
      filter: filter ? filter : filterValues
    }
  );

  const sanitizedUserList = useMemo(() => mapServerUsersListToClient(data), [data]);

  return (
    <UsersListContext.Provider value={{ userList: sanitizedUserList, filterValues, isLoading, dispatch }}>
      {children}
    </UsersListContext.Provider>
  );
};

export const useUsersList = (): UsersContextType => {
  return useContext(UsersListContext);
};
