import styles from '@src/pages/Activities/components/common.module.scss'
import { LxIcon } from '@components/icon/Icon.tsx'
import { getSvgComponent } from '@icons/skillAreas/svgMapper.util.ts'
import React from 'react'
import { cn } from '@src/utils/cn.ts'

type Props = {
  categoryName: string
  setIsOpen: (prev) => void
  className?: string
}
export const SkillAreaHeader: React.FC<Props> = ({categoryName, setIsOpen, className}) => {
  return (
    <div
      className={cn(styles.skillAreaRow, className)}
      onClick={() => setIsOpen(prev => !prev)}
    >
      <LxIcon sxStyles={{height: '32px', width: '32px'}}
              customViewBox={'0 0 1320 1320'}
              icon={getSvgComponent(categoryName)}>
      </LxIcon>
      <h4>{categoryName}</h4>
    </div>
  )
}