import React, { useCallback,  useMemo, useRef } from 'react'

import { useCreate,  useNotify, useRefresh } from 'react-admin'
import { clientUnitToServerUnit, GoalForm, GoalFormType } from '@components/goalForm/GoalForm.tsx'
import { useLearnerShow } from '@logic/contexts/Learners/LearnerShowContext.tsx'
import { cn } from '@src/utils/cn.ts'
import modalStyles from '@components/modal/modal.module.scss'
import { LxIcon } from '@components/icon/Icon.tsx'
import { PlusIcon } from '@icons/utils'
import { useModal } from '@logic/contexts/Modal/ModalContext.tsx'
import { useActivitiesList } from '@logic/contexts/Activities/ActivitiesListContext.tsx'
import { generateSimpleOptions } from '@logic/useFiltering.hook.ts'

export const LearnerGoalCreate = () => {
  const { showModal, hideModal, toggleOverlay } = useModal()
  const { learner } = useLearnerShow()

  const { allData: activitiesData  } =
    useActivitiesList()
  const [ create ] = useCreate()
  const notify = useNotify()
  const refresh = useRefresh()
  const isSubmitting = useRef(false)


  const options = useMemo(() => ( {
    skillAreas: generateSimpleOptions(activitiesData?.skillAreas.filter((skillArea) => skillArea.type !== 'curriculum'), 'name'),
  } ), [ activitiesData?.skillAreas ])

  const resetAndNotify = useCallback((message, type) => {
    notify(message, { type })
    hideModal()
    refresh()
    isSubmitting.current = false // Reset the isSubmitting flag
    toggleOverlay(isSubmitting.current)
  }, [ notify, hideModal, refresh ])

  const onSubmit = useCallback(async (formData: GoalFormType) => {
    if (isSubmitting.current) {
      return
    }
    isSubmitting.current = true
    toggleOverlay(isSubmitting.current)
    
    await create('Goal', {
      data: {
        learner_id: learner!.id,
        category_id: formData.skillArea!.id,
        sub_category_id: formData.goal!.id,
        category_name: formData.skillArea!.name,
        sub_category_name: formData.goal!.name,
        name: formData.goal!.name,
        description: formData.description,
        priority: formData.priority,
        units: clientUnitToServerUnit(formData.units as any),
      }
    }, {
      onSuccess: ({ data }) => resetAndNotify('Goal created successfully', 'success'),
      onFailure: (error) => resetAndNotify(error.message || 'Something went wrong, please try again later', 'error'),
    })
  }, [ create, resetAndNotify ])

  return (
    <>
      <div
        onClick={() => showModal(
          <div className={modalStyles.modalLg}>
            <div className={modalStyles.dialogHeader}>
              <h3>Add a new goal for {learner?.fullName}</h3>
            </div>
            <GoalForm onSubmit={onSubmit} onCancel={hideModal} dataSet={options}></GoalForm>
          </div>
        )}
        className={cn('lxActionButton lxActionButtonFilled lxActionButtonDefaultSize')}
        style={{ marginLeft: 'auto' }}
      >
        <LxIcon icon={PlusIcon}/>
        Add Goal
      </div>
    </>
  )
}
