import * as React from 'react'
import { type FC, ReactNode } from 'react'
import styles from './icon.module.scss'
import { LxIcon, LxIconProps } from '@components/icon/Icon.tsx'
import { cn } from '@src/utils/cn.ts'

type Props = LxIconProps & {
  onClick: (a?: any) => void,
  className?: string,
  dropdown?: ReactNode
}

export const LxClickAbleIcon: FC<Props> = ({ onClick, className, dropdown, ...rest }) => {
  return (
    <div onClick={onClick} className={cn(styles.clickable, className)}>
      <LxIcon {...rest}></LxIcon>
      { dropdown }
    </div>
  )
}