import { FC, useState } from 'react'
import { AnalyticsReportF } from '@logic/contexts/Analytics/Report/AnalyticsReport.type/ts'
import styles from './UsageReport.module.scss'
import { Bar } from 'react-chartjs-2'
import {
  generateChartOptions,
  generateTimeChartOptions,
  getChartConfig,
} from './chart.utils'

import { cn } from '@src/utils/cn.ts'
import { parseTime } from '@src/utils/dateFormatter'

import { formatDate, formatWeekDate } from '@src/utils/dateFormatter'
import { LxClickAbleIcon } from '@src/components/icon/clickAbleIcon'
import { ArrowDownIcon, ArrowUpIcon } from '@icons/utils'

type Props = {
  reportData: AnalyticsReportF
}

function hasEntriesGreaterThanN(data, n) {
  return data.some((entry) => entry.y !== null && entry.y > n)
}

export const UsageReport: FC<Props> = ({ reportData, duration }) => {
  const [isOpen, setIsOpen] = useState(true) // State to track whether the section is open or closed

  const toggleSection = () => {
    setIsOpen(!isOpen)
  }

  const data = reportData
  const units = data.units

  const formatXDate = (date) =>
    duration === '90' ? formatWeekDate(date) : formatDate(date, false, false)

  const processChartData = (units, key) => {
    return Object.entries(units).map(([date, values]) => ({
      x: formatXDate(date),
      y:
        key === 'totalTime'
          ? parseFloat((values[key] / 3600).toFixed(2))
          : parseInt(values[key]),
    }))
  }

  // Extracting data for time spent graph
  const timeSpentData = processChartData(units, 'totalTime') // Convert seconds to hours for time Spent graph
  const totalQuestionsData = processChartData(units, 'totalQuestions')

  const QuestionChartptions = generateChartOptions(
    'Total discrete questions',
    10,
    hasEntriesGreaterThanN(totalQuestionsData, 10) ? null : 10
  )

  const timeChartptions = generateTimeChartOptions(
    'Time spent in hours',
    1,
    hasEntriesGreaterThanN(timeSpentData, 1) ? null : 1
  ) as any

  const barThickness = units && Object.keys(units).length < 15 ? 15 : 10

  return (
    <div className={styles.activityPdfContainer}>
      <>
      <div className={`${styles.Title} ${!isOpen ? styles.ignoreOnPrint : ''}`} onClick={toggleSection}>
          <h3>Usage Metrics</h3>
         <LxClickAbleIcon
            icon={isOpen ? ArrowUpIcon : ArrowDownIcon}
            customViewBox={'0 0 960 560'}
          />
        </div>
        {isOpen && (
            <>
                <div id='toPdfReport' className={cn(styles.pdfAdjust)}>
                    <div className={styles.activitySummaryContainer}>
                        <div className={styles.summaryTitle}>Usage Summary</div>
                        <div>Total Usage Time</div>
                        <div>Total practiced Activities</div>
                        <div>Total Distinct Questions</div>
                        <div>Last Activity Date</div>
                        <div></div>
                        <div>{parseTime(data.totalTime)}</div>
                        <div>{data.totalActivities}</div>
                        <div>{data.totalUniqQuest}</div>
                        <div>{formatDate(data.lastActivityDate, false, false)}</div>
                    </div>
                </div>

                <div id='toPdfReport' className={styles.timeSpentChart}>
                    <h5 className={styles.pdfAdjust}>Time spent working with QTrobot:</h5>
                    <div className={styles.chartContainer}>
                        <Bar
                        id='timeSpentChart'
                        options={timeChartptions}
                        data={{
                            labels: timeSpentData.map((dataPoint) => dataPoint.x),
                            datasets: [
                            {
                                label: 'Time Spent (hours)',
                                data: timeSpentData.map((dataPoint) => dataPoint.y),
                                ...getChartConfig('#8fb3dc', barThickness),
                            },
                            ],
                        }}
                        />
                    </div>
                </div>

                <div id='toPdfReport' className={styles.totalQuestionsChart}>
                    <h5 className={styles.pdfAdjust}>
                        Total Number of Discrete Questions/Tasks Practiced :{' '}
                    </h5>
                    <div className={styles.chartContainer}>
                        <Bar
                        id='totalQuestionsChart'
                        options={QuestionChartptions}
                        data={{
                            labels: totalQuestionsData.map((dataPoint) => dataPoint.x),
                            datasets: [
                            {
                                label: 'Total Questions',
                                data: totalQuestionsData.map((dataPoint) => dataPoint.y),
                                ...getChartConfig('#8fb3dc', barThickness),
                            },
                            ],
                        }}
                        />
                    </div>
                </div>
            </>   
        )}   
      </>
    </div>
  )
}
