import React, { useState, useEffect } from 'react'
import { SearchIcon, PlusIcon, MinusIcon, SettingsIcon } from '@icons/utils'

import styles from './UserLearners.module.scss'
import sharedStyles from '../../../MultiPage.module.scss'
import { LxIcon } from '@components/icon/Icon.tsx'
import { cn } from '@src/utils/cn.ts'
import { LxLoadingSpinner } from '@components/loader/loadingSpinner.tsx'
import { LxClickAbleIcon } from '@components/icon/clickAbleIcon.tsx'
import { AddNewUserLearner } from '@src/pages/User/Show/Learners/AddNewLearner.tsx'
import { ConfirmationModal } from '@src/utils/confirmationModel'
import { isNilOrEmpty } from '@src/utils/isNilOrEmpty.ts'
import { mapRoleToReadableRole } from '@src/utils/mapRoleToReadableRole.ts'
import { GoalReAssign } from './GoalReAssign'
import { EditAccess } from './EditAccess'

import { useDataProvider, useNotify, useRefresh } from 'react-admin'
import { useModal } from '@logic/contexts/Modal/ModalContext.tsx'
import { useSubmitLoader } from '@src/logic/contexts/utils/SubmitContext.tsx'
import { LearnerF } from '@src/logic/contexts/Learners/LearnersFrontend.type'

export const UserView = ({
  userAssignedLearners,
  userFullName,
  userId,
  userRole,
  userAssignedGoals,
}) => {
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const refresh = useRefresh()

  const readableUserRole = mapRoleToReadableRole(userRole)

  const [learnerData, setLearnerData] = useState<Learner[]>([])
  const [isLearnerDataLoading, setIsLearnerDataLoading] = useState(true)
  const [assignedLearnerData, setAssignedLearnerData] = useState([])
  const [filteredAssignedLearnerData, setFilteredAssignedLearnerData] =
    useState()
  const [openGoalDialog, setOpenGoalDialog] = useState(false)
  const [selectedLearner, setSelectedLearner] = useState('')
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false)
  const [openAccessModal, setOpenAccessModal] = useState(false)
  const { showModal, hideModal } = useModal()
  const { isFormSubmitting, setIsFormSubmitting } = useSubmitLoader()

  // for handling goal assignment
  const [selectedUser, setSelectedUser] = useState('')
  const [userData, setUserData] = useState<User[]>([])
  const [isUserDataLoading, setIsUserDataLoading] = useState(true)
  const [userChoice, setUserChoice] = useState('')

  const fullName = (learner) => {
    return learner.first_name && learner.last_name
      ? `${learner.first_name} ${learner.last_name}`
      : learner.first_name
      ? learner.first_name
      : learner.last_name
      ? learner.last_name
      : learner.nick_name
      ? learner.nick_name
      : learner.id_code
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await dataProvider.getList('Learner', {
          pagination: { page: 0, perPage: 100 },
          sort: { field: 'id', order: 'ASC' },
          filter: {"state": "active"},
        })

        const LearnerDataSubSet: Learner[] = []

        response.data.forEach((learner: any) => {
          const learnerDataItem: Learner = {
            id: learner.id,
            fullName: fullName(learner)
          }
          LearnerDataSubSet.push(learnerDataItem)
        })

        setLearnerData(LearnerDataSubSet)
        setIsLearnerDataLoading(false)

        const assignedLearnerData = LearnerDataSubSet.filter((learner) =>
          userAssignedLearners.hasOwnProperty(learner.id)
        ).map((learner) => {
          const learnerAccess = {}
          if (userAssignedLearners[learner.id]) {
            userAssignedLearners[learner.id].forEach((access) => {
              learnerAccess[access] = true
            })
          }
          return {
            id: learner.id,
            fullName: learner.fullName,
            access: learnerAccess,
          }
        })

        setAssignedLearnerData(assignedLearnerData)
        setFilteredAssignedLearnerData(assignedLearnerData)
      } catch (error) {
        // Handle error
        setIsLearnerDataLoading(false)
      }
    }

    fetchData()
  }, [userAssignedLearners])

  const handleSearchInputChange = (inputValue) => {
    const filteredLearners = assignedLearnerData.filter((learner) =>
      learner.fullName.toLowerCase().includes(inputValue.toLowerCase())
    )
    setFilteredAssignedLearnerData(filteredLearners)
  }

  const handleUnAssign = async (learnerId: string) => {
    try {
      // check is there any goal asisgned to learner by this user
      const learnerGoals = userAssignedGoals[learnerId]
      setSelectedLearner(learnerId)

      if (learnerGoals && learnerGoals.length > 0) {
        setOpenGoalDialog(true)
      } else {
        setIsConfirmationOpen(true)
      }
    } catch (error: any) {
      const errorMessage =
        error.message || 'Something went wrong, Please try again after sometime'
      notify(errorMessage, { type: 'error' })
      refresh()
    }
  }

  const handleConfirmUnassign = async () => {
    if (isFormSubmitting) {
      return
    }

    setIsFormSubmitting(true)

    try {
      await dataProvider.update('User', {
        id: userId,
        data: {
          assign_learner: {
            action: 'remove_learner',
            learner_id: selectedLearner,
            assign_goals: null,
          },
        },
        previousData: null,
      })
      notify('Learner is removed successfully', { type: 'success' })
    } catch (error) {
      const errorMessage =
        error.message || 'Something went wrong, Please try again after sometime'
      notify(errorMessage, { type: 'error' })
    } finally {
      setIsFormSubmitting(false)
      setIsConfirmationOpen(false)
      refresh()
    }
  }

  // handling reassign goals

  // Function to fetch users from the database
  const fetchUsers = async () => {
    try {
      const response = await dataProvider.getList('User', {
        pagination: { page: 0, perPage: 100 },
        sort: { field: 'id', order: 'ASC' },
        filter: { list: 'all_users' },
      })

      // Filtering out the user with the same id as userId
      const userDataSubset: User[] = response.data
        .filter((user: any) => user.id !== userId)
        .map((user: any) => ({
          id: user.id,
          firstName: fullName(user),
        }))

      setUserData(userDataSubset)
      setIsUserDataLoading(false)
    } catch (error) {
      // Handle error
      setIsUserDataLoading(false)
    }
  }

  const handleCloseDialog = () => {
    setOpenGoalDialog(false)
    setSelectedUser('')
    setUserChoice('')
  }

  const handleUserChoiceChange = (event) => {
    setUserChoice(event.target.value)

    if (event.target.value === 'assign') {
      fetchUsers()
    }
  }

  const handleSelectedUserChange = (event) => {
    setSelectedUser(event.target.value)
  }

  const handleUserChoiceSubmit = async () => {
    if (isFormSubmitting) {return};

    setIsFormSubmitting(true);

    try {
      if (userChoice === 'assign') {
        await dataProvider.update('User', {
          id: userId,
          data: {
            assign_learner: {
              action: 'remove_learner',
              learner_id: selectedLearner,
              assign_goals: selectedUser,
            },
          },
          previousData: null,
        })
        notify('Learner is un assigned successfully', { type: 'success' })
        // Close the dialog
        handleCloseDialog()
        refresh()
      } else if (userChoice === 'unassign') {
        await dataProvider.update('User', {
          id: userId,
          data: {
            assign_learner: {
              action: 'remove_learner',
              learner_id: selectedLearner,
              assign_goals: null,
            },
          },
          previousData: null,
        })
        notify('Learner is un assigned successfully', { type: 'success' })
      }
    } catch (error) {
      const errorMessage =
        error.message || 'Something went wrong, Please try again after sometime'
      notify(errorMessage, { type: 'error' })
    } finally {
      refresh();
      setIsFormSubmitting(false);
      handleCloseDialog();
    }
  }

  const handleAccessEdit = async (learner) => {
    setSelectedLearner(learner)
    setOpenAccessModal(true)
  }

  // getting unassigned learnes
  const assignedLearnerIds = new Set(
    assignedLearnerData.map((learner) => learner.id)
  )
  const unAssignedLearnerData = learnerData.filter(
    (learner) => !assignedLearnerIds.has(learner.id)
  )

  return (
    <>
      <div className={styles.userLearnersNavigation}>
        {isLearnerDataLoading ? (
          <LxLoadingSpinner />
        ) : (
          <>
            <div className={cn(styles.userLearnerInfo)}>
              <h3>Learners Assigned to {userFullName} </h3>
            </div>
            <div className={cn(sharedStyles.tableActions)}>
              <div
                className={cn(
                  'lxActionButton',
                  'lxActionButtonFilled lxActionButtonDefaultSize'
                )}
                onClick={() =>
                  showModal(
                    <AddNewUserLearner
                      onClose={hideModal}
                      learners={unAssignedLearnerData}
                      userId={userId}
                      userFullName={userFullName}
                      userRole={readableUserRole}
                    ></AddNewUserLearner>
                  )
                }
              >
                <LxIcon icon={PlusIcon} />
                Assign New Learner
              </div>
              <div className={'lxActionButton lxActionButtonDefaultSize'}>
                <LxIcon icon={SearchIcon} />
                <input
                  className={'pristineInput'}
                  type='text'
                  onChange={(e) => {
                    handleSearchInputChange(e.target.value)
                  }}
                  placeholder='Search'
                />
              </div>
            </div>
          </>
        )}
      </div>
      <div className={styles.userLearnersList}>
        <div className={cn(sharedStyles.tableListHeader)}> Learner Name</div>
        <div
          className={cn(sharedStyles.tableListHeader, sharedStyles.hide2column)}
        ></div>
        <div
          className={cn(sharedStyles.tableListHeader, sharedStyles.hide3column)}
        >
          Access Control
        </div>
        <div
          className={cn(sharedStyles.tableListHeader, sharedStyles.hide4column)}
        >
          Unassign
        </div>
        <>
          {isLearnerDataLoading ? (
            <LxLoadingSpinner className={sharedStyles.loader} />
          ) : isNilOrEmpty(filteredAssignedLearnerData) ? (
            <div className={sharedStyles.notFound}>
              No learners match your current search or filters.
            </div>
          ) : (
            filteredAssignedLearnerData.map((learner: LearnerF) => (
              <React.Fragment key={`User-row-${learner.id}`}>
                <div>{learner.fullName}</div>
                <div className={sharedStyles.hide2column}></div>
                <div>
                  <LxClickAbleIcon
                    className={sharedStyles.hide3column}
                    onClick={() => {
                      handleAccessEdit(learner)
                    }}
                    icon={SettingsIcon}
                  />
                </div>

                <LxClickAbleIcon
                  className={sharedStyles.hide4column}
                  onClick={() => {
                    handleUnAssign(learner.id)
                  }}
                  icon={MinusIcon}
                />
              </React.Fragment>
            ))
          )}
        </>
      </div>

      <ConfirmationModal
        isOpen={isConfirmationOpen}
        onClose={() => setIsConfirmationOpen(false)}
        onConfirm={handleConfirmUnassign}
        message='Are you sure you want to unassign this learner?'
        isFormSubmitting={isFormSubmitting}
      />

      <EditAccess
        isModalOpen={openAccessModal}
        onClose={() => setOpenAccessModal(false)}
        learner={selectedLearner}
        userRole={readableUserRole}
        learnerFullName={selectedLearner?.fullName}
        userFullName={userFullName}
        userId={userId}
        isFormSubmitting={isFormSubmitting}
        setIsFormSubmitting={setIsFormSubmitting}
      />

      <GoalReAssign
        open={openGoalDialog}
        handleClose={handleCloseDialog}
        userChoice={userChoice}
        handleUserChoiceChange={handleUserChoiceChange}
        selectedUser={selectedUser}
        handleSelectedUserChange={handleSelectedUserChange}
        userData={userData}
        isUserDataLoading={isUserDataLoading}
        handleUserChoiceSubmit={handleUserChoiceSubmit} 
        isFormSubmitting={isFormSubmitting}
      />
    </>
  )
}
