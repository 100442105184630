import {
  useNotify,
  useUpdate
} from 'react-admin'
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { createContextFactory } from '@logic/contexts/createContextFactory.ts';
import { ProgressReport } from '@logic/contexts/Learners/LearnersFrontend.type.ts'
import {
  createFakeReport, fakeDummyFiltersParser,
  filterProgressReport,
  InitialReportFilters, mapProgressReportToFilterOptions, parseReportDataToProgressReport,
  ReportFilters
} from '@logic/contexts/Learners/ProgressReport/progressReport.factory.tsx'
import { parseReportDataToUsageReport } from './usageReport.factory';

import {
  ACTIVITY_UNIT_FILTER_KEY, defaultProgressReportFilter, GOALS_FILTER_KEY, ProgressReportFilterType,
  SKILL_AREAS_FILTER_KEY,
  STATUS_FILTER_KEY,
  useFiltering
} from '@logic/useFiltering.hook.ts'
import { ReportDataB } from '@logic/contexts/Learners/LearnersBackend.type.ts'
import { useActivitiesList } from '@logic/contexts/Activities/ActivitiesListContext.tsx'
import { UsageReportB } from '../LearnersBackend.type';


interface LearnerReportContextType {
  allData: ProgressReport | null
  displayData: ProgressReport | null
  isReportFetching: boolean
  isFilteringProcess: boolean
  handleDurationChange: (event: string) => void
  duration: string
  handleGenerateReportClick: () => void
  reportFilterOptions: ReportFilters
  filters: defaultProgressReportFilter
  resetFilters: () => void
  isPristine: boolean
}

const LearnerReportContextDefault = {
  allData: null,
  displayData: null,
  isReportFetching: false,
  isFilteringProcess: false,
  handleDurationChange: () => {},
  duration: '30',
  handleGenerateReportClick: () => {},
  reportFilterOptions: InitialReportFilters,
  filters: defaultProgressReportFilter,
  resetFilters: () => {},
  isPristine: true,
};

const ProgressReportContext = createContextFactory<LearnerReportContextType>(LearnerReportContextDefault);

export const ProgressReportProvider = ({ children }) => {
  const [duration, setDuration] = useState('30')
  const [isPristine, setIsPristine] = useState(true)
  const [isDataLoading, setIsDataLoading] = useState(false)
  const [isFilteringProcess, setIsFilteringProcess] = useState(true)
  const { id } = useParams()
  const [reportFilters, setReportFilters] = useState(InitialReportFilters)
  const [searchParams, setSearchParams] = useSearchParams();


  const [report, setReport] = useState<ProgressReport | null>(null);
  const [usageReport, setUsageReport] = useState<UsageReportB | null>(null);
  const [displayReport, setDisplayReport] = useState<ProgressReport | null>(null);

  const filteringConfig = [
    { key: SKILL_AREAS_FILTER_KEY },
    { key: GOALS_FILTER_KEY },
    { key: ACTIVITY_UNIT_FILTER_KEY },
    { key: STATUS_FILTER_KEY },
  ];


  const { allData: allActivitiesFromActivityPage } = useActivitiesList()
  const fakeReport = useMemo(() => createFakeReport(allActivitiesFromActivityPage), [allActivitiesFromActivityPage])
  const [fakeReportFilters, setFakeReportFilters] = useState(fakeDummyFiltersParser(allActivitiesFromActivityPage))
  const { filters: fakeFilters, resetFilters: resetFakeFilters } = useFiltering(filteringConfig, defaultProgressReportFilter)

  const { filters, resetFilters } = useFiltering(filteringConfig, defaultProgressReportFilter)

  const notify = useNotify()
  const [update] = useUpdate('Report');


  const handleDurationChange = (value: string) => {
    setDuration(value);
  };


  const fetchData = useCallback(async () => {
    try {
      setIsPristine(false)
      setIsDataLoading(true)
      setIsFilteringProcess(true)
      setSearchParams({})
      // resetFilters()
      await update('Report', {
          id,
          data: {
            filter: { duration },
            type: 'learner_progress_report'
          },
          previousData: null
        },
        {
          onSuccess: (data) => {
            const sanitizedData = parseReportDataToProgressReport(data as ReportDataB, duration)
            setReport(sanitizedData)
            setReportFilters({...mapProgressReportToFilterOptions(sanitizedData)})
            setUsageReport(parseReportDataToUsageReport(data as ReportDataB, duration))
            setIsDataLoading(false)
          },
          onFailure: (error) => {
            setReport(null)
            const errorMessage = error.message || 'Something went wrong'
            notify(errorMessage, { type: 'warning' })
            setIsDataLoading(false)
          }
        }
      );
    } catch (error) {
      console.error('Unexpected error', error)
      setIsDataLoading(false)
    }

    }, [duration, id]);

  useEffect(() => {
      setDisplayReport(filterProgressReport(report, filters as ProgressReportFilterType))
    }, [
      report,
      filters[SKILL_AREAS_FILTER_KEY].data,
      filters[GOALS_FILTER_KEY].data,
      filters[ACTIVITY_UNIT_FILTER_KEY].data,
      filters[STATUS_FILTER_KEY].data
    ]
  );

  return (
    <ProgressReportContext.Provider value={{
      allData: isPristine ? fakeReport : report,
      displayData: displayReport,
      usageReportData: usageReport,
      isReportFetching: isDataLoading,
      isFilteringProcess,
      reportFilterOptions: isPristine ? fakeReportFilters : reportFilters,
      handleDurationChange,
      handleGenerateReportClick: fetchData,
      duration,
      filters: isPristine ? fakeFilters : filters,
      resetFilters: isPristine ? resetFakeFilters : resetFilters,
      isPristine,
    }}>
      {children}
    </ProgressReportContext.Provider>
  );
};

export const useProgressReport = (): LearnerReportContextType => {
  return useContext(ProgressReportContext);
};
