// // Example usage:
// const originalArray = [
//   { id: 1, name: 'John' },
//   { id: 2, name: 'Jane' },
//   { id: 1, name: 'John' },
//   { id: 3, name: 'Doe' },
// ];
//
// // Specify the key (property) based on which uniqueness is determined
// const keyForUniqueness = 'id';
//
// const uniqueResult = uniqueObjectsByKey(originalArray, keyForUniqueness);
// console.log(uniqueResult);
// Output: [{ id: 1, name: 'John' }, { id: 2, name: 'Jane' }, { id: 3, name: 'Doe' }]


export const uniqueArrayByKey = <T>(arr: T, key: string): T[] => {
  // Use a Map to store unique objects based on the specified key
  const uniqueMap = new Map();

  for (const obj of arr) {
    // Use the specified key to determine uniqueness
    const keyValue = obj[key];

    // If the key value is not in the map, add the object to the map
    if (!uniqueMap.has(keyValue)) {
      uniqueMap.set(keyValue, obj);
    }
  }

  // Convert the values of the Map back to an array
  const uniqueArray = Array.from(uniqueMap.values());

  return uniqueArray;
}
