import { type GetOneParams, useGetList, useGetOne, useRecordContext, useRedirect, useResourceContext } from 'react-admin'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { type LearnerB } from '@logic/contexts/Learners/LearnersBackend.type.ts'
import { useParams } from 'react-router-dom'
import { type LearnerF } from '@logic/contexts/Learners/LearnersFrontend.type.ts'
import { createContextFactory } from '@logic/contexts/createContextFactory.ts'

interface LearnerShowContextType {
  learner: LearnerF | null
  onEditProfile: () => any
  isLoading: boolean
}

const LearnerShowContextDefault = {
  learner: null,
  onEditProfile: () => {},
  isLoading: true
}

const LearnerShowContext = createContextFactory<LearnerShowContextType>(LearnerShowContextDefault)

export const rawLearnerToLearnerFullName = (learner: LearnerB): string => {
  return (learner.first_name && learner.last_name) ? `${learner.first_name} ${learner.last_name}` :
    learner.first_name ? learner.first_name :
    learner.last_name ? learner.last_name :
    learner.nick_name ? learner.nick_name :
      learner.id_code ? learner.id_code : '';
}

const mapLearnerShowServerToClient = (learner: LearnerB | undefined): LearnerF | null => {
  if (!learner) {
    return null
  }

  const assigneeUsersArray = Object.entries(learner.assignee_users || {}).map(([key,value]) => ({
    [key]: value
  }));

  const assignedGoals = Object.entries(learner.assigned_goals || {}).map(([key,value]) => ({
    [key]: value
  }));

  return {
    id: learner.id,
    accountId: learner.account_id,
    firstName: learner.first_name,
    lastName: learner.last_name,
    fullName: rawLearnerToLearnerFullName(learner),
    nickName: learner.nick_name,
    gender: learner.gender,
    birthday: learner.birthday,
    idCode: learner.id_code,
    fileCode: learner.file_code,
    grade: learner.grade,
    state: learner.state,
    assigneeUsers: assigneeUsersArray,
    assignedGoals: assignedGoals
  }
}

export const LearnerShowProvider = ({ children }) => {
  const resourceContext = useResourceContext() || "Learner"
  const { id } = useParams() // this component is rendered in the /books/:id path
  const redirect = useRedirect()
  const params: GetOneParams<LearnerB> = { id: id ?? '' } // Provide a default value for undefined id
  const { data, isLoading } = useGetOne<LearnerB>(
    resourceContext,
    params,
    { onError: () => { redirect(`/${resourceContext}`) } }
  )

  const sanitizedLearner = useMemo(() => data ? mapLearnerShowServerToClient(data) : null, [data])
  const onEditProfile = () => { redirect(`/${resourceContext}/${id}/show/edit`) }

  return (
    <LearnerShowContext.Provider value={{ learner: sanitizedLearner, isLoading, onEditProfile }}>
      {children}
    </LearnerShowContext.Provider>
  )
}

export const useLearnerShow = (): LearnerShowContextType => {
  return useContext(LearnerShowContext)
}
