import React from 'react'
import { UnitF } from '@logic/contexts/Activities/ActivitiesFrontend.type.ts'
import { Link } from 'react-router-dom'
import styles from './common.module.scss'
import { LxIcon } from '@components/icon/Icon.tsx'
import { ActivityPurpleIcon } from '@icons/index.ts'
import { InfoOutlineIcon } from '@icons/utils'
import { LxTooltip } from '@components/tooltip/tooltip.tsx'

interface Props {
  unit: UnitF;
}

export const ActivitiesUnit: React.FC<Props> = ({ unit }) => {

  return (
    <Link
      to={`${window.location.pathname}/${unit.unitId}/show`}
      className={styles.unitArea}
    >
      <span>
        <LxIcon icon={ActivityPurpleIcon}></LxIcon>
        {unit.title}
      </span>
      <LxTooltip tooltipText={`Read more`}>
        <LxIcon icon={InfoOutlineIcon}></LxIcon>
      </LxTooltip>
    </Link>
  );
}
