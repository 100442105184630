import React, { FC, useMemo, useState } from 'react'
import { Dialog } from '@mui/material'
import styles from './ActivityReportDetailsModal.module.scss'
import { isNilOrEmpty } from '@src/utils/isNilOrEmpty.ts'
import { ActivityResultF } from '@logic/contexts/Learners/LearnersFrontend.type.ts'
import { cn } from '@src/utils/cn.ts'


type DetailsResult = {
  question: string;
  answer: string;
  timeTaken: string;
  isCorrect: boolean;
}

export type SelectedDetailsActivity = {
  results: ActivityResultF[],
  unitName?: string;
  sessionDate: string;
};

type Props = {
  isModalOpen: boolean;
  handleCloseModal: () => void;
  selectedActivity: SelectedDetailsActivity | null;
};

export const ActivityDetailDialog: FC<Props> = ({ isModalOpen, handleCloseModal, selectedActivity }) => {
  const [highlightCorrect, setHighlightCorrect] = useState<string | null>(null);
  const [highlightWrong, setHighlightWrong] = useState<string | null>(null);

  const toggleHighlightCorrect = () => {
    setHighlightCorrect(prev => prev === 'correct' ? null : 'correct');
    setHighlightWrong(null);
  };

  const toggleHighlightWrong = () => {
    setHighlightWrong(prev => prev === 'wrong' ? null : 'wrong');
    setHighlightCorrect(null);
  };

  const onModalClose = () => {
    setHighlightWrong(null)
    setHighlightCorrect(null)
    handleCloseModal()
  }

  const sanitizedData: DetailsResult[] = useMemo(() => {
    if (isNilOrEmpty(selectedActivity?.results)) {
      return [];
    }
    return selectedActivity!.results.map((result) => {
      const questionTime = new Date(result.questionTime);
      const answerTime = new Date(result.answerTime);
      const timeTakenInSeconds = ((answerTime - questionTime) / 1000).toFixed(2);

      let timeTaken;
      if (timeTakenInSeconds < 60) {
        timeTaken = `${timeTakenInSeconds} seconds`;
      } else {
        const timeTakenInMinutes = (timeTakenInSeconds / 60).toFixed(2);
        timeTaken = `${timeTakenInMinutes} minutes`;
      }

      return {
        question: result.question,
        answer: result.answer,
        isCorrect: result.answer === 'correct',
        timeTaken,
      };
    });
  }, [selectedActivity?.sessionDate]);

  const totalQuestions = sanitizedData.length ?? '-';
  const correctAnswers = sanitizedData.filter((result: DetailsResult) => result.isCorrect)?.length ?? '-';
  const wrongAnswers = sanitizedData.filter((result: DetailsResult) => !result.isCorrect)?.length ?? '-';


  return (
    <Dialog open={isModalOpen} onClose={onModalClose} fullWidth maxWidth="md">
      <div className={styles.dialogContent}>
        {selectedActivity
          ? <>
            <div className={styles.detailsSummary}>
              <h3>{selectedActivity.unitName}</h3>
              <h5>Detail of questions asked and answered during session: {selectedActivity.sessionDate}</h5>
              <div className={styles.summaryRow}>
                <p>Total Questions: {totalQuestions}</p>
                <div className={cn(styles.correctAnchor)}
                  onClick={toggleHighlightCorrect}>
                  Correct Answers: {correctAnswers}
                </div>
                <div className={cn(styles.wrongAnchor)}
                  onClick={toggleHighlightWrong}
                >
                  Wrong Answers: {wrongAnswers}
                </div>
              </div>
            </div>
            <div className={styles.activityDetailsContainer}>
              <p className={styles.gridHeader}>Question</p>
              <p className={styles.gridHeader}>Answer</p>
              <p className={styles.gridHeader}>Time Taken</p>
              {!isNilOrEmpty(sanitizedData) && sanitizedData.map((result, index) => (
                <React.Fragment key={`${result.question}-${index}`}>
                  <p className={cn(
                    styles.activityItem,
                    { [styles.highlightWrong]: highlightWrong == result.answer },
                    { [styles.highlightCorrect]: highlightCorrect == result.answer }
                  )}>{result.question}</p>
                  <p className={cn(
                    styles.activityItem,
                    { [styles.highlightWrong]: highlightWrong == result.answer },
                    { [styles.highlightCorrect]: highlightCorrect == result.answer }
                  )}>{result.answer}</p>
                  <p className={cn(
                    styles.activityItem,
                    { [styles.highlightWrong]: highlightWrong == result.answer },
                    { [styles.highlightCorrect]: highlightCorrect == result.answer }
                  )}>{result.timeTaken}</p>
                </React.Fragment>
              ))}
            </div>
          </>
          : <div> No data </div>
        }
      </div>
    </Dialog>
  );
};
