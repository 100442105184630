import { FC, useState } from 'react'
import { AnalyticsReportF } from '@logic/contexts/Analytics/Report/AnalyticsReport.type/ts'
import stylesPdf from './ReportArea.module.scss'
import { Bar } from 'react-chartjs-2'
import { generateChartOptions,generateTimeChartOptions, getChartConfig } from './chart.utils'

import { ToggleButton } from '@components/toggleButton/toggleButton.tsx'

import { cn } from '@src/utils/cn.ts'
import { parseTime } from '@src/utils/dateFormatter'


import { formatDate, formatWeekDate } from '@src/utils/dateFormatter'

type Props = {
  reportData: AnalyticsReportF
}


function hasEntriesGreaterThanN(data, n) {
  return data.some(entry => entry.y !== null && entry.y > n);
}

export const ReportArea: FC<Props> = ({ reportData, duration }) => {
  const data = reportData
  const units = data.units

  const [activityFilters, setActivityFilters] = useState({
    mastered: true,
    emerging: false,
    introduced: false,
  });

  const formatXDate = (date) => duration === '90' ? formatWeekDate(date) : formatDate(date, false, false);

  const processChartData = (units, key) => {
    return Object.entries(units).map(([date, values]) => ({
      x: formatXDate(date),
      y: key === 'totalTime' ?  parseFloat((values[key] / 3600).toFixed(2))  : parseInt(values[key]),
    }));
  };
  
  // Extracting data for time spent graph
  const timeSpentData = processChartData(units, 'totalTime'); // Convert seconds to hours for time Spent graph
  const totalQuestionsData = processChartData(units, 'totalQuestions');
  const totalAchievedGoalsData = processChartData(units, 'totalAchievedGoals');
  const totalMasteredActivitiesData = processChartData(units, 'totalMasteredActivities');
  const totalEmergingActivitiesData = processChartData(units, 'totalEmergingActivities');
  const totalIntroducedActivitiesData = processChartData(units, 'totalIntroducedActivities');

  // this is for setting step size in graph
  const hasYGreaterThan5Mastered = hasEntriesGreaterThanN(totalMasteredActivitiesData, 5);
  const hasYGreaterThan5Emerging = hasEntriesGreaterThanN(totalEmergingActivitiesData, 5);
  const hasYGreaterThan5Introduced = hasEntriesGreaterThanN(totalIntroducedActivitiesData, 5);

  const QuestionChartptions = generateChartOptions('Total discrete questions', 10, hasEntriesGreaterThanN(totalQuestionsData,10) ? null : 10);
  const GoalChartptions = generateChartOptions('Achieved Goals', 1, hasEntriesGreaterThanN(totalAchievedGoalsData,5) ? null : 5 );
  const ActivityChartptions = generateChartOptions('Activities', 1, (hasYGreaterThan5Mastered || ( hasYGreaterThan5Emerging && activityFilters.emerging) || (hasYGreaterThan5Introduced && activityFilters.introduced)) ? null : 5 ) as any;

  const timeChartptions = generateTimeChartOptions('Time spent in hours', 1, hasEntriesGreaterThanN(timeSpentData,1) ? null : 1) as any

  const barThickness =
    units && Object.keys(units).length < 15 ? 15 : 10
  const activitiesBarThickness =
    units && Object.keys(units).length < 15 ? 16 : 8

  return (
    <div className={stylesPdf.activityPdfContainer}>
      <>
        <div id='toPdfReport' className={cn(stylesPdf.pdfAdjust)}>
          <h3 className={stylesPdf.unitTitle}>Usage Metrics</h3>
          <div className={stylesPdf.activitySummaryContainer}>
            <div className={stylesPdf.summaryTitle}>Usage Summary</div>
            <div>Total Usage Time</div>
            <div>Total practiced Activities</div>
            <div>Total Distinct Questions</div>
            <div>Total Learners engaged</div>
            <div></div>
            <div>{parseTime(data.totalTime)}</div>
            <div>{data.totalActivities}</div>
            <div>{data.totalUniqQuest}</div>
            <div>{data.totalLearners}</div>
          </div>
        </div>
        <div id='toPdfReport' className={stylesPdf.timeSpentChart}>
          <h5 className={stylesPdf.pdfAdjust}>
            Time spent working with QTrobot:
          </h5>
          <div className={stylesPdf.chartContainer}>
            <Bar
              id='timeSpentChart'
              options={timeChartptions}
              data={{
                labels: timeSpentData.map((dataPoint) => dataPoint.x),
                datasets: [
                  {
                    label: 'Time Spent (hours)',
                    data: timeSpentData.map((dataPoint) => dataPoint.y),
                    ...getChartConfig('#8fb3dc', barThickness),
                  },
                ],
              }}
            />
          </div>
        </div>
        <div id='toPdfReport' className={stylesPdf.totalQuestionsChart}>
          <h5 className={stylesPdf.pdfAdjust}>
            Total Number of Discrete Questions/Tasks Practiced :{' '}
          </h5>
          <div className={stylesPdf.chartContainer}>
            <Bar
              id='totalQuestionsChart'
              options={QuestionChartptions}
              data={{
                labels: totalQuestionsData.map((dataPoint) => dataPoint.x),
                datasets: [
                  {
                    label: 'Total Questions',
                    data: totalQuestionsData.map((dataPoint) => dataPoint.y),
                    ...getChartConfig('#8fb3dc', barThickness),
                  },
                ],
              }}
            />
          </div>
        </div>
        <div id='toPdfReport' className={cn(stylesPdf.pdfAdjust)}>
          <h3 className={stylesPdf.unitTitle}>Success Metrics</h3>
          <div className={stylesPdf.activitySummaryContainer}>
            <div className={stylesPdf.summaryTitle}>Achievement Summary</div>
            <div>Goals Achieved</div>
            <div>Activities Mastered</div>
            <div>Activities Emerging</div>
            <div>Activities Introduced</div>
            <div></div>
            <div>{data.achievedGoals}</div>
            <div>{data.masteredActivities}</div>
            <div>{data.emergingActivities}</div>
            <div>{data.inProgressActivities}</div>
          </div>
        </div>
        <div id='toPdfReport'>
          <h5 className={stylesPdf.pdfAdjust}>
            Total Number of Achieved Goals :
          </h5>
          <div className={stylesPdf.chartContainer}>
            <Bar
              id='totalAchievedGoals'
              options={GoalChartptions}
              data={{
                labels: totalAchievedGoalsData.map((dataPoint) => dataPoint.x),
                datasets: [
                  {
                    label: 'Achieved Goals',
                    data: totalAchievedGoalsData.map(
                      (dataPoint) => dataPoint.y
                    ),
                    ...getChartConfig('#8fb3dc', barThickness),
                  },
                ],
              }}
            />
          </div>
        </div>

        <div id='toPdfReport' className={stylesPdf.activityChart}>
          <h5 className={stylesPdf.pdfAdjust}>Activities Metrics : </h5>
          <div  className={cn(stylesPdf.activityChartCheckBoxes, stylesPdf.ignoreOnPrint)}>
            <div  className={stylesPdf.activityChartCheckBox}>
              <ToggleButton
                value={activityFilters.mastered}
                onChange={() =>
                  setActivityFilters(prevFilters => ({
                    ...prevFilters,
                    mastered: !prevFilters.mastered
                      }))
                }
              />
              <span>Mastered Activities</span>
            </div>
            <div className={stylesPdf.activityChartCheckBox}>
              <ToggleButton
                value={activityFilters.emerging}
                onChange={() =>
                  setActivityFilters(prevFilters => ({
                    ...prevFilters,
                    emerging: !prevFilters.emerging
                      }))
                }
              />
              <span>Emerging Activities</span>
            </div>
            <div className={stylesPdf.activityChartCheckBox}>
              <ToggleButton
                value={activityFilters.introduced}
                onChange={() =>
                  setActivityFilters(prevFilters => ({
                ...prevFilters,
                introduced: !prevFilters.introduced
                  }))
                }
              />
              <span>Introduced Activities</span>
            </div>
          </div>

          <div className={stylesPdf.chartContainer}>
            <Bar
              id='totalAchieveActivities'
              options={ActivityChartptions}
              data={{
                labels: totalAchievedGoalsData.map((dataPoint) => dataPoint.x),
                datasets: [
                  {
                    label: 'Mastered Activities',
                    data: totalMasteredActivitiesData.map(
                      (dataPoint) => dataPoint.y
                    ),
                    ...getChartConfig(
                      '#8fb3dc',
                      activitiesBarThickness,
                      activityFilters.mastered
                    ),
                  },
                  {
                    label: 'Emerging Activities',
                    data: totalEmergingActivitiesData.map(
                      (dataPoint) => dataPoint.y
                    ),
                    ...getChartConfig(
                      '#C7D4E5',
                      activitiesBarThickness,
                      activityFilters.emerging
                    ),
                  },
                  {
                    label: 'Newly Introduced Activities',
                    data: totalIntroducedActivitiesData.map(
                      (dataPoint) => dataPoint.y
                    ),
                    ...getChartConfig(
                      '#dae3f3',
                      activitiesBarThickness,
                      activityFilters.introduced
                    ),
                  },
                ],
              }}
            />
          </div>
        </div>
      </>
    </div>
  )
}