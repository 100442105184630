import { createContext, useContext, useMemo, useEffect, useState } from 'react'
import { policyData, type Policy, type Condition, type User } from './AbacPolicy.tsx'

// Define effect types using an enum
enum EffectType {
  Any = 'any',
  All = 'all',
}

interface Resource {
  type: string
}

// Create a context for policies
const PolicyContext = createContext<{
  policies: Policy[]
  canPerformAction: (user: User, resource: Resource, action: string) => boolean
} | undefined>(undefined)

// Extract condition evaluation logic into a separate function
function evaluateCondition (user: User, condition: Condition): boolean {
  const conditionValue = condition.value
  const attributeValue = user[condition.attribute]
  return attributeValue === conditionValue
}

// Provide a PolicyProvider component to manage policies and policy evaluation
export function PolicyProvider ({ children }: { children: React.ReactNode }) {
  const [policies, setPolicies] = useState<Policy[]>([])
  useEffect(() => {
    setPolicies(policyData)
  }, [])

  // Function to evaluate a request against policies
  const canPerformAction = useMemo(() => (user: User, resource: Resource, action: string) => {
    return policies.some((policy) => {
      if (policy.action === action && policy.resourceType === resource.type) {
        const conditionsMet = policy.conditions.map((condition) =>
          evaluateCondition(user, condition)
        )

        if (policy.effect === EffectType.Any) {
          return conditionsMet.some((conditionMet) => conditionMet)
        } else if (policy.effect === EffectType.All) {
          return conditionsMet.every((conditionMet) => conditionMet)
        }
      }
      return false
    })
  }, [policies])

  const contextValue = useMemo(() => ({ policies, canPerformAction }), [policies])

  return <PolicyContext.Provider value={contextValue}>{children}</PolicyContext.Provider>
}

// Custom hook to access policies and policy evaluation function
export function usePolicyContext () {
  const context = useContext(PolicyContext)
  if (context === undefined) {
    throw new Error('usePolicyContext must be used within a PolicyProvider')
  }
  return context
}
