import { LearnerF } from '@logic/contexts/Learners/LearnersFrontend.type.ts'
import { FilterObject } from '@logic/useFiltering.hook.ts'
import React, { FC, ReactNode, useEffect, useMemo, useRef, useState } from 'react'
import { LxIcon } from '@components/icon/Icon.tsx'
import {
  ActivitiesOutlineIcon,
  AnalyticsOutlineIcon,
  GoalOutlineIcon,
  TeamMemberIcon,
  UserInfoIcon
} from '@icons/index.ts'
import { EditIcon, MoreActionsIcon } from '@icons/utils'
import styles from '@src/pages/Learner/Learner.module.scss'
import { LxClickAbleIcon } from '@components/icon/clickAbleIcon.tsx'
import sharedStyles from '@src/pages/MultiPage.module.scss'
import LearnerEditStatus from '@src/pages/Learner/LearnerEditStatus.tsx'
import { Link } from 'react-router-dom'
import LearnerInfo from '@src/pages/Learner/LearnerInfo.tsx'
import { useModal } from '@logic/contexts/Modal/ModalContext.tsx'
import { LxDropdown } from '@components/select/dropdown.tsx'
import { isNilOrEmpty } from '@src/utils/isNilOrEmpty.ts'

const generateOptions = (learner: LearnerF, canEditLearner: boolean): FilterObject<ReactNode>[] => {
  const options = [
    {
      value: null,
      className: sharedStyles.show6column,
      optionToRender: (
        <Link to={`${learner.id}/show/members`} className={styles.dropdownOption}>
          <LxIcon icon={TeamMemberIcon}/>
          Members
        </Link>
      )
    },
    {
      value: null,
      className: sharedStyles.show5column,
      optionToRender: (
        <Link to={`${learner.id}/show/reports`} className={styles.dropdownOption}>
          <LxIcon icon={AnalyticsOutlineIcon}/>
          Reports
        </Link>
      )
    },
    {
      value: null,
      className: sharedStyles.show4column,
      optionToRender: (
        <Link to={`${learner.id}/show/activity`} className={styles.dropdownOption}>
          <LxIcon icon={ActivitiesOutlineIcon}/>
          Activities
        </Link>
      )
    },
    {
      value: null,
      className: sharedStyles.show3column,
      // This and following options don't perform an action that returns a value in the same manner, so 'value' might be omitted or handled differently
      optionToRender: (
        <Link to={`${learner.id}/show/goal`} className={styles.dropdownOption}>
          <LxIcon icon={GoalOutlineIcon}/>
          Goals
        </Link>
      )
    },
    {
      // Assuming showModal is defined elsewhere to handle this action
      value: <LearnerInfo learner={learner}/>,
      className: sharedStyles.show2column,
      optionToRender: (
        <div className={styles.dropdownOption}>
          <LxIcon icon={UserInfoIcon}></LxIcon>
          Learner info
        </div>
      )
    },
  ]
  if (canEditLearner) {
    options.push({
      value: <LearnerEditStatus learner={learner}/>,
      optionToRender: (
        <div className={styles.dropdownOption}>
          <LxIcon icon={EditIcon}></LxIcon>
          <span> Manage Status</span>
        </div>
      )
    })
  }

  return options


  return options
}

type Props = {
  learner: LearnerF
  canEditLearner: boolean
}

export const LearnerListActions: FC<Props> = ({ learner, canEditLearner }) => {
  const [ isEditDropdownOpen, setIsEditDropdownOpen ] = useState(false)
  const { showModal, hideModal } = useModal()

  const selectRef = useRef<HTMLDivElement>(null)

  const options = useMemo(() => generateOptions(learner, canEditLearner), [ learner, canEditLearner ])

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
        setIsEditDropdownOpen(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [ selectRef ])

  const handleOptionClick = (option: FilterObject<ReactNode>) => {
    if (!isNilOrEmpty(option.value)) {
      showModal(option.value)
    }}

  return (
    <>
      <div className={styles.desktopActions}>
        {canEditLearner ? (
          <LxClickAbleIcon
            onClick={() => showModal(<LearnerEditStatus learner={learner}/>)}
            icon={EditIcon}
          />
        ) : null}
      </div>
      <div className={styles.mobileActions} ref={selectRef}>
        <LxClickAbleIcon
          icon={MoreActionsIcon}
          dropdown={
            isEditDropdownOpen
              ? <LxDropdown<ReactNode>
                className={styles.dropdown}
                options={options}
                optionRenderer={(option) => option.optionToRender}
                onOptionClick={handleOptionClick}
              />
              : null
          }
          onClick={() => setIsEditDropdownOpen(prev => !prev)}
        />
      </div>
    </>
  )
}

export const LearnerListActionsHeader: FC<{canEditLearner: boolean}> = ({canEditLearner}) => {
  return (
    <>
      <div className={styles.desktopActions}>
        {canEditLearner ? 'Manage Status' : ''}
      </div>
      <div className={styles.mobileActions}>
        More Actions
      </div>
    </>
  )
}
