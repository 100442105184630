import React,{ useEffect, useState } from 'react'
import { useNotify, useUpdate } from 'react-admin'

import styles from './AnalyticsList.module.scss'
import { RobotIcon , QtIcon} from '@icons/index.ts'
import { LxLoadingSpinner } from '@components/loader/loadingSpinner.tsx'

import { Dialog } from '@mui/material'

import { LxClickAbleIcon } from '@components/icon/clickAbleIcon.tsx'
import { cn } from '@src/utils/cn.ts'

export type RobotProps = {
  id: string
  serial: string
}

const RobotInfo = ({ accountId , accountName}) => {
  const [showDialog, setShowDialog] = useState(false)
  const [isDataLoading, setIsDataLoading] = useState(false)
  const [robotData, setRobotData] = useState<[RobotProps] | null>(null)

  const notify = useNotify()
  const [update] = useUpdate('Report')

  const sanitizeData = (data: any) => {
    if (!data || !data.report) {
      return null
    }

    return data.report.map((robot: RobotProps) => ({
      id: robot.id,
      serial: robot.serial,
    }))
  }

  const fetchData = async () => {
    setIsDataLoading(true)
    try {
      await update(
        'Report',
        {
          id: accountId,
          data: {
            filter: {},
            type: 'robot_info',
          },
          previousData: null,
        },
        {
          onSuccess: (data) => {
            const sanitizedData = sanitizeData(data)
            setRobotData(sanitizedData)
            setIsDataLoading(false)
          },
          onFailure: (error) => {
            setRobotData(null)
            const errorMessage = error.message || 'Something went wrong'
            notify(errorMessage, { type: 'warning' })
            setIsDataLoading(false)
          },
        }
      )
    } catch (error) {
      setIsDataLoading(false)
    }
  }

  useEffect(() => {
    if (showDialog) {
      fetchData()
    }
  }, [showDialog])

  return (
    <>
      <LxClickAbleIcon
        onClick={() => {
          setShowDialog(true)
        }}
        icon={QtIcon}
      ></LxClickAbleIcon>
      <Dialog
        open={showDialog}
        onClose={() => {
          setShowDialog(false)
        }}
        maxWidth='xs'
        fullWidth
      >
        <div className={styles.dialogHeader}>
          <h3>Robots  of {accountName}</h3>
        </div>
        <div className={styles.dialogContent}>
          <div className={styles.headerRow}>
            <strong>Serial:</strong>
          </div>

          {isDataLoading ? (
            <LxLoadingSpinner />
          ) : (
            robotData?.map((robot, index) => (
              <React.Fragment key={`StudentsGoals-${index}`}>
                <div className={styles.infoRow}>
                  <span>{robot?.serial}</span>
                </div>
              </React.Fragment>
            ))
          )}
        </div>
        <div className={styles.dialogActions}>
          <div
            className={cn('lxActionButton lxActionButtonDefaultSize')}
            onClick={() => {
              setShowDialog(false)
            }}
          >
            <span>Close</span>
          </div>
        </div>

        
      </Dialog>
    </>
  )
}

export default RobotInfo
