// This file serves common configrations such as constants, LuxAI date format

export const SITE_TITLE = 'LuxAI ADMIN App'

export const USER_ROLE = {
    ACCOUNT_ADMIN: "account_admin",
    ADMIN: "admin",
    USER: "user",
    ASSISTANT: "assistant",
    PARENT: 'parent'
} 
