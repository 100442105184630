import React, { FC} from "react";
import { useActivityReport } from "@logic/contexts/Learners/ActivityReport/LearnerActivityReportContext.tsx";
import { Line } from "react-chartjs-2";
import {
  chartConfig,
  generateChartOptions,
} from "@src/pages/Learner/Show/Activities/activityReport/chart.utils.ts";
import styles from "@src/pages/Learner/Show/Activities/activityReport/LearnerActivityReport.module.scss";
import { LxLoadingSpinner } from "@components/loader/loadingSpinner.tsx";
import { isNilOrEmpty } from "@src/utils/isNilOrEmpty.ts";

const options = generateChartOptions(
  "% of correct independent answers​", 100
) as any;

type Props = {
  unitName?: string;
};

export const ScoreChart: FC<Props> = ({ unitName }) => {
  const { activityChartData, isAssessmentLoading } = useActivityReport();

  return (
    <div>
      <h5>
        Progress graph based on percentage of independent correct answers to the
        total questions asked
      </h5>

      <div className={styles.chartContainer}>
        {isAssessmentLoading ? (
          <LxLoadingSpinner className={styles.chartLoader} />
        ) : isNilOrEmpty(activityChartData) ? (
          <div className={styles.noDataFound}> No data found</div>
        ) : (
          <Line
            id="activityChart"
            options={options}
            data={{
              labels: activityChartData!.labels,
              datasets: [
                {
                  label: "% score",
                  data: activityChartData!.allDataScore,
                  ...chartConfig,
                },
              ],
            }}
          />
        )}
      </div>
    </div>
  );
};
