import { useEffect } from 'react'

export const useCheckVisibility = (ref, className) => {
  useEffect(() => {
    if (ref.current) {
      const observer = new IntersectionObserver(
        ([ entry ]) => {
          if (!entry.isIntersecting) {
            ref.current?.classList.add(className)
          }
        },
        {
          root: null, // Checks visibility within the viewport
          threshold: 1.0, // Element is considered intersecting when it's fully within the viewport
        }
      )
      observer.observe(ref.current)

      return () => {
        if (ref.current) {
          ref.current.classList.remove(className)
          observer.unobserve(ref.current)

        }
      }
    }
  }, [ ref, className ]) // Effect dependencies
}
