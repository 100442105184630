import { defaultTheme } from 'react-admin'
import red from '@mui/material/colors/red'

// TODO: write documentation for colors and palette in own markdown file and add links from here

export const palette = {
  luxaiWhite1: '#ffffff',
  luxaiWhite2: '#ebf1f4',
  luxaiGray1: '#899aaf',
  luxaiDark1: '#242e42',
  luxaiBlue1: '#94ccec',
  luxaiBlue2: '#78b4e2',
  luxaiBlue3: '#8fb3dc',
  luxaiDarkBlue: '#6589a5',
  luxaiPurple1: '#b6b1d7',
  luxaiPurple2: '#a3acd9',
  luxaiDarkPurple: '#6e779a',

  neutral100: '#ffffff',
  neutral200: '#f4f2f1',
  neutral300: '#d7cec9',
  neutral400: '#b6aca6',
  neutral500: '#978f8a',
  neutral600: '#564e4a',
  neutral700: '#3c3836',
  neutral800: '#191015',
  neutral900: '#000000',

  primary100: '#f4e0d9',
  primary200: '#e8c1b4',
  primary300: '#dda28e',
  primary400: '#d28468',
  primary500: '#c76542',
  primary600: '#a54f31',

  secondary100: '#dcdde9',
  secondary200: '#bcc0d6',
  secondary300: '#9196b9',
  secondary400: '#626894',
  secondary500: '#41476e',

  accent100: '#ffeed4',
  accent200: '#ffe1b2',
  accent300: '#fdd495',
  accent400: '#fbc878',
  accent500: '#ffbb50',

  angry100: '#f2d6cd',
  angry500: '#c03403',

  overlay20: 'rgba(25, 16, 21, 0.2)',
  overlay50: 'rgba(25, 16, 21, 0.5)'
} as const

const Theme = {
  ...defaultTheme,
  palette: {
    primary: {
      main: '#2E2E52' // Specify the main color
    },
    secondary: {
      main: '#acacac' // Specify the main color
    },
    blue: {
      main:   palette.luxaiDarkBlue // Specify the main color
    },
    error: red,
    contrastThreshold: 3,
    tonalOffset: 0.2
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: 'Roboto, sans-serif',
    // fontFamily: ['-apple-system', 'BlinkMacSystemFont', '"Segoe UI"', 'Arial', 'sans-serif'].join(','),
  },
  components: {
    ...defaultTheme.components,
    MuiScopedCssBaseline: {
      styleOverrides: {
        root: {
          color: '#2E2E52'
        }
      }
    },
    MuiFilledInput: {
        styleOverrides: {
            root: {
              backgroundColor: 'transparent !important',
              '&:before': {
                display: 'none !important'
              },
              '&:after': {
                display: 'none !important'
              },
            }
        }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: '16px',
        },
      },
    },
    RaDatagrid: {
      styleOverrides: {
        root: {
          backgroundColor: palette.luxaiWhite2,
          '& .RaDatagrid-headerCell': {
            backgroundColor: palette.luxaiBlue2
          }
        }
      }

    },
    RaSidebar: {
      styleOverrides: {
        root: {
          backgroundColor: palette.luxaiBlue2
        }
      }
    },
    RaFileInput: {
      styleOverrides: {
        root: {
          border: '1px dotted',
          boxSizing: 'border-box',
          '& .RaFileInput-dropZone': {
          }
        }
      }
    }
  }
}

export default Theme
