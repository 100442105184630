import React, {useState, useEffect} from 'react'
import {SearchIcon} from '@icons/utils'

import styles from './LearnerTeamMembers.module.scss'
import sharedStyles from '../../../MultiPage.module.scss'
import '@styles/common.scss'
import {LxIcon} from '@components/icon/Icon.tsx'
import {cn} from '@src/utils/cn.ts'
import {useLearnerShow} from '@logic/contexts/Learners/LearnerShowContext.tsx'
import {LxLoadingSpinner} from '@components/loader/loadingSpinner.tsx'
import {UserF} from '@logic/contexts/Users/UsersFrontend.type.ts'
import {isNilOrEmpty} from '@src/utils/isNilOrEmpty.ts'
import {useDataProvider} from 'react-admin'

export const AssistantView = () => {
  const {learner, isLoading} = useLearnerShow()
  const learnerId = learner?.id

  const dataProvider = useDataProvider()

  const [userData, setUserData] = useState<User[]>([])
  const [isUserDataLoading, setIsUserDataLoading] = useState(true)
  const [filteredUserData, setFilteredUserData] = useState()

  const fullName = (user) => {
    return `${user.first_name} ${user.last_name}`
  }

  // get all users and details

  // get assigned users and details
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await dataProvider.getList('User', {
          pagination: {page: 0, perPage: 100},
          sort: {field: 'id', order: 'ASC'},
          filter: {list: 'learnerUsers', learner_id: learnerId},
        })

        const assignedUsersArray = []

        Object.entries(response.data || {}).map(([_, userData]) => {
          const userDict = {
            id: userData.id,
            fullName: fullName(userData),
            userRole: userData.userRole,
            assistants: [],
          }

          Object.entries(userData.assigned_users || {}).map(
            ([key, assistantData]) => {
              if (learnerId in assistantData.learners) {
                userDict.assistants.push({
                  id: key,
                  fullName: fullName(assistantData),
                  userRole: assistantData.userRole,
                  supervisorId: userData.id,
                  supervisorFullName: userDataMap[userData.id].fullName,
                })
              }
            }
          )
          assignedUsersArray.push(userDict)
        })

        // Mapping assignedUserData with assistants details
        assignedUsersArray.forEach((user) => {
          if (user.assistants.length > 0) {
            user.assistants.forEach((assistant) => {
              const assistantWithSupervisorId = {
                ...assistant,
              }
              assignedUsersArray.push(assistantWithSupervisorId)
            })

            delete user.assistants
          }
        })

        setUserData(assignedUsersArray)
        setFilteredUserData(assignedUsersArray)
        setIsUserDataLoading(false)
      } catch (error) {
        // Handle error
        setIsUserDataLoading(false)
      }
    }
    if (learnerId) {
      fetchData()
    }
  }, [learnerId])

  const handleSearchInputChange = (inputValue) => {
    const filteredUsers = userData.filter((user) =>
      user.fullName.toLowerCase().includes(inputValue.toLowerCase())
    )
    setFilteredUserData(filteredUsers)
  }

  return (
    <>
      <div className={styles.studentTeamMembersNavigation}>
        {isLoading || isUserDataLoading ? (
          <LxLoadingSpinner />
        ) : (
          <div className={cn(styles.membersInfo)}>
            <h3>Team Members Assigned to {learner?.fullName} </h3>
          </div>
        )}
        <div className={cn(sharedStyles.tableActions)}>
          <div className={'lxActionButton lxActionButtonDefaultSize'}>
            <LxIcon icon={SearchIcon} />
            <input
              className={'pristineInput'}
              type='text'
              onChange={(e) => {
                handleSearchInputChange(e.target.value)
              }}
              placeholder='Search'
            />
          </div>
        </div>
      </div>

      <div className={styles.studentTeamMembersAssistantList}>
        <div className={cn(sharedStyles.tableListHeader)}>Team Member Name</div>
        <div
          className={cn(sharedStyles.tableListHeader, sharedStyles.hide3column)}
        ></div>
        <>
          {isUserDataLoading ? (
            <LxLoadingSpinner className={sharedStyles.loader} />
          ) : isNilOrEmpty(filteredUserData) ? (
            <div className={sharedStyles.notFound}>
              No team members match your current search or filters.
            </div>
          ) : (
            filteredUserData.map((user: UserF) => (
              <React.Fragment key={`User-row-${user.id}-${user.supervisorId}`}>
                <div>{user.fullName}</div>

                <div className={sharedStyles.hide3column}></div>
              </React.Fragment>
            ))
          )}
        </>
      </div>
    </>
  )
}
