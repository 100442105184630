// This modal used to show only text

import { Dialog } from '@mui/material'
import { useState } from 'react'

import styles from '@components/modal/modal.module.scss'
import { LxClickAbleIcon } from '@components/icon/clickAbleIcon.tsx'

// TODO upgrade inline styles
export const InfoModal = ({ icon, header, message }) => {
  const [showDialog, setShowDialog] = useState(false)

  const clear_all = () => {
    setShowDialog(false)
  }

  return (
    <>
      <LxClickAbleIcon
        icon={icon}
        onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
          e.stopPropagation()
          setShowDialog(true)
        }}
      />

      <Dialog open={showDialog} onClose={clear_all}>
        <div className={styles.dialogHeader}>
          <h3>{header}</h3>
        </div>

        <div className={styles.dialogContent}>
          <span style={{ fontSize: '16px'  }}>{message}</span>
        </div>

        <div className={styles.singleAction}>
          <div
            className={'lxActionButton lxActionButtonDefaultSize singleAction'}
            onClick={clear_all}
          >
            <span>Close</span>
          </div>
        </div>
      </Dialog>
    </>
  )
}
