import React, { FC, } from 'react'
import { useActivityReport } from '@logic/contexts/Learners/ActivityReport/LearnerActivityReportContext.tsx'
import { Bar } from 'react-chartjs-2'
import { barChartConfig, generateChartOptions } from '@src/pages/Learner/Show/Activities/activityReport/chart.utils.ts'
import styles from '@src/pages/Learner/Show/Activities/activityReport/LearnerActivityReport.module.scss'
import { LxLoadingSpinner } from '@components/loader/loadingSpinner.tsx'
import { isNilOrEmpty } from '@src/utils/isNilOrEmpty.ts'


const options = generateChartOptions('Time spent on activity (minutes)') as any

type Props = {
  unitName?: string;
}

export const TimeChart: FC<Props> = ({unitName}) => {
  const { activityChartData, isAssessmentLoading } = useActivityReport()


  return (
    <div>
      <h5>Time spent working with QTrobot on the {unitName}</h5>
      <div className={styles.chartContainer}>
        {
          isAssessmentLoading
            ? <LxLoadingSpinner className={styles.chartLoader}/>
            : isNilOrEmpty(activityChartData)
              ? <div className={styles.noDataFound}> No data found</div>
              : <Bar
                options={options}
                data={{
                  labels: activityChartData!.labels,
                  datasets: [
                    {
                      label: 'Minutes',
                      data: activityChartData!.allDataByTime,
                      ...barChartConfig
                    }
                  ]
                }}
              />
          }
        </div>
    </div>
  )
};
