import styles from './Navbar.module.scss'
import { LogoutIcon, MenuIcon } from '@icons/utils'
import { useMsal } from '@azure/msal-react'
import { useUserContext } from '@logic/contexts/AppStore/UserContext.tsx'
import { CircularProgress } from "@mui/material";
import { cn } from '@src/utils/cn.ts'
import { LxClickAbleIcon } from '@components/icon/clickAbleIcon.tsx'
import { LxIcon } from '@components/icon/Icon.tsx'
import LxMenu from '@src/pages/layout/Menu.tsx'
import { useLayout } from '@logic/contexts/LayoutContext.tsx'

export const LxNavbar = () => {
  const { instance } = useMsal()
  const { currentUser, isUserLoading } = useUserContext() as any
  const { setShouldOpenMobileNav, shouldOpenMobileNav } = useLayout()

  const handleLogout = () => {
    // userId, AccoutnId - will set during useTokenManagement context - Home component
    // msalClientId, msalClientUrl, apiUrl- will be set in login page
    // msalB2CId - will be set after login - App component
    const keysToRemove = [
        "userEmail",
        "msalB2CId",
        "UserId",
        "AccountId",
        "msalClientId",
        "msalClientUrl",
        "apiUrl"
    ];

     // issue in msal, sometimes this below status is not cleared so manually clearing the msal.interaction.status
    const itemKey = "msal.interaction.status";
    if (sessionStorage.getItem(itemKey)) {
      sessionStorage.removeItem(itemKey);
     }

    keysToRemove.forEach(key => localStorage.removeItem(key));
    instance.logoutRedirect();
  }

  let accountName = ""
  if (!isUserLoading) {
    accountName = currentUser.accountName
  }

  return (
    <div className={cn(styles.layoutNavbar, 'ignoreOnPrint')}>
       <div className={styles.layoutNavbarleftText}>
         {isUserLoading ? <CircularProgress /> : <h5> {accountName}</h5>}
      </div>
      <div className={styles.navbarNavigation}>
      <a href="https://luxai.com/qtrobot-training-resources-for-special-needs-education-schools/" target="_blank" className={styles.support}>
          <h5>Tutorials</h5>
        </a>
        <a href="https://luxai.com/contact/" target="_blank" className={styles.support}>
          <h5>Support</h5>
        </a>
        <div className={styles.logoutButton} onClick={handleLogout}>
          <h5>Logout</h5>
          <LxIcon icon={LogoutIcon}></LxIcon>
        </div>
      </div>
      <div className={styles.mobileNavbarNavigation}>
        <LxClickAbleIcon icon={MenuIcon} customViewBox={'0 0 50 50'} onClick={() => setShouldOpenMobileNav(true)}></LxClickAbleIcon>
        {shouldOpenMobileNav && <div className={styles.menuNav}><LxMenu  mobileVersion={true}/></div>}
      </div>
    </div>
  )
}

export default LxNavbar
