import { TeamMembersIcon, StudentsOutlineIcon, UserInfoIcon } from '@icons/index.ts'

import { SearchIcon, PlusIcon, EditIcon } from '@icons/utils'

import React, {useState, useEffect} from 'react'
import { useUsersList } from '@logic/contexts/Users/UsersListsContext.tsx'
import styles from './UserList.module.scss'
import sharedStyles from '@src/pages/MultiPage.module.scss'
import { LxIcon } from '@components/icon/Icon.tsx'
import { cn } from '@src/utils/cn.ts'
import { Link } from 'react-router-dom'
import { UserF } from '@logic/contexts/Users/UsersFrontend.type.ts'
import { LxTooltip } from '@components/tooltip/tooltip.tsx'
import { mapRoleToReadableRole } from '@src/utils/mapRoleToReadableRole.ts'
import { LxLoadingSpinner } from '@components/loader/loadingSpinner.tsx'
import UserInfo from './UserInfo'
import { useUserContext } from '@logic/contexts/AppStore/UserContext.tsx'
import { usePolicyContext } from '@logic/contexts/AppStore/AbacContext.tsx'
import UserEditStatus from './UserEditStatus'
import { LxAutocompleteTmp } from '@components/autocompleteTmp/autocompleteTmp.tsx'
import { LxClickAbleIcon } from '@components/icon/clickAbleIcon.tsx'
import { useModal } from '@logic/contexts/Modal/ModalContext.tsx'

export const UserList = () => {
  const { showModal } = useModal();
  const { userList, isLoading, filterValues, dispatch } = useUsersList()
  const [filteredData, setFilteredData] = useState(userList)

  const { Q: inputValue, state: stateFilter } = filterValues
  // const setInputValue = newValue => dispatch({ type: 'UPDATE_FILTER', field: 'Q', value: newValue })
  const setStateFilter = newValue => dispatch({ type: 'UPDATE_FILTER', field: 'state', value: newValue })

  const { currentUser } = useUserContext() as any
  const { canPerformAction } = usePolicyContext()

  const handleSearchInputChange = (inputValue: string) => {
    const filteredLearners = userList?.filter((learner) =>
      learner.fullName.toLowerCase().includes(inputValue.toLowerCase())
    )
    setFilteredData(filteredLearners)
  }

  useEffect(() => {
    // Ensure learnerList is loaded before applying search
    if (!isLoading) {
      setFilteredData(userList)
    }
  }, [isLoading, userList])

  const canCreateUser = canPerformAction(
    currentUser,
    { type: 'User' },
    'create'
  )
  const canEditUser = canPerformAction(
    currentUser,
    { type: 'User' },
    'create'
  )

  const is_not_assistant =
    currentUser.userRole == 'admin' ||
    currentUser.userRole == 'account_admin' ||
    currentUser.userRole == 'user'

  const TeamAssignIcon = (
    <LxIcon
      sxStyles={{
        height: '32px',
        width: '32px',
        marginBottom: '-12px',
      }}
      icon={StudentsOutlineIcon}
    />
  )
  
   

  return (
    <>
      <div className={styles.userTableNavigation}>
        <div className={sharedStyles.tableInfo}>
          <div>
            <LxIcon
              sxStyles={{ height: '80px', width: '80px', marginTop: '-18px' }}
              icon={TeamMembersIcon}
            ></LxIcon>
          </div>
          <div>
            <LxAutocompleteTmp
              value={stateFilter}
              onChange={setStateFilter}
              options={[
                {
                  value: 'active',
                  label: 'Active Team Members',
                },
                {
                  value: 'archived',
                  label: 'Archived Team Members',
                },
              ]}
            />
            {stateFilter == 'deleted' && (
              <span>
                Deleted learner profiles can be restored within 90 days
              </span>
            )}
          </div>
        </div>
        <div className={sharedStyles.tableActions}>
          {canCreateUser ? (
              <div className={cn('lxActionButton lxActionButtonFilled lxActionButtonDefaultSize')}>
                <Link to={'create'}>
                  <LxIcon icon={PlusIcon}/>
                  Add New Team Member
                </Link>
              </div>
            )
            : null
          }
          <div className={'lxActionButton lxActionButtonDefaultSize'}>
            <LxIcon icon={SearchIcon}/>
            <input
              className={'pristineInput'}
              type="text"
              onChange={(e) => {
                handleSearchInputChange(e.target.value)
              }}
              placeholder="Search"
            />
          </div>
        </div>
      </div>
      <div className={styles.teamMembersListContent}>
        <div className={cn(sharedStyles.tableListHeader)}> User</div>
        <div className={cn(sharedStyles.tableListHeader, sharedStyles.hide2column)}> Title</div>
        <div className={cn(sharedStyles.tableListHeader, sharedStyles.hide3column)}> Role</div>
        <div className={cn(sharedStyles.tableListHeader, sharedStyles.hide4column)}> Info</div>
        <div className={cn(sharedStyles.tableListHeader, sharedStyles.hide5column)}> Learners</div>
        <div className={cn(sharedStyles.tableListHeader)}>
          {canEditUser ? 'Manage Status' : ''}
        </div>
        {isLoading ? (
          <LxLoadingSpinner className={sharedStyles.loader}></LxLoadingSpinner>
        ) : (
          <React.Fragment>
            {filteredData?.length ? (
              filteredData.map((user: UserF) => (
                <React.Fragment key={`User-row-${user.id}`}>
                  <div><span className={sharedStyles.textOverflow}>{user.fullName}</span></div>
                  <div className={sharedStyles.hide2column}>{user.jobTitle}</div>
                  <div className={sharedStyles.hide3column}>{mapRoleToReadableRole(user.userRole)}</div>
                  <div className={sharedStyles.hide4column}>
                    <LxClickAbleIcon
                      onClick={() => showModal(
                        <UserInfo user={user}/>)}
                      icon={UserInfoIcon}
                    ></LxClickAbleIcon>
                  </div>
                  <div className={sharedStyles.hide5column}>
                    {is_not_assistant ? (
                        <>
                        {user.state !== 'active' ? (
                          <LxTooltip tooltipText={'User is not active'}>
                            {TeamAssignIcon}
                          </LxTooltip>
                        ) : (
                          <Link to={`${user.id}/show/learners`}>
                            {TeamAssignIcon}
                          </Link>
                        )}
                        </>
                      ) : (
                        <LxTooltip tooltipText={'Sorry, You dont have access'}>
                          {TeamAssignIcon}
                        </LxTooltip>
                    )}
                  </div>
                  <div>
                    {canEditUser ? (
                      <LxClickAbleIcon
                        onClick={() => showModal(
                          <UserEditStatus
                            userId={user.id}
                            state={user.state}
                            userRole={user.userRole}
                            userName={user.fullName}
                            userList={userList!}
                        />)}
                        icon={EditIcon}
                      />
                    ) : null}
                  </div>
                </React.Fragment>
              ))
            ) : (
              <div className={sharedStyles.notFound}>
                No users match your current search or filters.
              </div>
            )}
          </React.Fragment>
        )}
      </div>
    </>
  )
}

export default UserList
