import {
  AnalyticsReportB,
  ReportDataB,
} from '@logic/contexts/Analytics/Report/AnalyticsReport.type.tsx'

import { isNilOrEmpty } from '@src/utils/isNilOrEmpty.ts'

import styles from '@src/pages/Analytics/Reports/AnalyticsReport.module.scss'
import { formatDate } from '@src/utils/dateFormatter'

export const sanitizeDate = (date: Date) => {
  // return (<strong>{date.getFullYear()}.{(date.getMonth() + 1).toString().padStart(2, '0')}.{date.getDate().toString().padStart(2, '0')}</strong>)
  return <>{formatDate(date, false, false)}</>
}
export function formatReportPeriod(days) {
  const currentDate = new Date()
  const startDate = new Date()
  startDate.setDate(currentDate.getDate() - days)
  return (
    <h5 id='toPdfReport' className={styles.reportHeader}>
      Report period from {sanitizeDate(startDate)} to{' '}
      {sanitizeDate(currentDate)}
    </h5>
  )
}

export const parseReportDataToAnalyticsReport = (
  reportData: ReportDataB,
  duration: string
): AnalyticsReportB | null => {
  if (isNilOrEmpty(reportData?.report)) {
    return null
  }
  const periodEl = formatReportPeriod(duration)
  const report = reportData.report

  const units: {
    [key: string]: {
      totalTime: string
      totalQuestions: string
      totalAchievedGoals: string
      totalMasteredActivities: string
      totalEmergingActivities: string
      totalIntroducedActivities: string
    }
  } = {}

  Object.keys(report.units).forEach((date: string) => {
    const unit = report.units[date]
    units[date] = {
      totalTime: unit.tt,
      totalQuestions: unit.tuq,
      totalAchievedGoals: unit.ag,
      totalMasteredActivities: unit.ma,
      totalEmergingActivities: unit.ea,
      totalIntroducedActivities: unit.pa,
    }
  })

  const AnalyticsReport: AnalyticsReportB = {
    id: reportData.id,
    name: report.name,
    totalTime: report.tt,
    totalActivities: report.ta,
    totalUniqQuest: report.tuq,
    totalLearners: report.tle,
    achievedGoals: report.ag,
    masteredActivities: report.ma,
    emergingActivities: report.ea,
    inProgressActivities: report.pa,
    totalActiveUsers: report.tau,
    totalActiveLearners: report.tal,
    lastActivityDate: report.lad,
    units: units,
    periodEl: periodEl,
  }

  return AnalyticsReport
}
