import { useNavigate } from 'react-router-dom'
import { useDataProvider, useNotify } from 'react-admin'
import { parseUserFormToBackend, UserForm, UserFormType } from '@src/pages/User/Show/CreateOrEdit/UserForm.tsx'
import { useSubmitLoader } from '@src/logic/contexts/utils/SubmitContext.tsx'

export const UserCreate = () => {
  const navigate = useNavigate()
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const {isFormSubmitting, setIsFormSubmitting} = useSubmitLoader();

  const onSubmit = async (formData: UserFormType) => {
    if (isFormSubmitting) {return};
    setIsFormSubmitting(true);

    try {
      await dataProvider.create('User', {
        data: parseUserFormToBackend(formData)
      })

      // // Handle success case
      notify('User created successfully', { type: 'success' })
      backToLearners()
    } catch (error) {
      const errorMessage =
        error.message || 'Something went wrong, Please try again after sometime'
      notify(errorMessage, { type: 'error' }) 
    } finally {
      setIsFormSubmitting(false);
    }
  }

  const backToLearners = () => {
    navigate('/User');  // this will navigate to the previous page
  };

  return (
    <div>
      <div>
        <h3>Add New Team Member</h3>
      </div>
      <UserForm onSubmit={onSubmit} onCancel={backToLearners} isSubmitting={isFormSubmitting}></UserForm>
    </div>
  )
}
