import UserList from './UserList.tsx'
// import UserCreate from './UserCreate.tsx'
import { UserListProvider } from '@logic/contexts/Users/UsersListsContext.tsx'
import { UserCreate } from '@src/pages/User/Show/CreateOrEdit/UserCreate.tsx'
import { UserEdit } from '@src/pages/User/Show/CreateOrEdit/UserEdit.tsx'
import { UserShowProvider } from '@logic/contexts/Users/UserShowContext.tsx'

export default {
  list: <UserListProvider><UserList/></UserListProvider>,
  create: UserCreate,
  edit:  <UserShowProvider><UserEdit/></UserShowProvider>,
}
