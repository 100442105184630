import { useState } from 'react'
import { isNilOrEmpty } from '@src/utils/isNilOrEmpty.ts'

export const SKILL_AREAS_FILTER_KEY = 'enabledSkillAreas'
export const GOALS_FILTER_KEY = 'enabledGoals'
export const ACTIVITY_UNIT_FILTER_KEY = 'enabledActivityUnit'
export const STATUS_FILTER_KEY = 'enabledStatus'

// TODO to be improved
export const generateSimpleOptions = <T, K extends keyof T>(
  data: T[] | null | undefined,
  labelKey: K,
  isEnabled = true,
): FilterObject<T>[] => {
  if (isNilOrEmpty(data)) {
    return []
  }
  return data!.map((item: T) => ( {
    value: item,
    label: item[labelKey] as unknown as string,
    id: (item as any).id ? (item as any).id : item[labelKey], // TODO fix this
    isEnabled,
  } ))
}


export type FilterObject<T> = {
  value: T
  isEnabled?: boolean
  label?: string
  optionToRender?: React.ReactNode
  className?: string
  id?: string
  type?: string
}

type FilterState<T> = {
  data: T[];
  set: (value: T[]) => void;
};


// Define the default state for each filter
const defaultFilterState = <T>(): FilterState<T> => ( {
  data: [],
  set: () => {
  } // A no-op function for the default case
} )

export const defaultActivityFilter = {
  [SKILL_AREAS_FILTER_KEY]: defaultFilterState<FilterObject<string>>(),
  [GOALS_FILTER_KEY]: defaultFilterState<FilterObject<string>>(),
}


export interface ActivityListFilterType {
  [SKILL_AREAS_FILTER_KEY]: FilterState<FilterObject<string>>;
  [GOALS_FILTER_KEY]: FilterState<FilterObject<string>>;
}


export const defaultProgressReportFilter = {
  [SKILL_AREAS_FILTER_KEY]: defaultFilterState<FilterObject<string>>(),
  [GOALS_FILTER_KEY]: defaultFilterState<FilterObject<string>>(),
  [ACTIVITY_UNIT_FILTER_KEY]: defaultFilterState<FilterObject<string>>(),
  [STATUS_FILTER_KEY]: defaultFilterState<FilterObject<string>>(),
}


export interface ProgressReportFilterType {
  [SKILL_AREAS_FILTER_KEY]: FilterState<FilterObject<string>>;
  [GOALS_FILTER_KEY]: FilterState<FilterObject<string>>;
  [ACTIVITY_UNIT_FILTER_KEY]: FilterState<FilterObject<string>>;
  [STATUS_FILTER_KEY]: FilterState<FilterObject<string>>;
}


export type InitialFilterState = {
  [key: string]: FilterState<FilterObject<string>>;
};


export type FilteringConfig = {
  key: string;
}[]

export const useFiltering = (filteringConfig: FilteringConfig, initialState: InitialFilterState) => {
  const [ filters, setFilters ] = useState<InitialFilterState>(() => {
    const initialFilters: InitialFilterState = { ...initialState }
    filteringConfig.forEach((filter) => {
      initialFilters[filter.key] = {
        data: [],
        set: (value: FilterObject<string>[]) => setFilters((prevFilters: InitialFilterState) => ( {
          ...prevFilters,
          [filter.key]: { ...prevFilters[filter.key], data: value }
        } ))
      }
    })
    return initialFilters
  })

  const resetFilters = () => {
    Object.keys(filters).forEach(filterKey => {
      filters[filterKey].set([])
    })
  }

  return { filters, resetFilters }
}