import React, { FC } from 'react'
import styles from './loader.module.scss'
import { cn } from '@src/utils/cn.ts'
import { CircularProgress } from '@mui/material'

type Props = {
  className?: string
}

export const LxLoadingSpinner: FC<Props> = ({ className }) => {
  return (
    <div className={cn(styles.loadingContainer, className)}>
      <CircularProgress />
    </div>
  );
};
