// PolicyTypes.ts

export interface Policy {
  action: string
  resourceType: string
  effect: string
  conditions: Condition[]
}

export interface Condition {
  attribute: keyof User
  value: string
}

export interface User {
  id: string
  userRole: string[]
}

export const policyData: Policy[] = [
  {
    // only admin, account_admin , user can see create users
    action: 'create',
    resourceType: 'User',
    effect: 'any',
    conditions: [
      {
        attribute: 'userRole',
        value: 'account_admin'
      },
      {
        attribute: 'userRole',
        value: 'admin'
      },
      {
        attribute: 'userRole',
        value: 'user'
      }
    ]
  },
  {
    // only account_admin, admin can edit users
    action: 'edit',
    resourceType: 'User',
    effect: 'any',
    conditions: [
      {
        attribute: 'userRole',
        value: 'account_admin'
      },
      {
        attribute: 'userRole',
        value: 'admin'
      },
      {
        attribute: 'userRole',
        value: 'user'
      }
    ]
  },

  // Learner policies
  {
    // only admin, account_admin , user can create learner
    action: 'create',
    resourceType: 'Learner',
    effect: 'any',
    conditions: [
      {
        attribute: 'userRole',
        value: 'account_admin'
      },
      {
        attribute: 'userRole',
        value: 'admin'
      },
      {
        attribute: 'userRole',
        value: 'user'
      }
    ]
  },
  {
    // only account_admin, admin can edit users
    action: 'edit',
    resourceType: 'Learner',
    effect: 'any',
    conditions: [
      {
        attribute: 'userRole',
        value: 'account_admin'
      },
      {
        attribute: 'userRole',
        value: 'admin'
      },
      {
        attribute: 'userRole',
        value: 'user'
      }
    ]
  },
  {
    // only account_admin, admin,user can create goal
    action: 'add_goal',
    resourceType: 'Learner',
    effect: 'any',
    conditions: [
      {
        attribute: 'userRole',
        value: 'account_admin'
      },
      {
        attribute: 'userRole',
        value: 'admin'
      },
      {
        attribute: 'userRole',
        value: 'user'
      }
    ]
  },
  {
    // only account_admin, admin,user can edit goal
    action: 'edit_goal',
    resourceType: 'Learner',
    effect: 'any',
    conditions: [
      {
        attribute: 'userRole',
        value: 'account_admin'
      },
      {
        attribute: 'userRole',
        value: 'admin'
      },
      {
        attribute: 'userRole',
        value: 'user'
      }
    ]
  },
  {
    // only account_admin, admin , user can edit activity status
    action: 'edit_activity',
    resourceType: 'Learner',
    effect: 'any',
    conditions: [
      {
        attribute: 'userRole',
        value: 'account_admin'
      },
      {
        attribute: 'userRole',
        value: 'admin'
      },
      {
        attribute: 'userRole',
        value: 'user'
      }
    ]
  },
  {
    // only account_admin, admin  can view analytics
    action: 'view_menu',
    resourceType: 'Analytics',
    effect: 'any',
    conditions: [
      {
        attribute: 'userRole',
        value: 'account_admin'
      },
      {
        attribute: 'userRole',
        value: 'admin'
      }
    ]
  },
  {
    // only account_admin, admin , user can edit activity status
    action: 'enroll_curriculum',
    resourceType: 'Learner',
    effect: 'any',
    conditions: [
      {
        attribute: 'userRole',
        value: 'account_admin'
      },
      {
        attribute: 'userRole',
        value: 'admin'
      },
      {
        attribute: 'userRole',
        value: 'user'
      }
    ]
  },

]
