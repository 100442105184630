import React, { FC, useMemo, useState } from 'react'
import { useLearnerShow } from '@logic/contexts/Learners/LearnerShowContext.tsx'
import styles from '@src/pages/Learner/Show/common.module.scss'
import { LearnerGoalsTable } from '@src/pages/Learner/Show/Goals/LearnerGoalsTable.tsx'
import { useGetList } from 'react-admin'
import { GOALF } from '@logic/contexts/Learners/LearnersFrontend.type.ts'


import sharedStyles from '../../../MultiPage.module.scss'
import { CircularProgress } from '@mui/material'

import { useUserContext } from '@logic/contexts/AppStore/UserContext.tsx'
import { usePolicyContext } from '@logic/contexts/AppStore/AbacContext.tsx'
import { GOALB } from '@logic/contexts/Learners/LearnersBackend.type.ts'
import { LxSelect } from '@components/select/select.tsx'
import { LearnerGoalCreate } from '@src/pages/Learner/Show/Goals/GoalActions/LearnerGoalCreate.tsx'
import { isNilOrEmpty } from '@src/utils/isNilOrEmpty.ts'

export const mapServerGoalToClientGoal = (goalBData: GOALB[]): GOALF[] & any => {
  return goalBData.map(goalB => {
    // Calculate the most recent lastPracticed date
    const lastPracticedDates = Object.values(goalB.units).map(unit => unit.last_practiced ? new Date(unit.last_practiced).getTime() : 0)
    const mostRecentDate = Math.max(...lastPracticedDates)
    const formattedDate = isFinite(mostRecentDate) && mostRecentDate > 0 ? new Date(mostRecentDate).toLocaleDateString() : 'N/A';

    // Map status with a readable format
    const status = goalB.state === 'on_hold' ? 'on_hold' : goalB.status;

    const units = Object.keys(goalB.units).map(unitId => ( {
      id: unitId,
      unitId: unitId,
      unitTitle: goalB.units[unitId].unit_title,
      levelNumber: goalB.units[unitId].level_number,
      locked: goalB.units[unitId].locked,
      status: goalB.units[unitId].status,
      state: goalB.units[unitId].state,
      extras: goalB.units[unitId].extras,
      lastScore: goalB.units[unitId].last_score ? goalB.units[unitId].last_score.toString() : null,
      lastPracticed: goalB.units[unitId].last_practiced,
      events: goalB.units[unitId].events || {},
    } ))

    return {
      id: goalB.id,
      name: goalB.name,
      automation: goalB.automation,
      status,
      state: goalB.state,
      description: goalB.description,
      priority: goalB.priority,
      extras: goalB.extras,
      skillAreaId: goalB.category_id,
      goalId: goalB.sub_category_id,
      skillAreaName: goalB.category_name,
      goalName: goalB.sub_category_name,
      ownerName: goalB.owner_name,
      learnerId: goalB.learner_id,
      units,
      unitsIds: Object.keys(goalB.units),
      lastPracticed: formattedDate, // Use the most recent lastPracticed date
      assigneeUsers: goalB.assignee_users || [],
      accountId: goalB.account_id, // Assuming you want to include the accountId in the final structure
      rawGoal: goalB,
    }
  })
}

export const LearnerGoals: FC = () => {
  const { learner } = useLearnerShow()
  const { currentUser } = useUserContext() as any
  const { canPerformAction } = usePolicyContext()
  const [ selectedFilter, setSelectedFilter ] = useState<string>('all') // Default to show all goals

  const canAddGoal = canPerformAction(
    currentUser,
    { type: 'Learner' },
    'add_goal'
  )

  const filter = { learner_id: learner.id }
  const { data, isLoading, error } = useGetList<GOALB>('Goal', {
    pagination: { page: 1, perPage: 100 },
    sort: { field: 'last_update', order: 'DESC' },
    filter,
  })


  const sanitizedGoals: GOALF[] = useMemo(() => {
    if (isNilOrEmpty(data)) {
      return []
    }
    return mapServerGoalToClientGoal(data)
  }, [ data ])


  const goalList = data

  const filteredGoals = useMemo(() => {
    if (selectedFilter === 'unassigned') {
      return sanitizedGoals.filter(
        (goal: GOALF) => !goal.assigneeUsers || goal.assigneeUsers.length === 0
      )
    } else if (selectedFilter === 'user') {
      const currentUserID = localStorage.getItem('UserId')
      return sanitizedGoals.filter(
        (goal: GOALF) => goal.assigneeUsers && goal.assigneeUsers.includes(currentUserID)
      )
    }
    return sanitizedGoals
  }, [ sanitizedGoals, selectedFilter ])


  const statusCounts: Record<any, number> = useMemo(() => {
    const counts: Record<any, number> = {
      achieved: 0,
      not_introduced: 0,
      in_progress: 0,
      emerging: 0,
    }

    if (filteredGoals) {
      filteredGoals.forEach((goal: GOALF) => {
        const status = goal.status
        if (status in counts) {
          counts[status]++
        }
      })
    }

    return counts
  }, [ filteredGoals ])

  return (
    <div className={styles.tabContainer}>
      <div className={styles.goalsummaryContainer}>
        <div className={styles.summaryTitle}>Goals Status Summary</div>
        <div className={styles.info}>
          <div>Achieved</div>
          <div>{statusCounts.achieved ? statusCounts.achieved : '-'}</div>
        </div>
        <div className={styles.info}>
          <div>Emerging</div>
          <div>{statusCounts.emerging ? statusCounts.emerging : '-'}</div>
        </div>
        <div className={styles.info}>
          <div>In progress</div>
          <div>{statusCounts.in_progress ? statusCounts.in_progress : '-'}</div>
        </div>
        <div className={styles.info}>
          <div>Not introduced</div>
          <div>{statusCounts.not_introduced ? statusCounts.not_introduced : '-'}</div>
        </div>
      </div>

      {isLoading ? (
        <div className={sharedStyles.loader}>
          <CircularProgress/>
        </div>
      ) : (
        <React.Fragment>

          <div className={styles.goalsTableActions}>
            <LxSelect
              className={styles.learnerTableAction}
              value={selectedFilter}
              shrinked
              onChange={setSelectedFilter}
              options={[
                { value: 'all', label: 'All Goals' },
                { value: 'user', label: 'Assigned to me' },
                { value: 'unassigned', label: 'Unassigned Goals' }
              ]}
            ></LxSelect>
            {canAddGoal && <LearnerGoalCreate></LearnerGoalCreate>}
          </div>

          {goalList?.length ? (
            <LearnerGoalsTable
              goalList={filteredGoals}
            ></LearnerGoalsTable>
          ) : (
            <div className={sharedStyles.notFound}>
              Sorry, we cannot find any goals. Please create new goals.
            </div>
          )}
        </React.Fragment>
      )}
    </div>
  )
}
