export function formatDate(inputDate: string, displayTime: boolean = true, displaySeconds: boolean = false): string {
  // sample output Feb 28,2024.
    if (!inputDate) {
      return '';
    }

    const isYearMonthFormat = /^\d{4}-\d{2}$/.test(inputDate); // This is for returning Year month for Yearly grpahs. Otherwise by default 01 is added as date

    const date = new Date(inputDate);

    if (isNaN(date)) {
      return '';
    }

    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
  
    const year = date.getFullYear();
    // const month = date.getMonth() + 1; // Months are 0-indexed
    const monthIndex = date.getMonth();  // Months are 0-indexed
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
   
    // Format the date in the same way as the backend format
    // const formattedMonth = month < 10 ? `0${month}` : month;
    const formattedMonth = months[monthIndex];
    const formattedDay = isYearMonthFormat ? '' : (day < 10 ? `0${day}` : day);
    const formattedHours = hours < 10 ? `0${hours}` : hours;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
  
    return `${formattedMonth} ${formattedDay}, ${year} ${displayTime ? `${formattedHours}:${formattedMinutes}` : ''} ${displaySeconds ? `${formattedSeconds}` : ''}`;
  }


 export  function parseTime(seconds) {
    let hours = Math.floor(seconds / 3600);
    let minutes = Math.floor((seconds % 3600) / 60);
    // let remainingSeconds = seconds % 60;

    let timeString = '';

    if (hours > 0) {
        timeString += hours + ' Hour';
        if (hours > 1) timeString += 's';
        timeString += ' ';
    }

    if (minutes > 0) {
        timeString += minutes + ' Minute';
        if (minutes > 1) timeString += 's';
        timeString += ' ';
    }

    // if (remainingSeconds > 0 || timeString === '') {
    //     timeString += remainingSeconds + ' Second';
    //     if (remainingSeconds !== 1) timeString += 's';
    // }

    return timeString.trim();
}


// below function will give week range for the input date e.g. Dec 24-30, 2023. (input date always has to be last sunday of the week)
export function formatWeekDate(inputDate) {
  const endOfWeek = new Date(inputDate);
  const startOfWeek = new Date(endOfWeek);
  startOfWeek.setDate(endOfWeek.getDate() - 6); // start of the week is 6 days later

  const currentDate = new Date();
  const startOfMonth = startOfWeek.getMonth();
  const endOfMonth = endOfWeek.getMonth();

  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];

  if (startOfMonth === endOfMonth) {
    return `${months[startOfMonth]} ${startOfWeek.getDate()} - ${currentDate.getDate() === endOfWeek.getDate() ? currentDate.getDate() : endOfWeek.getDate()}, ${startOfWeek.getFullYear()}`;
  } else {
    return `${months[startOfMonth]} ${startOfWeek.getDate()} - ${months[endOfMonth]} ${endOfWeek.getDate()}, ${startOfWeek.getFullYear()}`;
  }
}

