import { RoleF } from '@logic/contexts/Users/UsersFrontend.type.ts'

export type Status =  'account_admin'|'admin'|'user'|'assistant'

export const mapRoleToReadableRole = (role: RoleF): string => {
  const roleMap: { [key in Status]?: string } = {
    account_admin: 'Account Admin',
    admin: 'Admin',
    user: 'User',
    assistant: 'Assistant'
  }
  return roleMap[role] || ''
}