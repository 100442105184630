import {
  type RedirectRequest
} from '@azure/msal-browser'


// Function to  update the config dynamically
export const updateMsalConfig = (clientId: string, url: string): RedirectRequest => {
  const modifiedMsalConfig: RedirectRequest = {
    auth: {
      authority: url,
      clientId: clientId,
      redirectUri: `${import.meta.env.VITE_APP_BASE_URI}/`,
      navigateToLoginRequestUrl: false,
      knownAuthorities: [
        new URL(url).origin
      ],
    },
    cache: {
      cacheLocation: 'localStorage', // options 1. sessionStorage , 2. localStorage  - localstorage option will give SSO across browser , change storage if need
      storeAuthStateInCookie: false
    },
    scopes: [clientId, 'openid', 'offline_access']
  };
  return modifiedMsalConfig;
};