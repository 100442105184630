import { Controller, useForm } from 'react-hook-form'
import React, { FC, useEffect } from 'react'
import styles from './LearnerForm.module.scss'
import { cn } from '@src/utils/cn.ts'
import { LxSelect } from '@components/select/select.tsx'
import { ToggleButton } from '@components/toggleButton/toggleButton.tsx'
import { LxTooltip } from '@components/tooltip/tooltip.tsx'
import { LxIcon } from '@components/icon/Icon.tsx'
import { InfoOutlineIcon } from '@icons/utils'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { yupResolver } from '@hookform/resolvers/yup'
import { useFormNotify } from '@components/formNotify/useFormNotify.tsx'
import { isAdmin, Roles } from '@logic/contexts/utils/rolesCheck.ts'
import * as yup from 'yup'
import { useUserContext } from '@logic/contexts/AppStore/UserContext.tsx'
import { LearnerF } from '@logic/contexts/Learners/LearnersFrontend.type.ts'
import dayjs from 'dayjs'
import { LxLoadingSpinner } from '@components/loader/loadingSpinner.tsx'


export type LearnerFormType = {
  id?: string
  firstName: string
  lastName: string
  idCode?: string
  fileCode?: string
  nickName?: string
  gender: string
  grade?: string
  birthday: string
  assignLearner?: boolean | null
}
export const defaultLearnerFormValues = (role: Roles): LearnerFormType => ( {
  firstName: '',
  lastName: '',
  idCode: '',
  fileCode: '',
  nickName: '',
  gender: '',
  grade: '',
  birthday: '',
  assignLearner: isAdmin(role) ? true : false,
} )

export const LearnerFormValidation = yupResolver(yup.object().shape({
  firstName: yup.string(),
  lastName: yup.string(),
  idCode: yup.string(),
  fileCode: yup.string(),
  nickName: yup.string(),
  gender: yup.string(),
  grade: yup.string(),
  birthday: yup.string(),
  assignLearner: yup.boolean(),
}).required())

export const parseLearnerFormToBackend = (formData: LearnerFormType) => ( {
  first_name: formData.firstName,
  last_name: formData.lastName,
  id_code: formData.idCode,
  file_code: formData.fileCode,
  nick_name: formData.nickName,
  gender: formData.gender,
  birthday: formData.birthday,
  grade: formData.grade,
  assign_learner: formData.assignLearner
} )

type Props = {
  onSubmit: (formData: LearnerFormType) => void
  learner?: LearnerF | null
  isEdit?: boolean
  onCancel: () => void
  isSubmitting: boolean
}

export const LearnerForm: FC<Props> = ({ onSubmit, learner, isEdit, onCancel, isSubmitting=false }) => {
  const { currentUser } = useUserContext() as any
  const { control,  handleSubmit,  reset, formState: { errors, submitCount, isValid } } = useForm({
    defaultValues: learner ? { ...learner } : defaultLearnerFormValues(currentUser.userRole),
    resolver: LearnerFormValidation,
  })

  useFormNotify(errors, submitCount, isValid)


  useEffect(() => {
    // Reset the form when defaultValues change (for Edit scenario)
    reset(learner ? {...learner} : defaultLearnerFormValues(currentUser.userRole))
  }, [ learner, reset ])

  
  return (
    <form className={styles.learnerForm} onSubmit={handleSubmit(onSubmit)}>
      {isSubmitting && (
        <div className={styles.LoadingOverlay}>
          <LxLoadingSpinner />
        </div>
      )}

      <Controller
        name="firstName"
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <div className={cn(styles.inputField, 'lxActionButton', { ['formFieldRequired']: !!error })}>
            <input
              className={'pristineInput'}
              type="text"
              value={value}
              onChange={(e) => onChange(e.target.value)}
              placeholder="First Name"
            />
          </div> )}
      />
      <Controller
        name="lastName"
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <div className={cn(styles.inputField, 'lxActionButton', { ['formFieldRequired']: !!error })}>
            <input
              className={'pristineInput'}
              type="text"
              value={value}
              onChange={(e) => onChange(e.target.value)}
              placeholder="Last Name"
            />
          </div> )}
      />
      <Controller
        name="idCode"
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <div className={cn(styles.inputField, 'lxActionButton', { ['formFieldRequired']: !!error })}>
            <input
              className={'pristineInput'}
              type="text"
              value={value}
              onChange={(e) => onChange(e.target.value)}
              placeholder="Identification Code/Number"
            />
          </div>
        )}
      />
      <Controller
        name="nickName"
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <div className={cn(styles.inputField, 'lxActionButton', { ['formFieldRequired']: !!error })}>
            <input
              className={'pristineInput'}
              type="text"
              value={value}
              onChange={(e) => onChange(e.target.value)}
              placeholder="Nickname"
            />
          </div>
        )}
      />
      <Controller
        name="grade"
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <div className={cn(styles.inputField, 'lxActionButton', { ['formFieldRequired']: !!error })}>
            <input
              className={'pristineInput'}
              type="text"
              value={value}
              onChange={(e) => onChange(e.target.value)}
              placeholder="Grade"
            />
          </div>
        )}
      />
      <Controller
        name="birthday"
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              format="MMM DD, YYYY"
              className={cn(styles.datePicker, { [styles.formFieldRequired]: !!error })}
              onChange={(e) => onChange(e?.format('YYYY-MM-DD') || null)}
              value={value ? dayjs(value) : null}
            />
          </LocalizationProvider>
        )}
      />
      <Controller
        name="gender"
        control={control}
        render={({ field, fieldState}) => (
          <LxSelect
            value={field.value}
            hasError={!!fieldState.error}
            shrinked
            placeholder={'Select Gender'}
            onChange={field.onChange}
            options={[
              { value: 'male', label: 'Male' },
              { value: 'female', label: 'Female' },
              { value: 'others', label: 'Other' },
            ]}
          />
        )}
      />
       <Controller
        name="fileCode"
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <div className={cn(styles.inputField, 'lxActionButton', { ['formFieldRequired']: !!error })}>
            <input
              className={'pristineInput'}
              type="text"
              value={value}
              onChange={(e) => onChange(e.target.value)}
              placeholder="IEP/EHCP Code"
            />
          </div>
        )}
      />
      {isAdmin(currentUser.userRole) &&  !isEdit && (
        <Controller
          name="assignLearner"
          control={control}
          render={({ field: { onChange, value } }) => (
            <div className={styles.assignLearnerSection}>
              <ToggleButton
                value={value}
                onChange={onChange}
              />
              <span>Assign this learner to me</span>
              <LxTooltip tooltipText={
                `On the tablets, you can run activities only for the learners who are assigned to your profile.
                   You can assign/unassign learners to your profile at any time.`
              }>
                <LxIcon icon={InfoOutlineIcon}/>
              </LxTooltip>
            </div>
          )}
        />
      )}

      <div className={styles.formActions}>
        <div
          className={cn('lxActionButton lxActionButtonDefaultSize')}
          onClick={onCancel}
        >
          <span>Cancel</span>
        </div>
        <div
          className={cn('lxActionButton lxActionButtonFilled lxActionButtonDefaultSize')}
          onClick={handleSubmit(onSubmit)}
        >
          <span>{isEdit ? 'Save Changes' : 'Create Learner'}</span>
        </div>
      </div>
    </form>
  )
}
