import { UnitF } from '@logic/contexts/Learners/LearnersFrontend.type.ts'
import { GOALB } from '@logic/contexts/Learners/LearnersBackend.type.ts'
import { TaskStatus } from '@src/utils/mapStatusToReadableStatus.ts'
import { isNilOrEmpty } from '@src/utils/isNilOrEmpty.ts'
import { removeDuplicates } from '@src/utils/removeDuplicates.ts'

export type ActivityArea = {
  name: string
  id: string
  goals: ActivityGoal[]
}

export type ActivityGoal = {
  skillAreaId: string
  skillAreaName: string
  name: string
  goalId: string
  priority: string
  status: string
  state: string
  units: UnitF[]
}


export const rawGoalToUnitF = (goal: GOALB): UnitF[] => {
  return Object.entries(goal.units).map(([unitId, unit]): UnitF => ({
    id: unitId,
    unitId: unit.unit_id,
    unitTitle: unit.unit_title,
    levelNumber: unit.level_number,
    locked: unit.locked,
    status: unit.status,
    state: unit.state as TaskStatus, // Ensure this matches TaskStatus type
    extras: { ...unit.extras },
    lastScore: unit.last_score,
    lastPracticed: unit.last_practiced,
  }));
}

export const parseGoalsToActivityAreas = (goalList: GOALB[] | undefined): ActivityArea[] => {
  if (isNilOrEmpty(goalList)) {
    return []
  }

  const skillAreaMap = new Map<string, ActivityArea>();

  goalList!.forEach((goal) => {
    const skillAreaName = goal.category_name
    const skillAreaId =  goal.category_id
    const goalName = goal.sub_category_name

    // Check if the skillAreaName is already in the map
    let skillArea = skillAreaMap.get(skillAreaName);

    if (!skillArea) {
      // If not, create a new ProgressReportArea
      skillArea = {
        name: skillAreaName,
        id: skillAreaId,
        goals: [],
      };
      skillAreaMap.set(skillAreaName, skillArea);
    }
    let aggregatedGoal = skillArea.goals.find((goal: ActivityGoal) => goal.name === goalName)

    if (!aggregatedGoal) {
      skillArea.goals.push({
        name: goalName,
        skillAreaId: goal.category_id,
        skillAreaName: goal.category_name,
        goalId: goal.id,
        status: goal.status,
        state: goal.state,
        priority: goal.priority,
        units: rawGoalToUnitF(goal),
      })
    } else {
      const updatedUnits = removeDuplicates<UnitF>(
        [...aggregatedGoal.units, ...rawGoalToUnitF(goal)],
        unit => unit.id
      );
      skillArea.goals = skillArea.goals.map(g =>
        g.name === goalName ? { ...g, units: updatedUnits } : g
      );
    }
  })
  return Array.from(skillAreaMap.values());
}
