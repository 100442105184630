import React, {  useContext, useEffect, useMemo, useState } from 'react';
import { createContextFactory } from '@logic/contexts/createContextFactory.ts';
import { useGetList, useNotify, useUpdate } from 'react-admin'

// Import types from your existing context
import {
  ActivityAssessmentF, ActivityResultF,
} from '@logic/contexts/Learners/LearnersFrontend.type.ts'
import {
  ActivityAssessmentB,
  ActivityResultB,
} from '@logic/contexts/Learners/LearnersBackend.type.ts'
import { useParams,useSearchParams } from 'react-router-dom'
import { isNilOrEmpty } from '@src/utils/isNilOrEmpty.ts'
import {
  GroupedQuestionData,
  groupQuestionData
} from '@logic/contexts/Learners/ActivityReport/groupChartData.factory.ts'

// Define the new context type
export interface ActivityReportContextType {
  activityAssessment: ActivityAssessmentF[] | null;
  isAssessmentLoading: boolean;
  handleDurationChange: (event: string) => void;
  duration: string;
  activityChartData: GroupedQuestionData;
  handleGenerateAssessmentClick: () => void;
  activityAssessmentFilters: ActivityAssessmentFilters | null;
}

const ActivityReportContextDefault = {
  activityAssessment: null,
  isAssessmentLoading: true,
  activityChartData: null,
  handleDurationChange: () => {},
  duration: '1000', // Change the default duration if needed
  handleGenerateAssessmentClick: () => {},
  activityAssessmentFilters: null,
};

const ActivityReportContext = createContextFactory<ActivityReportContextType>(ActivityReportContextDefault);

// Define a mapping function to convert data from ActivityAssessmentB to ActivityAssessmentF
const mapActivityAssessmentBToF = (dataArray: ActivityAssessmentB[] | undefined): ActivityAssessmentF[] | null => {
  if (isNilOrEmpty(dataArray)) {
    return null;
  }

  return dataArray!.map((data) => {
    const mappedAssessment: ActivityAssessmentF = {
      id: data.id,
      iepId: data.iep_id,
      unitId: data.unit_id,
      learnerId: data.learner_id,
      userId: data.user_id,
      start: data.start,
      end: data.end,
      score: data.score,
      results: isNilOrEmpty(data.results) ? [] : data.results.map((resultB: ActivityResultB): ActivityResultF => ({
        id: resultB.id,
        question: resultB.question,
        questionTime: resultB.question_time,
        questionProp: resultB.question_prop,
        answer: resultB.answer,
        answerTime: resultB.answer_time,
        answerProp: resultB.answer_prop,
      })),
      resultsIds: data.resultsIds,
    };
    
    return mappedAssessment;
  });

 
};


// Define types for assessment filters if needed
export type ActivityAssessmentFilters = {
  // Define your filter properties here
};

// Create the ActivityReportProvider component
export const ActivityReportProvider = ({ children }) => {
  const [duration, setDuration] = useState('1000'); // Change the default duration if needed
  const [activityChartData, setActivityChartData] = useState<GroupedQuestionData>(null)


  const [isDataLoading, setIsDataLoading] = useState(true);
  const [activityAssessment, setActivityAssessment] = useState<ActivityAssessmentF[] | null>(null);
  const { id: learnerId, activityId} = useParams()
  let [searchParams] = useSearchParams();

  const goalId = searchParams.get('goal')

  const [activityAssessmentFilters, setActivityAssessmentFilters] = useState<ActivityAssessmentFilters | null>(null);

  // Use your dataProvider, update, and notify as needed
  const notify = useNotify();

  const handleDurationChange = (val: string) => {
    setDuration(val)
  };

  const filter = useMemo(() => {
    return { learner_id: learnerId, duration, unit_id: activityId , goal_id: goalId};
  }, [learnerId, duration, activityId, goalId]);


  const { data, isLoading, error } = useGetList('Session', {
    pagination: { page: 0, perPage: 10000 },
    sort: { field: 'id', order: 'ASC' },
    filter: filter,
  });

   
// Handle errors
  if (error) {
    // You can handle the error here, e.g., notify the user, log the error, etc.
    console.error('Error while fetching activity data:', error);
    // You can also return an error message or a different UI in case of an error.
    return (
      <div>
        <p>An error occurred while fetching activity data. Please try again later.</p>
      </div>
    );
  }

  useEffect(() => {
    if (data) {
      const sanitizedData = mapActivityAssessmentBToF(data)
      setActivityAssessment(sanitizedData)
      setActivityChartData(groupQuestionData(sanitizedData))
      setIsDataLoading(false)
    }
  }, [data]);

  return (
    <ActivityReportContext.Provider value={{
      activityAssessment,
      activityChartData,
      isAssessmentLoading: isDataLoading,
      handleDurationChange,
      duration,
      handleGenerateAssessmentClick: () => {},
      activityAssessmentFilters,
    }}>
      {children}
    </ActivityReportContext.Provider>
  );
};

// Create a custom hook for using the Activity Report context
export const useActivityReport = (): ActivityReportContextType => {
  return useContext(ActivityReportContext);
};
