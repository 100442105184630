import { useUserContext } from "@logic/contexts/AppStore/UserContext.tsx";
import { UserView } from "./UserView.tsx";
import {AdminView} from "./AdminView.tsx";
import { AssistantView } from "./AssistantView.tsx";
import { UserListProvider } from '@logic/contexts/Users/UsersListsContext.tsx'

export const LearnerTeamMembers = () => {
  const { currentUser } = useUserContext() as any


  let roleComponent

  if (currentUser.userRole === 'account_admin') {
    roleComponent = <AdminView />
  } else if (currentUser.userRole === 'admin') {
    roleComponent = <AdminView />
  } else if (currentUser.userRole === 'user') {
    roleComponent = <UserView currentUserId={currentUser.id} />
  } else if (currentUser.userRole === 'assistant') {
    roleComponent = <AssistantView />
  }

  return roleComponent
};