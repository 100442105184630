// useNotifyErrors.js
import { useEffect } from 'react';
import { useNotify } from 'react-admin';
import styles from './useFormNotify.module.scss';


// TODO it's need to be refactored, it's very naive implementation

export const useFormNotify = (errors, submitCount, isValid) => {
  const notify = useNotify();

  useEffect(() => {
    if (submitCount > 0 && !isValid) {
      const errorsCombined = (
        <div className={styles.notifyError}>
          {Object.keys(errors).map((key, index) => (
            <p key={`${key}-${index}`}>{errors[key]!.message}</p>
          ))}
        </div>
      );
      notify(errorsCombined, { type: 'error', autoHideDuration: 5000 });
    }
  }, [isValid, submitCount, errors, notify]);
};
