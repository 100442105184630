// Accordion.tsx
import React, { useEffect, useState } from 'react'
import { isNilOrEmpty } from '@src/utils/isNilOrEmpty.ts'
import { ActivitiesGoal } from '@src/pages/Activities/components/ActivitiesGoal.tsx'
import { SkillAreaHeader } from '@src/pages/Activities/components/SkillAreaHeader.tsx'
import { SkillAreaF } from '@logic/contexts/Activities/ActivitiesFrontend.type.ts'

interface Props {
  skillArea: SkillAreaF;
  expandAll: boolean;
}

export const ActivitiesSkillArea: React.FC<Props> = ({ skillArea, expandAll }) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(expandAll);
  }, [expandAll]);

  return (
    <>
      {!isNilOrEmpty(skillArea.goals) ? (
        <>
          <SkillAreaHeader categoryName={skillArea.name} setIsOpen={setIsOpen} />
          {skillArea.goals.map((goal, index) => (
            <React.Fragment key={`${goal.name}-${index}`}>
              <ActivitiesGoal
                categoryId={skillArea.id}
                categoryName={skillArea.name}
                goal={goal}
                goalNumber={index + 1}
                expandAll={isOpen}
              />
            </React.Fragment>
          ))}
        </>
      ) : null}
    </>
  );
}
