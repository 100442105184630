import modalStyles from '@components/modal/modal.module.scss'
import {
  useDataProvider,
  useNotify,
  useRefresh,
} from 'react-admin'
import { useModal } from '@logic/contexts/Modal/ModalContext.tsx'
import { UnitF } from '@logic/contexts/Learners/LearnersFrontend.type.ts'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { Controller, useForm } from 'react-hook-form'
import { LxSelect } from '@components/select/select.tsx'
import { cn } from '@src/utils/cn.ts'
import { useSubmitLoader } from '@src/logic/contexts/utils/SubmitContext.tsx'
import styles from './Activities.module.scss'
import { LxLoadingSpinner } from '@components/loader/loadingSpinner.tsx'

export const stateFormValidation = yupResolver(yup.object().shape({
  state: yup.string().required('State is required')
}).required())


export const LearnerEditActivityStatus = ({
                                     unit,
                                     goalId
                                   }: {
  unit: UnitF
  goalId: string
}) => {
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const refresh = useRefresh()
  const { hideModal } = useModal()
  const { isFormSubmitting, setIsFormSubmitting } = useSubmitLoader()

  const {
    control,
    handleSubmit,
    formState: { errors, submitCount, isValid }
  } = useForm({
    defaultValues: { state: unit.state },
    resolver: stateFormValidation,
  })

  const onClose = () => {
    hideModal()
  }

  const onSubmit = async (formState: { state: string }) => {
    if (isFormSubmitting) {
      return
    }

    setIsFormSubmitting(true)

    try {
      await dataProvider.update('Goal', {
        id: goalId,
        data: {
          units: [
            {
              state: formState.state,
              id: unit.id,
              title: '',
              sort_order: ''
            }
          ],
          action: 'update_unit_status'
        },
        previousData: null
      });
  
      notify('Activity status updated', { type: 'success' });
    } catch (error) {
      const errorMessage = error.message || 'Something went wrong';
      notify(errorMessage, { type: 'warning' });
    }  finally {
      setIsFormSubmitting(false)
      hideModal();
      refresh();
    }
  };
  

  return (
    <div>
      <div className={modalStyles.modalMd}>
        {isFormSubmitting && (
          <div className={styles.LoadingOverlay}>
            <LxLoadingSpinner />
          </div>
        )}
      
        <div className={modalStyles.dialogHeader}>
          <h3>Manage Status of {unit.unitTitle}</h3>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className={modalStyles.dialogContent}>
          <Controller
            name="state"
            control={control}
            render={({ field, fieldState }) => (
              <LxSelect
                value={field.value}
                hasError={!!fieldState.error}
                placeholder={'Select Learner State'}
                onChange={field.onChange}
                options={
                  [
                    {
                      value: 'mastered',
                      label: 'Mastered',
                      className: ( unit.status == 'mastered' || unit.status == 'generalized' ) && 'disabled'
                    },
                    {
                      value: 'generalized',
                      label: 'Generalized',
                      className: ( unit.status == 'generalized' ) && 'disabled'
                    },
                    {
                      value: 'on_hold',
                      label: 'On Hold',
                      className: ( unit.state == 'on_hold' ) && 'disabled'
                    },
                    { value: 'active', label: 'Active', className: ( unit.state == 'active' ) && 'disabled' }
                  ]
                }
              />
            )}
          />
        </form>
        <div className={modalStyles.dialogActions}>
          <div
            className={cn('lxActionButton lxActionButtonDefaultSize')}
            onClick={onClose}
          >
            <span>Close</span>
          </div>
          <div
            className={cn('lxActionButton lxActionButtonFilled lxActionButtonDefaultSize')}
            onClick={handleSubmit(onSubmit)}
          >
            <span>Confirm</span>
          </div>
        </div>
      </div>
    </div>
  )
}
