import { isNilOrEmpty } from '@src/utils/isNilOrEmpty.ts'
import { FilterObject } from '@logic/useFiltering.hook.ts'

export const serializeQuery = (data: FilterObject<string>[]): string => {
  return data.map(item => encodeURIComponent(item.value.replace(/\s+/g, '-'))).join(',');
}

export const deserializeQuery = (serializedString: string): string[] => {
  if(isNilOrEmpty(serializedString)) {
    return ['']
  }
  return serializedString
    .split(',')
    .map(item => decodeURIComponent(item.replace(/-/g, ' ')));
}