import { SvgIcon, SvgIconProps } from '@mui/material'
import * as React from 'react'
import { type FC } from 'react'
import { type SxProps } from '@mui/system'
import { type Theme } from '@mui/material/styles'

const defaultSX = { height: '32px', width: '32px', display: 'flex', alignItems: 'center', justifyContent: 'center' }

export interface LxIconProps {
  sxStyles?: SxProps<Theme>
  customViewBox?: string
  icon: React.ElementType | any // TODO: any is a hack
}

export const LxIcon: FC<LxIconProps> = ({ icon, sxStyles, customViewBox }) => {
  return (
    <>
      {
        icon
          ? <SvgIcon component={icon} sx={sxStyles ?? defaultSX} viewBox={customViewBox ?? '0 0 384 384'}></SvgIcon>
          : <SvgIcon component={null} sx={sxStyles ?? defaultSX} viewBox={customViewBox ?? '0 0 384 384'}></SvgIcon>
      }
    </>
  )
}
