// LxModal.tsx
import React, { FC, ReactNode } from 'react'
import ReactDOM from 'react-dom'
import styles from './modal.module.scss'
import { CloseIcon } from '@icons/utils'
import { LxClickAbleIcon } from '@components/icon/clickAbleIcon.tsx'
import { cn } from '@src/utils/cn.ts'
import { LxLoadingSpinner } from '@components/loader/loadingSpinner.tsx'

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  className?: string;
  overlayVisible?: boolean;
}

export const LxModal: FC<ModalProps> = ({ isOpen, onClose, children, className, overlayVisible = false }) => {
  if (!isOpen) return null

  return ReactDOM.createPortal(
    (
      <div className={styles.modalOverlay} onClick={onClose}>
        <div className={cn(styles.modalContainer, className)} onClick={(e) => e.stopPropagation()}>
          <div className={styles.modalNav}>
            <LxClickAbleIcon
              icon={CloseIcon}
              onClick={onClose}
              sxStyles={{ height: '24px', width: '24px' }}
              customViewBox={'0 0 460 460'}
            />
          </div>
          {overlayVisible && (
            <div className={styles.modalLoadingOverlay}>
              <LxLoadingSpinner></LxLoadingSpinner>
            </div>
          )}
          {children}
        </div>
      </div>
    ),
    document.body
  )
}
