import LearnerList from './LearnerList.tsx'
import LearnerCreate from '@src/pages/Learner/Show/CreateOrEdit/LearnerCreate.tsx'
import LearnerShow from './Show/LearnerShow.tsx'

import { LearnerListProvider } from '@logic/contexts/Learners/LearnersListContext.tsx'
import { LearnerShowProvider } from '@logic/contexts/Learners/LearnerShowContext.tsx'
import { Route, Routes } from 'react-router-dom'
import { LearnerTeamMembers } from '@src/pages/Learner/Show/TeamMembers/LearnerTeamMembers.tsx'
import { LearnerEdit } from '@src/pages/Learner/Show/CreateOrEdit/LearnerEdit.tsx'

export default {
  list: <LearnerListProvider><LearnerList/></LearnerListProvider>,
  create: LearnerCreate,
  edit: LearnerEdit,
  show:
    <LearnerShowProvider>
      <LearnerShow/>
      <Routes>
        <Route path={'members'} element={<LearnerTeamMembers/>}/>
        <Route path={'edit'} element={<LearnerEdit />}/>
      </Routes>
    </LearnerShowProvider>,
}
