import React, { FC } from 'react'
import { ProgressGoals, ProgressReportArea, ReportItemF } from '@logic/contexts/Learners/LearnersFrontend.type.ts'
import styles from './SkillArea.module.scss'
import { useLearnerShow } from '@logic/contexts/Learners/LearnerShowContext.tsx'
import { cn } from '@src/utils/cn.ts'
import { SkillAreaUnit } from '@src/pages/Learner/Show/Report/SkillAreaUnit.tsx'
import { LxTooltip } from '@components/tooltip/tooltip.tsx'


type Props = {
  reportItem: ProgressReportArea,
}
export const SkillArea: FC<Props> = ({ reportItem }) => {
  const { learner } = useLearnerShow();

  return (
    <div className={cn(styles.skillAreaContainer)}>
      <div id="toPdfReport" className={styles.separatedArea}>
        <div className={cn(styles.skillAreaNavigation, styles.nonClickable )}>
          <h3>Skill Area: {reportItem.name}</h3>
        </div>
      </div>
      { reportItem.goals.length &&
        reportItem.goals.map((goal: ProgressGoals, index: number) =>
          <React.Fragment key={`progress-report-${goal.name}-${index}`}>
            <div id="toPdfReport" className={styles.separatedArea}>
              <div className={cn(styles.skillAreaGoal)}>
                <h5>Goal Name:</h5>
                <h5>{goal.name}</h5>
                <p>Goal Description:</p>
                <p>{goal.description}</p>
                <p>List of activities to achieve this goal:</p>
                <ul>{goal.listOfUnits.map((unit, index) => {
                  if (unit.isEnabled) {
                    return <li key={`listOfUnit-${unit.value}-${index}`}>{unit.value}</li>
                  } else {
                    return <li key={`listOfUnit-${unit.value}-${index}`} className={cn({[styles.unitIsDisabled]:true})}><LxTooltip tooltipText={'This unit is filtered out from the report'}>{unit.value}</LxTooltip></li>
                  }
                }
                )}
                </ul>
              </div>
            </div>
            {goal.units.map((unit, index) => (
                <React.Fragment key={`${goal.name}-${unit.name}-${index}`}>
                  <SkillAreaUnit  unit={unit} learner={learner}></SkillAreaUnit>
                </React.Fragment>
              )
            )}
          </React.Fragment>
        )
      }
    </div>
  );
};
